import { Program as IProgram } from "../domain/program.interface";
import { axios } from "../utils/axios.create";

const findProgramsByAccount = async (account: string): Promise<IProgram[]> => {
  return axios
    .get(`/programs?organizationIds=`)
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const programService = {
  findProgramsByAccount,
};
