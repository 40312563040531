import React from "react";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import { useAppTheme } from "../../../utils/theme";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

interface GenericModalProps {
	onClick?: () => void;
	onCancel?: () => void;
	title: string;
	description: string | React.ReactNode;
	submitText: string;
	cancelText?: string;
	isLoading?: boolean;
	icon?: React.ReactNode;
	showIcon?: boolean;
}

const GenericAlertModal: React.FC<GenericModalProps> = ({
	onClick,
	onCancel,
	title,
	description,
	submitText,
	cancelText,
	isLoading,
	icon,
	showIcon = true,
}) => {
	const theme = useAppTheme();

	return (
		<Dialog
			open={true}
			onClose={onCancel}
			PaperProps={{
				style: {
					width: "100%",
					maxWidth: "280px",
					display: "flex",
					backgroundColor: theme.palette.surface.dark,
					textAlign: "center",
					borderRadius: "15px",
				},
			}}
		>
			{isLoading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="240px"
					padding="24px"
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					{showIcon && (
						<div style={{ marginTop: "1.5rem" }}>
							{icon || <CancelPresentationIcon fontSize="medium" />}
						</div>
					)}
					<DialogTitle sx={{ padding: "1rem 1.25rem", textAlign: "left" }}>
						<Typography variant="headlineSmall" color="#261F1F">{title}</Typography>
					</DialogTitle>
					<DialogContent style={{ textAlign: "left" }}>
						<DialogContentText variant="bodyMedium" color="#564C4C">
							{description}
						</DialogContentText>
					</DialogContent>
					<DialogActions
						style={{ paddingRight: "24px", paddingBottom: "24px" }}
					>
						{cancelText && (
							<Button
								onClick={onCancel}
								variant="text"
								style={{ 
									color: theme.palette.menu.light,
									textTransform: "capitalize",
									fontWeight: "500",
									fontSize: "14px"
								}}
							>
								{cancelText}
							</Button>
						)}
						<Button
							onClick={onClick}
							variant="text"
							style={{ 
								color: theme.palette.menu.light,
								textTransform: "capitalize",
								fontWeight: "500",
								fontSize: "14px"
							}}
						>
							{submitText}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
export default GenericAlertModal;
