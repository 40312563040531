import { axios } from "../utils/axios.create";
import { HelpSupportResponse } from "../domain/helpSupport.interface";
import { ParticipantItem } from "../domain/participant.interface";

const getDashboardTotals = async ({
	accountId,
	programId,
	orgUnitId,
	orgUnitLabelId,
}: {
	accountId: string;
	programId: string;
	orgUnitId: string[];
	orgUnitLabelId: string;
}): Promise<{
	totalIncetiveEarned: number,
	totalValidationMissed: number,
	totalValidationSuccess: number,
	totalValidation: number
}> => {
	if (programId === "") {
		return Promise.resolve({
			totalIncetiveEarned: 0,
			totalValidationMissed: 0,
			totalValidationSuccess: 0,
			totalValidation: 0
		});
	}
	
	const program = new URLSearchParams({ programId }).toString();
	const orgUnit = new URLSearchParams({
		orgUnitId: orgUnitId.join(","),
	}).toString();
	const orgUnitLabel = new URLSearchParams({ orgUnitLabelId }).toString();

	return axios
		.get(
			`/enrollment/dashboard?${program}&${orgUnit}&${orgUnitLabel}&enrollmentId=`,
		)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};

const getHelpSupport = async (): Promise<HelpSupportResponse> => {
	return axios
		.get(`/helpsupport`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
}

const getSearchBarResults = async ({
	searchQuery
} : {
	searchQuery: string
}): Promise<any[]> => {
	return axios
		.get(`/participants/search?search=${searchQuery}`)
		.then((res) => {
			const { participants } = res.data.body;
			return participants.map((participant: ParticipantItem) => ({
				_id: `${participant._id}?enrollmentId=${participant.lastEnrollment._id}`,
				fullname: participant.user.firstName + " " + participant.user.lastName,
			}));
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
}

export const GeneralServices = {
	getDashboardTotals,
	getHelpSupport,
	getSearchBarResults
};
