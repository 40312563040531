import { FC, useState, ReactNode, useMemo, useEffect } from "react";
import {
	useCurrencySetting,
	useIANATimeZoneSetting,
} from "../../hooks/useSetting";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
	HeadCell,
	DataTableCell,
} from "../../components/DataTable/dataTable.interface";
import { headCellsConfig } from "../../utils/tableConfig";
import DataTable from "../../components/DataTable";
import ActionMenu from "../../components/ActionMenu";
import { useMedicationProofList } from "../../hooks/useEnrollment";
import {
	MedicationProofData,
	MedicationProofStatus,
	MedicationProofType,
} from "../../domain/enrollment.interface";
import Chip from "@mui/material/Chip";
import { getStatusColor } from "../../utils/general";
import { useAppTheme } from "../../utils/theme";

dayjs.extend(utc);
dayjs.extend(timezone);

function createData(
	id: number,
	day: ReactNode,
	validationNumber: ReactNode,
	validationType: ReactNode,
	validationStatus: ReactNode,
	validation: ReactNode,
	validationAttemps: ReactNode,
	rewardEarned: ReactNode,
	action: ReactNode,
): DataTableCell {
	return {
		id,
		day,
		validationNumber,
		validationType,
		validationStatus,
		validation,
		validationAttemps,
		rewardEarned,
		action,
	};
}

type Props = {
	enrollmentId: string;
	participantId: string;
};

type Ordering = {
	pageNumber: number;
	pageSize: number;
	sortOrder: number;
	sortField: string;
};

const ProfileResults: FC<Props> = ({ enrollmentId, participantId }) => {
	const currencySetting = useCurrencySetting();
	const IANATimezone = useIANATimeZoneSetting();
	const headCells: HeadCell[] = headCellsConfig.profileResults;
	const theme = useAppTheme();

	const orderingState = useState<Ordering>({
		pageNumber: 0,
		pageSize: 10,
		sortOrder: 1,
		sortField: "validationNumber",
	});

	const [sortOrdering, setSortOrdering] = orderingState;

	const {
		data: dataResponse,
		refetch: refetchResultList,
		isLoading,
	} = useMedicationProofList(
		enrollmentId,
		sortOrdering.pageNumber,
		sortOrdering.pageSize,
		sortOrdering.sortField,
		sortOrdering.sortOrder,
	);

	const { data: medicationProofList, total: totalCount } = dataResponse || {};

	const rows = useMemo(() => {
		const actionMenu = [
			{
				value: "View images",
				onClick: () => {},
			},
		];

		return medicationProofList?.map(
			(item: MedicationProofData, index: number) => {
				let validationTypeText: MedicationProofType | string;
				switch (item.validationType) {
					case MedicationProofType.PUSH:
						validationTypeText = "Validation requested";
						break;
					case MedicationProofType.FIRST_TIME:
						validationTypeText = "First validation";
						break;
					case MedicationProofType.ORDER:
						validationTypeText = "Post-order";
						break;
					default:
						validationTypeText = item.validationType;
						break;
				}

				return createData(
					index,
					item.day,
					<Chip
						label={item.validationNumber === -1 ? "-" : item.validationNumber}
					/>,
					validationTypeText,
					<Chip
						sx={
							item.validationStatus === MedicationProofStatus.MISSED
								? undefined
								: {
										bgcolor: getStatusColor(item.validationStatus, theme),
									}
						}
						label={item.validationStatus}
					/>,
					dayjs(item.validationDate).tz(IANATimezone).format("MM/DD/YYYY"),
					<div className="flex justify-center">{item.numberOfAttempts}</div>,
					<div>
						{item.validationType === MedicationProofType.FIRST_TIME ||
						item.validationType === MedicationProofType.ORDER
							? `-`
							: `${currencySetting.mask} ${item.rewardEarned}`}
					</div>,
					<ActionMenu id={`${index}`} options={actionMenu} disabled={true} />,
				);
			},
		);
	}, [IANATimezone, currencySetting.mask, medicationProofList, theme]);

	useEffect(() => {
		refetchResultList();
	}, [sortOrdering]);

	return (
		<DataTable
			data={rows || []}
			headCells={headCells}
			order={sortOrdering.sortOrder === 1 ? "asc" : "desc"}
			orderBy={sortOrdering.sortField}
			rowsPerPage={sortOrdering.pageSize}
			page={sortOrdering.pageNumber}
			total={totalCount}
			onChangePage={(event, newPage) => {
				setSortOrdering((sortOrdering) => ({
					...sortOrdering,
					pageNumber: newPage,
				}));
			}}
			onChangeSize={(event) => {
				setSortOrdering((sortOrdering) => ({
					...sortOrdering,
					pageNumber: 0,
					pageSize: Number(event.target.value),
				}));
			}}
			onChangeSort={(order, orderBy) => {
				setSortOrdering((sortOrdering) => ({
					...sortOrdering,
					pageNumber: 0,
					sortField: orderBy as string,
					sortOrder: order === "asc" ? 1 : -1,
				}));
			}}
			isLoading={isLoading}
		/>
	);
};

export default ProfileResults;
