import React, { FC, useMemo } from "react";
import { useAppTheme } from "../../utils/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "../../components/Avatar";
import {
	useCurrencySetting,
	useIANATimeZoneSetting,
} from "../../hooks/useSetting";
import { toTitleCase } from "../../utils/toTitleCase";
import { ParticipantItem } from "@/domain/participant.interface";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { useGetAccountSettings } from '../../hooks/useSetting/index';

dayjs.extend(utc);
dayjs.extend(timezone);

const ProfileInformation: FC<{ participant: ParticipantItem }> = ({
	participant,
}) => {
	const theme = useAppTheme();
	const currencySetting = useCurrencySetting();
	const IANATimezone = useIANATimeZoneSetting();

	const { accountSettings, isLoadingAccountSettings, refetchAccountSettings } = useGetAccountSettings(`${process.env.REACT_APP_ACCOUNT_ID}`)
	
	const country = useMemo(() => {
		if (!accountSettings) {
			return "US";
		}

		const countrySetting = accountSettings.find((setting) => setting.name === "country");
		return countrySetting?.default as string || "US";
	}, [accountSettings, isLoadingAccountSettings]);
	
	return (
		<CardContent className="space-y-4">
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					sx={{
						flex: "1 1 0%",
					}}
				>
					<Typography variant="headlineSmall">Information</Typography>
					<Typography
						variant="bodyMedium"
						sx={{
							display: "flex",
							gap: "0.25rem",
							color: (theme) => theme.palette.textMain.main2,
							fontWeight: 900,
							textTransform: "uppercase",
						}}
					>
						{`
							${participant.program.name} (${dayjs(participant.program.startDate).tz(IANATimezone).format("MM/DD/YY")} 
							${participant.program.endDate ? `- ${dayjs(participant.program.endDate).tz(IANATimezone).format("MM/DD/YY")}` : ""})
							/ ${participant.organization.name}`}
					</Typography>
				</Box>

				<Avatar
					firstName={participant.user.firstName}
					lastName={participant.user.lastName}
				/>
			</Box>

			<Box sx={{
				display: "flex",
				gap: "1.25rem",
				width: "100%"
			}}>
				<List
					sx={{
						width: "50%",
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						maxWidth: "400px",
					}}
				>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Enrollment date:</strong>
							{participant.lastEnrollment ? dayjs(participant.lastEnrollment.startDate)
								.tz(IANATimezone)
								.format("MM/DD/YYYY") : "Not enrolled"}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>CM start date:</strong>
							{participant.lastEnrollment.cmStartDate ? dayjs(participant.lastEnrollment.cmStartDate)
								.tz(IANATimezone)
								.format("MM/DD/YYYY") : "Not started"}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Client ID number:</strong>
							{participant.participant.clientIdNumber || "N/A"}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Email:</strong>
							{participant.user.email || "-"}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Phone:</strong> {participant.user.countryPhoneCode ? `${formatPhoneNumber(participant.user.phoneNumber, participant.user.countryPhoneCode)}` : 
								participant.user.phoneNumber ? formatPhoneNumber(participant.user.phoneNumber, country) : "-"}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Date of birth:</strong> 
							{participant.participant.dateOfBirth ? dayjs(participant.participant.dateOfBirth)
								.tz(IANATimezone)
								.format("MM/DD/YYYY") : "N/A"}
						</Typography>
					</ListItem>
				</List>
				<List
					sx={{
						width: "50%",
						display: "flex",
						flexDirection: "column",
						gap: "0.875rem",
					}}
				>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Address:</strong> {participant.fullAddress || "N/A"}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Incentives earned:</strong>
							{currencySetting.mask + (participant?.incentivesEarned || 0).toFixed(2)}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>{new Date().getFullYear()} incentives:</strong>
							{`${currencySetting.mask}${participant?.totalAmountEarnedByCurrentYear?.toFixed(2)} of ${currencySetting.mask}${participant?.program?.maxEarnings.toFixed(2)}`}
						</Typography>
					</ListItem>
					<ListItem disablePadding>
						<Typography
							variant="bodyLarge"
							sx={{
								display: "flex",
								gap: "0.25rem",
								color: (theme) => theme.palette.textMain.dark2,
							}}
						>
							<strong>Status:</strong>
							<Chip
								sx={{
									bgcolor:
										participant.lastEnrollment.status === "ACTIVE"
											? theme.palette.secondary.light
											: theme.palette.outline.dark,
								}}
								label={
									<div className="text-xs">
										{toTitleCase(participant.lastEnrollment.status)}
									</div>
								}
							/>
						</Typography>
					</ListItem>
				</List>
			</Box>
		</CardContent>
	);
};

export default ProfileInformation;
