import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { SxProps } from "@mui/material";
import { AppTheme } from "../../utils/theme";

interface BasicDatepickerProps {
  value?: Dayjs | null;
  label: string;
  onChange?: (value: Dayjs | null) => void;
  sx?: SxProps<AppTheme> | undefined;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  minDate?: Dayjs;
  disableWrite?: boolean;
}

const BasicDatepicker: React.FC<BasicDatepickerProps> = ({
  value,
  label,
  onChange,
  sx,
  error,
  helperText,
  required = false,
  minDate,
  disableWrite = false,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          minWidth: 180,
          display: "flex",
          flexGrow: 1,
          maxWidth: "100%",
          ...sx,
        }}
      >
        <FormControl fullWidth error={error}>
          <DatePicker
            value={value}
            label={label}
            disableFuture={true}
            minDate={minDate}
            onChange={onChange}
            slotProps={{
              textField: {
                error: error,
                helperText: error ? helperText : "",  
                required: required,
                disabled: disableWrite,
                sx: disableWrite ? {
                  "& .MuiInputLabel-formControl": {
                    cursor: "not-allowed",
                    color: "rgba(0, 0, 0, 0.6)"
                  },
                } : {},
              }
            }}
          />
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
};

export default BasicDatepicker;
