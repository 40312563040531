import React, { useState } from 'react';
import { Box, Tabs, Tab, Grid, TextField, Button } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch, useForm } from 'react-hook-form';

import { ICreateUserForm, IUser } from '../../domain/user.interface';
import BasicSelect from '../../components/BasicSelect';

type Props = {
    control: Control<ICreateUserForm>;
    errors: FieldErrors<ICreateUserForm>;
    watch: UseFormWatch<ICreateUserForm>;
    setValue: UseFormSetValue<ICreateUserForm>;
    isEditMode: boolean;
};

export const UserFieldForm = ({ control, errors, isEditMode }: Props) => {
    return (
        <Box sx={{ width: '23rem', p: 1, m: 1 }}>
            <Grid container spacing={2} sx={{
                border: '1px solid #E0E0E0',
                borderRadius: 2,
                paddingRight: 2,
                paddingBottom: 2,
            }}>
                <Grid item xs={12}>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                required
                                label="First name"
                                error={!!errors.firstName}
                                helperText={errors.firstName ? errors.firstName.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                name={name}
                                required
                                fullWidth
                                id="lastName"
                                label="Last name"
                                error={!!errors.lastName}
                                helperText={errors.lastName ? errors.lastName.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                name={name}
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : null}
                                disabled={isEditMode}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                name={name}
                                fullWidth
                                id="phoneNumber"
                                label="Phone number"
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="npi"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                name={name}
                                fullWidth
                                id="npi"
                                label="NPI"
                                error={!!errors.npi}
                                helperText={errors.npi ? errors.npi.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="status"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                            <BasicSelect
                                handleChange={onChange}
                                value={value}
                                data={
                                    statusList(value)?.map((status) => {
                                        return { key: status.id, value: status.name };
                                    }) || []
                                }
                                isLoading={value === "PENDING"}
                                required
                                id="status"
                                label="Status"
                                error={!!errors.status}
                                errorMessage={errors.status?.message || ""}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

const statusList = (status: string) => {
    if (status === "PENDING") {
        return [{ id: "PENDING", name: "Pending Verification" }];
    }
    return [
        { id: "ACTIVE", name: "Active" },
        { id: "INACTIVE", name: "Inactive" },
    ];
}