export enum MedicationProofStatus {
	PENDING = "PENDING",
	SUCCESS = "SUCCESS",
	FAILED = "FAILED",
	MISSED = "MISSED",
}

export enum MedicationProofType {
	PUSH = "PUSH",
	FIRST_TIME = "FIRST_TIME",
	ORDER = "ORDER",
}

export interface MedicationProofData {
	day: string;
	validationNumber: number;
	validationType: MedicationProofType;
	validationStatus: MedicationProofStatus;
	validationDate: string;
	numberOfAttempts: number;
	rewardEarned: number;
}
