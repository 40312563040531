import dayjs from "dayjs";
import { TFunction } from "i18next";
import * as yup from "yup";

const editValidationSchema = (
  t: TFunction,
  minAgeAllowed: number,
  country: string,
  dateOfBirth: string
) => {
  return yup.lazy(() => {
    const dateOfBirthValidation = (): yup.StringSchema<string | undefined> => {
      return dateOfBirth === "mandatory"
        ? yup
					.string()
					.required(t("validations.required"))
          .test(
            "futureDate",
            "Date of birth cannot be in the future",
            (value) => {
              if (!value) return true;
              return dayjs(value).isBefore(dayjs());
            }
          )
					.test(
						"isOldEnough",
						t("validations.validator_birth_date", { minAgeAllowed }),
						(value) => {
							if (!value) return true;
							const birthdate = dayjs(value);
							const age = dayjs().diff(birthdate, "year");
							return age >= minAgeAllowed;
						}
					)
        : yup
					.string()
          .optional()
					.test(
            "futureDate",
            "Date of birth cannot be in the future",
            (value) => {
              if (!value) return true;
              return dayjs(value).isBefore(dayjs());
            }
          )
					.test(
						"isOldEnough",
						t("validations.validator_birth_date", { minAgeAllowed }),
						(value) => {
							if (!value) return true;
							const birthdate = dayjs(value);
							const age = dayjs().diff(birthdate, "year");
							return age >= minAgeAllowed;
						}
					);
    };

    const phoneNumberValidation = (): yup.StringSchema<string | undefined> => {
      switch (country) {
        case "US":
          return yup
            .string()
            .test(
              "min",
              t("validations.validator_digits_min", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 10;
              }
            )
            .test(
              "max",
              t("validations.validator_digits_max", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 10;
              }
            )
            .test("phoneNumber", t("validations.invalid_format"), (value) => {
              if (!value || value.trim() === "") return true;
              const phoneRegex = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
              return phoneRegex.test(value);
            });
        case "UK":
          return yup
            .string()
            .test(
              "min",
              t("validations.validator_digits_min", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 10;
              }
            )
            .test(
              "max",
              t("validations.validator_digits_max", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 10;
              }
            )
            .test("phoneNumber", t("validations.invalid_format"), (value) => {
              if (!value || value.trim() === "") return true;
              const phoneRegex = /^\(?\d{4}\)?[-. ]?\d{6}$/;
              return phoneRegex.test(value);
            });
        default:
          return yup.string();
      }
    };

    const localityValidation = (): yup.StringSchema<string | undefined> => {
      return country === "UK"
        ? yup
            .string()
            .test(
              "min",
              t("validations.min_length", { count: 3 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 3;
              }
            )
            .test(
              "max",
              t("validations.max_length", { count: 50 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 50;
              }
            )
        : yup.string();
    };

    const addressValidation = () => {
      return yup.object().shape({
        addressLine1: yup
          .string()
          .test("isRequired", t("validations.required"), function (value) {
            const { city, state, postalCode, zipCode } = this.parent;
            if (city || state || postalCode || zipCode) {
              return !!value;
            }
            return true;
          })
          .test(
            "min",
            t("validations.min_length", { count: 3 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length >= 3;
            },
          )
          .test(
            "max",
            t("validations.max_length", { count: 100 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length <= 100;
            },
          ),
        city: yup
          .string()
          .test(
            "isRequired",
            t("validations.required"),
            function (value) {
              const { addressLine1, state, postalCode, zipCode } = this.parent;
              if (addressLine1 || state || postalCode || zipCode) {
                return !!value;
              }
              return true;
            }
          )
          .test(
            "min",
            t("validations.min_length", { count: 3 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length >= 3;
            },
          )
          .test(
            "max",
            t("validations.max_length", { count: 50 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length <= 50;
            },
          ),
        state: yup
					.string()
          .test(
            "isRequired",
            t("validations.required"),
            function (value) {
              const { addressLine1, city, zipCode } = this.parent;
              if ((addressLine1 || city || zipCode) && country === "US") {
                return !!value;
              }
              return true;
            }
          ),
        postalCode: yup
          .string()
          .test(
            "isRequired",
            t("validations.required"),
            function (value) {
              const { addressLine1, city } = this.parent;
              if ((addressLine1 || city) && country === "UK") {
                return !!value;
              }

              return true;
            }
          )
          .test(
            "min",
            t("validations.validator_digits_min", { count: 6 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length >= 6;
            },
          )
          .test(
            "max",
            t("validations.validator_digits_max", { count: 8 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length <= 8;
            },
          ),
        zipCode: yup
          .string()
          .test(
            "isRequired",
            t("validations.required"),
            function (value) {
              const { addressLine1, city, state } = this.parent;
              if ((addressLine1 || city || state) && country === "US") {
                return !!value;
              }
              return true;
            }
          )
          .test(
            "min",
            t("validations.validator_digits_min", { count: 5 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length >= 5;
            },
          )
          .test(
            "max",
            t("validations.validator_digits_max", { count: 5 }),
            (val) => {
              if (!val || val.length === 0) return true;
              return val.length <= 5;
            },
          )
      });
    };

    return yup.object().shape({
      firstName: yup
        .string()
        .required(t("validations.required"))
        .min(2, t("validations.min_length", { count: 2 }))
        .max(50, t("validations.max_length", { count: 50 })),
      lastName: yup
        .string()
        .required(t("validations.required"))
        .min(2, t("validations.min_length", { count: 2 }))
        .max(50, t("validations.max_length", { count: 50 })),
      dateOfBirth: dateOfBirthValidation(),
      email: yup
        .string()
        .required(t("validations.required"))
        .email(t("validations.invalid_format")),
      phoneNumber: phoneNumberValidation(),
      clientIdNumber: yup.string().optional(),
      status: yup.string().required(),
      program: yup.string().optional(),
      isArchived: yup.boolean().optional(),
      reason: yup.string().optional(),
      orgUnitId: yup
        .string()
        .required(t("validations.required")),
      addressLine1: yup.string(),
      addressLine2: yup
        .string()
        .test(
          "min",
          t("validations.min_length", { count: 3 }),
          (val) => {
            if (!val || val.length === 0) return true;
            return val.length >= 3;
          }
        )
        .test(
          "max",
          t("validations.max_length", { count: 100 }),
          (val) => {
            if (!val || val.length === 0) return true;
            return val.length <= 100;
          }
        ),
      locality: localityValidation(),
      city: yup.string(),
      postalCode: yup.string(),
      zipCode: yup.string(),
      state: yup.string(),
    }).concat(addressValidation());
  });
};

export default editValidationSchema;
