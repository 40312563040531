import { ISettingResponse } from "@/domain/setting.interface";
import { settingService } from "../../services/setting.service";
import { useQuery } from "@tanstack/react-query";

const { findAll, findByAccount } = settingService;

export const useGetSetting = () => {
  const userQuery = useQuery({
    queryKey: ["get-setting-list"],
    queryFn: findAll,
    staleTime: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
  });

  return userQuery;
};

export const useGetAccountSettings = (accountId: string, settingName?: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-account-setting-list", accountId, settingName],
    queryFn: () => findByAccount({ accountId, settingName }),
    staleTime: 10 * 1000, // 10 seconds in milliseconds
  });

  return {
    accountSettings: data,
    isLoadingAccountSettings: isLoading,
    refetchAccountSettings: refetch,
  };
}

export const useCurrencySetting = (): ISettingResponse => {
  const currencySymbol = { USD: "$", EUR: "€", GBP: "£" };
  const { data } = useGetSetting();
  const currencySetting = data?.find((setting) => setting.name === "currency");

  if (currencySetting) {
    // @ts-expect-error - the default value is a string
    currencySetting.mask = currencySymbol[currencySetting?.default];
  }

  // @ts-expect-error - the default value is a string
  return currencySetting || { mask: "$", default: "USD" };
};

export const useIANATimeZoneSetting = (): string => {
  const { data } = useGetSetting();
  const timeZoneSetting = data?.find(
    (setting) => setting.name === "IANATimeZone"
  );

  return (timeZoneSetting?.default as string) || "America/New_York";
};
