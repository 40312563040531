import { create } from "zustand";

export interface MenuStore {
	openList: {[key: string]: boolean};
	active: string;
	toggleOpen: (id: string) => void;
	setActive: (id: string) => void;
}

export interface MenuItem {
	id: string;
	icon: string;
	title: string;
	route: string;
	type: "parent" | "link" | "child" | "action";
	open: boolean;
	active: boolean;
	children?: MenuItem[];
}

export const useMenuStore = create<MenuStore>((set) => ({
	openList: {},
	active: '',
	setActive: (id) => set({active: id}),
	toggleOpen: (id) =>
		set((state) => ({
			openList: {
				...state.openList ,
				[id]: !state.openList[id]
			}
		})),
}));