import { styled } from "@mui/material/styles";
import ListItemButton, {
	ListItemButtonProps,
} from "@mui/material/ListItemButton";

export interface MenuItemButtonProps extends ListItemButtonProps {
	open?: boolean;
	active?: boolean;
}

export const MenuItemButton = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== "open",
})<MenuItemButtonProps>(({ theme, open, active }) => ({
	borderRadius: "8px",
	backgroundColor: active ? theme.palette.menu.light : "transparent",
	color: active ? "#ffffff" : "#DFDFDF",
	".MuiListItemIcon-root": {
		color: active ? "#ffffff" : "#DFDFDF",
	},
	":hover": {
		backgroundColor: theme.palette.menu.light2, // Background color on hover
		color: "#DFDFDF", // Text color on hover
		".MuiListItemIcon-root": {
			color: "#DFDFDF", // Icon color on hover
		},
	},

}));
