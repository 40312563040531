import { FC, useEffect, useMemo, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAppTheme } from "../../utils/theme";

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../hooks/useSession";

import TotalNumber from "../../components/TotalNumber/TotalNumber";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import { Filters } from "../Participant/ListFilters";
import { Program } from "../../domain/program.interface";
import { usePrograms, useOrganizationUnits, useOrganizationLalbels } from "../../hooks/useParticipant";
import { useDashboardTotals } from "../../hooks/useGeneral";
import { useCurrencySetting } from "../../hooks/useSetting";
import { useCreateAuditlog } from '../../hooks/useAuditlog/index';
import { MODULE_NAME } from '../../domain/auditlog.enum';

const Dashboard: FC = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { data: userInfo } = useSession();
	const theme = useAppTheme();
	const currencySetting = useCurrencySetting();

	const createAuditlog = useCreateAuditlog();

	const filtersState = useState<Filters>({
		campaignId: "",
		orgUnitsIds: [""],
		orgUnitLabelId: "",
		status: ["ACTIVE"],
		search: "",
		pageNumber: 0,
		pageSize: 10,
		sortField: "user.fullName",
		sortOrder: 1,
		isArchived: false,
	});

	const [filters, setFilters] = filtersState;

	const [programsOptions, setProgramsOptions] = useState<Array<{ key: string; value: string }>>([]);
	const [programSelected, setProgramSelected] = useState<Program | undefined>();

	const {
		programs,
		isLoadingPrograms,
		refetchPrograms,
	} = usePrograms({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [""],
	});

	const programOUIds = useMemo(() => {
		if (programSelected) {
			return programSelected.orgUnitId || [];
		}
		return [];
	}, [programSelected]);

	const {
		organizationUnits,
		isLoadingOrganizationUnits,
		refetchOrganizationUnits,
	} = useOrganizationUnits({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: programOUIds,
		organizationLabels: "",
	});

	const uniqueLabels = useMemo(() => {
		if (!organizationUnits) {
			return [];
		}

		const labelSet = organizationUnits.reduce((acc, unit) => {
			unit.orgUnitLabelId.forEach(label => acc.add(label));
			return acc;
		}, new Set<string>());

		return Array.from(labelSet);
	}, [organizationUnits]);

	const {
		organizationLabels,
		isLoadingOrganizationLabels,
		refetchOrganizationLabels,
	} = useOrganizationLalbels({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationLabelIds: uniqueLabels,
	});

	useEffect(() => {
		if (programs && !isLoadingPrograms) {
			setProgramsOptions(
				[
					...programs.map((program) => ({
						key: program._id,
						value: program.name,
					})),
				] || [],
			);

			setFilters((filters) => ({ ...filters, campaignId: programs[0]._id }));
			setProgramSelected(programs[0]);
		}
	}, [programs, isLoadingPrograms]);

	const ouOptions = useMemo(() => {
		if (!organizationUnits) {
			return [];
		}

		return organizationUnits.map((ou) => ({
			key: ou.id,
			value: ou.name,
		}));
	}, [organizationUnits]);

	const {
		dashboardTotals,
		refetchTotals
	} = useDashboardTotals({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		programId: filters.campaignId,
		orgUnitId: filters.orgUnitsIds,
		orgUnitLabelId: filters.orgUnitLabelId,
	});

	useEffect(() => {
		refetchOrganizationUnits();
	}, [programSelected]);

	useEffect(() => {
		refetchOrganizationLabels();
	}, [organizationUnits]);

	useEffect(() => {
		refetchTotals();
	}, [filters]);

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current && userInfo?._id !== undefined) {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.DASHBOARD,
				action: "When entering",
				detail: "Viewed the dashboard",
				actionCode: "WEB_DASHBOARD_VIEW",
				option: "Dashboard",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			postAuditlogCalledRef.current = true;
		}
	}, [userInfo]);

	return (
		<div className="w-full">
			<div className="full-width-container mx-auto">
				<div className="flex flex-col items-center justify-start min-h-screen space-y-5">
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							<Typography variant="headlineMedium">
								Hello {userInfo?.firstName} {userInfo?.lastName}!
							</Typography>
							<Typography variant="bodyLarge">
								Here is what is going on today.
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								gap: "10px",
							}}
						>
							<BasicSelect
								handleChange={(value) => {
									setFilters((filters) => ({
										...filters,
										campaignId: value as string,
										orgUnitsIds: [""],
										orgUnitLabelId: "",
										pageNumber: 0,
									}));

									const selectedProgram = programs?.find(
										(program) => program._id === value,
									);

									setProgramSelected(selectedProgram);
								}}
								data={programsOptions || []}
								value={filters.campaignId}
								label="Campaign"
								id="participant-program"
								isLoading={isLoadingPrograms}
								className="min-w-[215px]"
								maxWidth={300}
							/>

							<BasicSelect
								handleChange={(value) => {
									setFilters((filters) => ({
										...filters,
										orgUnitsIds: value as string[],
										orgUnitLabelId: "",
										pageNumber: 0,
									}));
								}}
								data={ouOptions || []}
								value={filters.orgUnitsIds[0] === "" ? [] : filters.orgUnitsIds}
								label="Org unit(s)"
								multiple
								id="participant-org-units"
								isLoading={isLoadingOrganizationUnits}
								className="min-w-[215px]"
								maxWidth={300}
							/>

							<BasicSelect
								handleChange={(value) => {
									setFilters((filters) => ({
										...filters,
										orgUnitLabelId: value as string,
										orgUnitsIds: [""],
										pageNumber: 0,
									}));
								}}
								showClearOption
								data={organizationLabels || []}
								value={filters.orgUnitLabelId}
								label="Org unit label"
								id="participant-org-unit-label"
								isLoading={isLoadingOrganizationLabels}
								className="min-w-[215px]"
								maxWidth={300}
							/>
						</Box>
					</Box>

					<Box
						sx={{
							borderTop: "1px solid #C6C2DF",
							display: "flex",
							flexDirection: "column",
							gap: "1.25rem",
							paddingTop: "1.25rem",
							width: "100%",
						}}
					>
						<Typography variant="headlineSmall">
							Overview
						</Typography>
						<div className="flex flex-wrap items-start w-full gap-4">
							<TotalNumber label="Total incentives earned" count={`${currencySetting?.mask} ${dashboardTotals?.totalIncetiveEarned.toFixed(2)}`} />
							<TotalNumber label="Total validations (Success + Missed)" count={dashboardTotals?.totalValidation} />
							<TotalNumber
								label="Succesful validations"
								count={dashboardTotals?.totalValidationSuccess}
							/>
							<TotalNumber
								label="Missed validations"
								count={dashboardTotals?.totalValidationMissed}
							/>
						</div>
					</Box>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
