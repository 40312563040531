import { useQueryClient, QueryKey } from '@tanstack/react-query';

interface QueryKeyObject {
  queryKey: QueryKey;
}

const useManageQueries = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = (queryKeys: QueryKeyObject[]) => {
    queryKeys.forEach(({ queryKey }) => {
      queryClient.invalidateQueries({ queryKey });
    });
  };

  const removeQueries = (queryKeys: QueryKeyObject[]) => {
    queryKeys.forEach(({ queryKey }) => {
      queryClient.removeQueries({ queryKey });
    });
  };

  const getQueryData = <T>(queryKey: QueryKey): T | undefined => {
    return queryClient.getQueryData<T>(queryKey);
  };

  return { invalidateQueries, removeQueries, getQueryData };
};

export default useManageQueries;
