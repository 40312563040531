import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import DataTable, { HeadCell, DataTableCell } from "../../components/DataTable";
import { headCellsConfig } from "../../utils/tableConfig";
import { useAppTheme } from "../../utils/theme";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../hooks/useSession";
import {
	useOrganizationUnits,
	useQueueParticipants,
} from "../../hooks/useParticipant";
import useDebounce from "../../hooks/useDebounce";
import { useInviteParticipantModal } from "../../container/InviteParticipantModal/InviteParticipantModalContext";
import ActionMenu from "../../components/ActionMenu/ActionMenu";
import dayjs from "dayjs";
import { Filters } from "./ListFilters";
import BasicSelect from "../../components/BasicSelect";
import {
	getRegistrationStageText,
	getStatusColor,
	getStatusText,
} from "../../utils/helperStatus";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import GenericAlertModal from "../../components/Modals/AlertModal/index";
import { useCreateAuditlog } from "../../hooks/useAuditlog/index";
import { ParticipantQueueItem } from "../../domain/participant.interface";
import EnrollParticipantsModal from "../../components/Modals/EnrollParticipantsModal/index";
import { MODULE_NAME } from "../../domain/auditlog.enum";
import { usePatchDenyParticipants } from "../../hooks/useParticipant";
import { ErrorResponse } from "../../domain/error.interface";
import { useFeedBack } from "../../providers/FeedBackProvider/FeedBackContext";

// Delete when the call to the API is implemented
function createData(
	id: number,
	fullname: ReactNode,
	enrollmentDate: ReactNode,
	registrationStage: ReactNode,
	onboardingType: ReactNode,
	status: ReactNode,
	action: ReactNode,
): DataTableCell {
	return {
		id,
		fullname,
		enrollmentDate,
		registrationStage,
		onboardingType,
		status,
		action,
	};
}

type QueueFilters = Omit<
	Filters,
	"campaignId" | "orgUnitsIds" | "orgUnitLabelId" | "status"
> & {
	orgUnitsId: string;
	onboardingType: string;
	registration: string[];
};

const EnrollQueueTab: FC = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { data: userInfo } = useSession();
	const theme = useAppTheme();
	const { showSnackBar } = useFeedBack();

	const [openGenericDialog, setOpenGenericDialog] = useState(false);
	const [openEnrollParticipants, setOpenEnrollParticipants] = useState(false);
	const [openDenyDialog, setOpenDenyDialog] = useState(false);
	const [openAuditLogModal, setOpenAuditLogModal] = useState(false);
	const [showArchivedParticipants, setShowArchivedParticipants] =
		useState(false);

	const [selectedOrgUnit, setSelectedOrgUnit] = useState<string>("");
	const [noOuAssigned, setNoOuAssigned] = useState<boolean>(false);
	const [showPreviouslyDenied, setShowPreviouslyDenied] =
		useState<boolean>(false);

	const [participantsToEnroll, setParticipantsToEnroll] = useState<string[]>(
		[],
	);
	const [participantsToArchive, setParticipantsToArchive] = useState<string[]>(
		[],
	);
	const [participantToDelete, setParticipantToDelete] = useState<string>("");

	const filtersState = useState<QueueFilters>({
		orgUnitsId: "",
		search: "",
		onboardingType: "",
		registration: [],
		pageNumber: 0,
		pageSize: 10,
		sortField: "user.fullName",
		sortOrder: 1,
		isArchived: false,
	});

	const [filters, setFilters] = filtersState;

	const createAuditlog = useCreateAuditlog();
	const [inputValue, setInputValue] = useState<string | undefined>(undefined);
	const debouncedInputValue = useDebounce({ value: inputValue });
	const patchDenyParticipants = usePatchDenyParticipants({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const { data, isLoadingQueueParticipants, refetchQueueParticipants } =
		useQueueParticipants({
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			orgUnitId: filters.orgUnitsId,
			registration: filters.registration,
			onboardingType: filters.onboardingType,
			search: filters.search,
			pageNumber: filters.pageNumber,
			pageSize: filters.pageSize,
			sortField: filters.sortField,
			sortOrder: filters.sortOrder,
		});

	const { participants: queueParticipants, totalCount } = data || {};

	const {
		organizationUnits,
		isLoadingOrganizationUnits,
		refetchOrganizationUnits,
	} = useOrganizationUnits({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [""],
		organizationLabels: "",
	});

	const ouOptions = useMemo(() => {
		if (!organizationUnits || organizationUnits.length === 0) {
			return [];
		}

		setSelectedOrgUnit(organizationUnits[0].id);
		setFilters({ ...filters, orgUnitsId: organizationUnits[0].id });

		return organizationUnits.map((ou) => ({
			key: ou.id,
			value: ou.name,
		}));
	}, [organizationUnits]);

	const rows = useMemo(() => {
		if (queueParticipants && queueParticipants?.length === 0) {
			return [];
		}

		return (
			queueParticipants?.map((item: ParticipantQueueItem, index: number) => {
				const actionMenu = [
					{
						value: "Delete",
						onClick: () => {
							setParticipantToDelete(item._id);
							setOpenGenericDialog(true);
						},
					},
				];

				const row = createData(
					index,
					<div className="flex items-center">
						<Checkbox
							onChange={(event) => {
								const checked = event.target.checked;
								if (checked) {
									setParticipantsToEnroll((prev) => [...prev, item._id]);
								} else {
									setParticipantsToEnroll((prev) =>
										prev.filter((id) => id !== item._id),
									);
								}
							}}
							checked={participantsToEnroll.includes(item._id)}
						/>
						{`${item.user.firstName} ${item.user.lastName}`}
					</div>,
					<div>{dayjs(item.dateJoined).format("MM/DD/YYYY hh:mm[h]")}</div>,
					<div>{getRegistrationStageText(item.status)}</div>,
					<div>{item.onboarding.type || ""}</div>,
					<Chip
						sx={{
							bgcolor: getStatusColor(item.status, theme),
						}}
						label={<div className="text-xs">{getStatusText(item.status)}</div>}
					/>,
					<ActionMenu id={item._id} options={actionMenu} />,
				);

				return row;
			}) || []
		);
	}, [queueParticipants, participantsToEnroll, theme]);

	const headCells: HeadCell[] = headCellsConfig.enrollQueue;

	const handleEnroll = () => setOpenEnrollParticipants(true);

	const handleDeny = () => setOpenDenyDialog(true);

	const onDeny = async () => {
		setOpenDenyDialog(false)

		createAuditlog.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.PARTICIPANTS,
			action: "When denying enrollment to participants from the queue",
			detail: "Denied enrollment to one or more participants from the enroll queue",
			actionCode: "WEB_PART_DENY_FROM_QUEUE",
			option: "Deny",
			createdBy: userInfo?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		});

		try {
			await patchDenyParticipants.mutateAsync({
				participantIds: participantsToEnroll,
				userId: userInfo?._id || "",
			}, {
				onSuccess: () => refetchQueueParticipants(),
			});
		} catch (error) {
			showSnackBar(
				(error as ErrorResponse).error?.errorMessage ||
					(error as ErrorResponse).message?.[0] ||
					"An error occurred",
				"error",
			);
		}
	}

	useEffect(() => {
		if (debouncedInputValue !== undefined) {
			setFilters((filters) => ({
				...filters,
				search: debouncedInputValue,
			}));

			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When searching",
				detail: "	Searched within the list of participants in queue",
				actionCode: "WEB_PART_LIST_QUEUE_SEARCH",
				option: "Participants list (queue tab)",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
		}
	}, [debouncedInputValue]);

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current) {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When entering",
				detail: "Viewed the list of participants in queue",
				actionCode: "WEB_PART_LIST_QUEUE_VIEW",
				option: "Participants list (queue tab)",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			postAuditlogCalledRef.current = true;
		}
	}, [createAuditlog, userInfo]);

	useEffect(() => {
		refetchQueueParticipants();
		setParticipantsToEnroll([]);
	}, [filters]);

	useEffect(() => {
		refetchOrganizationUnits();
		setParticipantsToEnroll([]);
	}, [selectedOrgUnit]);

	return (
		<>
			<Box sx={{ width: "100%" }} className="space-y-5">
				<Box sx={{ display: "flex", gap: "10px" }}>
					<div className="flex flex-1 bg-surfaceCardsmall rounded-lg px-4 py-3 max-w-full items-center justify-between h-14">
						<InputBase
							className="flex-1"
							placeholder="Search participant"
							inputProps={{ "aria-label": "search" }}
							onChange={(e) => setInputValue(e.target.value)}
						/>
						<SearchIcon className="text-icon-primary" />
					</div>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							gap: "10px",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={handleEnroll}
							sx={{ borderRadius: "0.5rem" }}
							disabled={participantsToEnroll.length === 0}
						>
							ENROLL
						</Button>
						<Button
							variant="outlined"
							onClick={handleDeny}
							sx={{ borderRadius: "0.5rem" }}
							disabled={showPreviouslyDenied || participantsToEnroll.length === 0}
						>
							DENY
						</Button>
					</Box>
				</Box>

				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: {
							xs: "1fr",
							sm: "repeat(2, 1fr)",
							md: "repeat(3, 1fr)",
							lg: "repeat(4, 1fr)",
						},
						gap: "10px",
						alignItems: "center",
						width: "100%",
					}}
				>
					<BasicSelect
						handleChange={(value) => {
							setFilters((filters) => ({
								...filters,
								orgUnitsId: value as string,
								pageNumber: 0,
							}));
						}}
						data={ouOptions || []}
						value={filters.orgUnitsId}
						label="Org unit(s)"
						id="participant-org-units"
						isLoading={isLoadingOrganizationUnits || noOuAssigned}
					/>

					<BasicSelect
						handleChange={(value) => {
							setFilters((filters) => ({
								...filters,
								onboardingType: value as string,
								pageNumber: 0,
							}));
						}}
						data={[
							{ key: "MANUAL", value: "Manual" },
							{ key: "SELF-ENROLLMENT", value: "Self-enrollment" },
						]}
						showClearOption
						value={filters.onboardingType}
						label="Onboarding type"
						id="onboarding-type"
					/>

					<BasicSelect
						handleChange={(value) => {
							console.log(value);
							setFilters((filters) => ({
								...filters,
								registration: [value] as string[],
								pageNumber: 0,
							}));
						}}
						data={[
							{ key: "ACTIVE", value: "Completed" },
							{
								key: "PENDING_VERIFICATION,PENDING_ACTIVATION",
								value: "Pending",
							},
							...(showPreviouslyDenied
								? [{ key: "DENIED", value: "Denied" }]
								: []),
						]}
						showClearOption
						value={filters.registration}
						label="Registration"
						id="registration"
						isLoading={isLoadingQueueParticipants || showPreviouslyDenied}
					/>

					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<FormControlLabel
							sx={{ height: "2rem" }}
							control={<Checkbox />}
							onChange={(_, checked) => {
								setFilters((filters) => ({
									...filters,
									orgUnitsId: checked ? "" : selectedOrgUnit,
									pageNumber: 0,
								}));

								setNoOuAssigned(checked);
							}}
							label="No OU assigned"
							className="!hidden"
						/>

						<FormControlLabel
							sx={{ height: "2rem" }}
							control={<Checkbox />}
							onChange={(_, checked) => {
								setFilters((filters) => ({
									...filters,
									registration: checked ? ["DENIED"] : [],
									pageNumber: 0,
								}));

								setShowPreviouslyDenied(checked);
							}}
							label="Show previously denied"
						/>
					</Box>
				</Box>

				<DataTable
					data={rows}
					headCells={headCells}
					order={filters.sortOrder === 1 ? "asc" : "desc"}
					orderBy={filters.sortField}
					rowsPerPage={filters.pageSize}
					page={filters.pageNumber}
					total={totalCount}
					onChangePage={(event, newPage) => {
						setFilters((filters) => ({
							...filters,
							pageNumber: newPage,
						}));
					}}
					onChangeSize={(event) => {
						setFilters((filters) => ({
							...filters,
							pageNumber: 0,
							pageSize: Number(event.target.value),
						}));
					}}
					onChangeSort={(order, orderBy) => {
						setFilters((filters) => ({
							...filters,
							pageNumber: 0,
							sortField: orderBy as string,
							sortOrder: order === "asc" ? 1 : -1,
						}));
					}}
					isLoading={isLoadingQueueParticipants || patchDenyParticipants.isPending}
				/>
			</Box>

			{openGenericDialog ? (
				<GenericAlertModal
					title="Delete"
					description={
						<div className="space-y-5">
							<p>
								Deleting this participant will revoke their access to the
								application and prevent them from being enrolled in the future.
							</p>
							<p>Are you sure you want to delete this participant?</p>
						</div>
					}
					submitText="Delete"
					cancelText="Cancel"
					showIcon={false}
					onClick={() => {
						setOpenGenericDialog(false);
						// Logic to delete participant}
					}}
					onCancel={() => setOpenGenericDialog(false)}
				/>
			) : null}

			{openDenyDialog ? (
				<GenericAlertModal
					title="Deny enrollment"
					description={
						<div className="space-y-5">
							<p>
								Denying enrollment will remove the participant(s) from the list but will not revoke their access to the application. You can enroll them later by using the "Show previously denied" filter.
							</p>
							<p>Are you sure you want to deny the enrollment?</p>
						</div>
					}
					submitText="Deny Enrollment"
					cancelText="Cancel"
					showIcon={false}
					onClick={() => onDeny()}
					onCancel={() => setOpenDenyDialog(false)}
				/>
			) : null}

			{openEnrollParticipants ? (
				<EnrollParticipantsModal
					open={openEnrollParticipants}
					onClose={() => setOpenEnrollParticipants(false)}
					participantIds={participantsToEnroll}
					onSuccess={() => {
						refetchQueueParticipants();
						setOpenEnrollParticipants(false);
						setParticipantsToEnroll([]);
					}}
				/>
			) : null}
		</>
	);
};

export default EnrollQueueTab;
