import { FC, useState, useEffect, useCallback, useRef } from "react";
import { TextField, CircularProgress, Box } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import useDebounce from "../../hooks/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useAppTheme } from "../../utils/theme";
import { useSearchBar } from "../../hooks/useGeneral";

type Props = {
	children?: React.ReactNode;
};

type SearchResult = {
	_id: string;
	fullname: string;
};

const SearchBar: FC<Props> = () => {
	const [searchedVal, setSearchedVal] = useState("" as string | undefined);
	const navigate = useNavigate();
	const theme = useAppTheme();
	const [options, setOptions] = useState<SearchResult[]>([]);
	const debouncedInputValue = useDebounce({ value: searchedVal as string, delay: 100 });

	const {
		searchBarResults,
		isLoading,
		isFetching,
		refetchSearchBarResults,
	} = useSearchBar({ searchQuery: debouncedInputValue });

	const memoizedRefetch = useCallback(refetchSearchBarResults, [
		refetchSearchBarResults,
	]);

	useEffect(() => {
		memoizedRefetch();
	}, [debouncedInputValue, memoizedRefetch]);

	useEffect(() => {
		if (searchBarResults) {
			setOptions(searchBarResults);
		}
	}, [searchBarResults]);

	const autoC = useRef<HTMLDivElement | null>(null);

	return (
		<Autocomplete
			ref={autoC}
			sx={{
				minWidth: 180,
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				width: "100%",
				maxWidth: "565px",
				".MuiInputBase-root": {
					padding: "9px !important",
				},
				".MuiAutocomplete-popupIndicator": {
					transform: "rotate(0) !important",
				}
			}}
			id="autocomplete-beneficiary-search"
			getOptionLabel={(option) => option.fullname}
			options={options}
			noOptionsText={
				isFetching ? (
					<Box display="flex" justifyContent="center" alignItems="center">
						<CircularProgress sx={{ color: theme.palette.primary.main }} />
					</Box>
				) : (
					"No data found"
				)
			}
			onInputChange={(event, newInputValue) => {
				setSearchedVal(newInputValue);
			}}
			onChange={(event, newValue) => {
				if (newValue === null) return;
				
				setSearchedVal(undefined);
				navigate(`/participants/${newValue?._id}`,  { replace: true });

				setTimeout(() => {
					if (autoC.current) {
						const ele = (autoC.current as HTMLInputElement).getElementsByClassName('MuiAutocomplete-clearIndicator');

						if (ele[0]) {
							(ele[0] as HTMLInputElement).click();
						}
					}
				}, 300);
			}}
			popupIcon={
				<SearchIcon
					className="text-icon-primary"
				/>
			}
			renderInput={(params) => (
				<TextField
					sx={{
						backgroundColor: theme.palette.surface.main,
						borderRadius: ".5rem",
						"& fieldset": { border: "none" },
					}}
					focused={false}
					{...params}
					placeholder="Search..."
				/>
			)}
		/>
	);
};

export default SearchBar;
