import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BasicSelect from "../../BasicSelect/index";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useCreateAuditlog } from "../../../hooks/useAuditlog";
import reenrollValidationSchema from "./validation.schema";
import { useFeedBack } from "../../../providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "../../../domain/error.interface";
import { useEffect, useMemo, useState } from "react";
import { useSession } from "../../../hooks/useSession";
import { useAppTheme } from "../../../utils/theme";
import { ParticipantItem } from "../../../domain/participant.interface";
import useManageQueries from "../../../hooks/useManageQueries";
import {
	usePrograms,
	useOrganizationUnits,
	usePatchParticipantReenroll,
} from "../../../hooks/useParticipant";
import CircularProgress from "@mui/material/CircularProgress";
import { MODULE_NAME } from "../../../domain/auditlog.enum";

const ReenrollParticipantDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: `${theme.spacing(2)} ${theme.spacing(2.5)} 0`,
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(2.5),
	},
}));

interface ReenrollParticipantModalProps {
	open: boolean;
	onClose: () => void;
	participant: ParticipantItem;
}

interface FormData {
	organizationUnit: string;
	program: string;
	reason: string;
}

enum EnrollmentReasons {
	RENEWED_MOTIVATION_OR_INTEREST = "RENEWED_MOTIVATION_OR_INTEREST",
	DISENROLLED_FROM_ANOTHER_PROGRAM = "DISENROLLED_FROM_ANOTHER_PROGRAM",
	INCOMPLETE_INITIAL_PARTICIPATION = "INCOMPLETE_INITIAL_PARTICIPATION",
	ADHERENCE_REINFORCEMENT = "ADHERENCE_REINFORCEMENT",
	REQUIREMENT_FOR_A_NEW_JOB = "REQUIREMENT_FOR_A_NEW_JOB",
}

export default function ReenrollParticipantModal({
	open,
	onClose,
	participant,
}: ReenrollParticipantModalProps) {
	const theme = useAppTheme();
	const { t } = useTranslation("common");
	const { data: userInfo } = useSession();
	const { showSnackBar } = useFeedBack();
	const { invalidateQueries } = useManageQueries();

	const createAuditlogMutation = useCreateAuditlog();
	const patchParticipantReenroll = usePatchParticipantReenroll({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const { isPending } = patchParticipantReenroll;

	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [ouIdSelected, setOuIdSelected] = useState<string | undefined>();

	const { programs, isLoadingPrograms, refetchPrograms } = usePrograms({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [ouIdSelected || ""],
		isRegistering: true,
	});

	const { organizationUnits } = useOrganizationUnits({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [""],
		organizationLabels: "",
		isRegistering: true,
	});

	const ouOptions = useMemo(() => {
		if (!organizationUnits) {
			return [];
		}

		return organizationUnits.map((ou) => ({
			key: ou.id,
			value: ou.name,
		}));
	}, [organizationUnits]);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(reenrollValidationSchema(t)),
		mode: "all",
	});

	const onSubmit = async (data: FormData) => {
		createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.PARTICIPANTS,
			option: "Enroll",
			actionCode: "WEB_PART_REENROLL",
			action: "When re-enrolling a participant",
			detail: `DRe-enrolled the participant ${participant.user.firstName} ${participant.user.lastName}`,
			createdBy: userInfo?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			programId: data.program,
			orgUnitId: data.organizationUnit,
		});

		const reenrollData = {
			reason: data.reason,
			orgUnitId: data.organizationUnit,
			programId: data.program,
			participantId: participant._id,
			enrollmentId: participant.lastEnrollment._id,
			userId: userInfo?._id || "",
		};

		try {
			await patchParticipantReenroll.mutateAsync(reenrollData, {
				onSuccess: (message) => {
					invalidateQueries([
						{ queryKey: ["nalcam-enrolled-participants"] },
						{ queryKey: ["nalcam-queue-participants"] },
						{ queryKey: ["nalcam-participant-profile", participant._id] },
					]);

					showSnackBar(message, "success");

					onClose();
				},
			});
		} catch (error) {
			showSnackBar(
				(error as ErrorResponse).error?.errorMessage ||
					(error as ErrorResponse).message?.[0] ||
					"An error occurred",
			);
		}
	};

	const reasonOptions = useMemo(() => {
		return [
			{
				key: EnrollmentReasons.RENEWED_MOTIVATION_OR_INTEREST,
				value: "Renewed motivation or interest",
			},
			{
				key: EnrollmentReasons.DISENROLLED_FROM_ANOTHER_PROGRAM,
				value: "Disenrolled from another program",
			},
			{
				key: EnrollmentReasons.INCOMPLETE_INITIAL_PARTICIPATION,
				value: "Incomplete initial participation",
			},
			{
				key: EnrollmentReasons.ADHERENCE_REINFORCEMENT,
				value: "Adherence reinforcement",
			},
			{
				key: EnrollmentReasons.REQUIREMENT_FOR_A_NEW_JOB,
				value: "Requirement for a new job",
			},
		] as { key: string; value: string }[];
	}, [participant]);

	useEffect(() => {
		if (programs && !isLoadingPrograms) {
			setProgramsOptions(
				[
					...programs.map((program) => ({
						key: program._id,
						value: program.name,
					})),
				] || [],
			);
		}
	}, [programs, isLoadingPrograms]);

	useEffect(() => {
		refetchPrograms();
	}, [ouIdSelected]);

	return (
		<ReenrollParticipantDialog
			onClose={() => {
				if (isPending) {
					return;
				}

				onClose();
			}}
			aria-labelledby="register-beneficiary-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
					maxWidth: 380,
					minHeight: 420,
				},
			}}
			open={open}
			disableEscapeKeyDown={isPending}
		>
			<DialogTitle
				sx={{ 
					m: 0, 
					p: 2.5,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
				id="register-beneficiary-dialog-title"
				variant="headlineSmall"
				color={(theme) => theme.palette.surface.light}
				bgcolor={(theme) => theme.palette.primary.dark}
			>
				Enroll

				<IconButton
					aria-label="close"
					onClick={() => {
						if (isPending) {
							return;
						}
		
						onClose();
					}}
					sx={{
						color: (theme) => theme.palette.surface.light,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					gap: "1rem",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box sx={{ width: "100%", pt: "20px" }}>
					<Box sx={{ display: "flex", flexDirection: "column", px: 0 }}>
						<Typography
							variant="headlineSmall"
							sx={{
								color: "#7D7979",
								textTransform: "uppercase",
								fontSize: "1.25rem",
							}}
						>
							{participant.user.firstName} {participant.user.lastName}
						</Typography>
						<Typography
							variant="bodySmall"
							sx={{
								color: "#7D7979",
								textTransform: "uppercase",
								lineHeight: "1.5rem",
							}}
						>
							Enrolled by: {userInfo?.firstName} {userInfo?.lastName}
						</Typography>
					</Box>
					<Box sx={{ py: 3, px: 0 }} className="space-y-4">
						<Stack direction="column" className="space-y-4">
							<Controller
								name="organizationUnit"
								control={control}
								defaultValue={undefined}
								render={({ field }) => (
									<BasicSelect
										{...field}
										data={ouOptions || []}
										value={field.value}
										required={true}
										label="Org unit"
										id="participant-org-unit"
										handleChange={(value) => {
											const selectedOuId = value as string;
											field.onChange(selectedOuId);

											setOuIdSelected(selectedOuId);
										}}
										error={!!errors.organizationUnit}
										errorMessage={errors.organizationUnit?.message || ""}
										isLoading={isLoadingPrograms}
									/>
								)}
							/>
							<Controller
								name="program"
								control={control}
								defaultValue={undefined}
								render={({ field }) => (
									<BasicSelect
										{...field}
										required
										data={programsOptions || []}
										value={field.value}
										label="Campaign"
										id="participant-campaign"
										handleChange={(value) => {
											const selectedProgramId = value as string;
											field.onChange(selectedProgramId);
										}}
										error={!!errors.program}
										errorMessage={errors.program?.message || ""}
										isLoading={isLoadingPrograms || ouIdSelected === undefined}
									/>
								)}
							/>
							<Controller
								name="reason"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<BasicSelect
										{...field}
										handleChange={(value) => {
											field.onChange(value);
										}}
										required
										data={reasonOptions}
										value={field.value}
										label="Reason of enrollment"
										id="beneficiary-status"
										error={!!errors.reason}
										errorMessage={errors.reason?.message || ""}
									/>
								)}
							/>
						</Stack>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "100%",
						gap: "0.5rem",
					}}
				>
					<Button
						size="large"
						onClick={onClose}
						variant="outlined"
						sx={{
							borderColor: theme.palette.outline.dark,
							color: theme.palette.text.primary,
							fontWeight: 500,
							padding: "8px 16px",
						}}
						disabled={isPending}
					>
						CANCEL
					</Button>
					<Button
						size="large"
						onClick={handleSubmit(onSubmit)}
						color="primary"
						variant="contained"
						sx={{
							textTransform: "none",
							textAlign: "center",
							padding: "8px 16px",
						}}
						disabled={isPending}
						startIcon={isPending ? <CircularProgress size={16} /> : null}
					>
						ENROLL
					</Button>
				</Box>
			</DialogActions>
		</ReenrollParticipantDialog>
	);
}
