import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BasicSelect from "../../BasicSelect/index";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useCreateAuditlog } from "../../../hooks/useAuditlog";
import disenrollValidationSchema from "./validation.schema";
import { useFeedBack } from "../../../providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "../../../domain/error.interface";
import { useMemo } from "react";
import { useSession } from "../../../hooks/useSession";
import { useAppTheme } from "../../../utils/theme";
import { ParticipantItem } from "../../../domain/participant.interface";
import useManageQueries from "../../../hooks/useManageQueries";
import { usePatchParticipantDisenroll } from "../../../hooks/useParticipant";
import CircularProgress from "@mui/material/CircularProgress";
import { MODULE_NAME } from "../../../domain/auditlog.enum";

const DisenrollParticipantDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: `${theme.spacing(2)} ${theme.spacing(2.5)} 0`,
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(2.5),
	},
}));

interface DisenrollParticipantModalProps {
	open: boolean;
	onClose: () => void;
	participant: ParticipantItem;
}

interface FormData {
	reason: string;
}

enum DisenrollReason {
	NON_COMPLIANCE_WITH_CAMPAIGN_RULES = "NON_COMPLIANCE_WITH_CAMPAIGN_RULES",
	PARTICIPANTS_CHANGES_IN_INTEREST = "PARTICIPANTS_CHANGES_IN_INTEREST",
	PARTICIPANTS_PERSONAL_REASONS = "PARTICIPANTS_PERSONAL_REASONS",
	LACK_OF_ENGAGEMENT_OR_PARTICIPATION = "LACK_OF_ENGAGEMENT_OR_PARTICIPATION",
	HEALTH_COMPLICATIONS = "HEALTH_COMPLICATIONS",
}

export default function DisenrollParticipantModal({
	open,
	onClose,
	participant,
}: DisenrollParticipantModalProps) {
	const theme = useAppTheme();
	const { t } = useTranslation("common");
	const { data: userInfo } = useSession();
	const { showSnackBar } = useFeedBack();
	const { invalidateQueries } = useManageQueries();

	const createAuditlogMutation = useCreateAuditlog();
	const patchParticipantDisenroll = usePatchParticipantDisenroll({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const { isPending } = patchParticipantDisenroll;

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(disenrollValidationSchema(t)),
		mode: "all",
	});

	const onSubmit = async (data: FormData) => {
		createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.PARTICIPANTS,
			option: "Disenroll",
			actionCode: "WEB_PART_DISENROLL",
			action: "When disenrolling a participant",
			detail: `Disenrolled the participant ${participant.user.firstName} ${participant.user.lastName}`,
			createdBy: userInfo?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			programId: participant.program._id,
			orgUnitId: participant.organization._id,
		});

		const { reason } = data;

		const disenrollData = {
			reason,
			userId: userInfo?._id || "",
			enrollmentId: participant.lastEnrollment._id,
		};

		try {
			await patchParticipantDisenroll.mutateAsync(disenrollData, {
				onSuccess: () => {
					invalidateQueries([
						{ queryKey: ["nalcam-enrolled-participants"] },
						{ queryKey: ["nalcam-queue-participants"] },
						{ queryKey: ["nalcam-participant-profile", participant._id] },
					]);

					showSnackBar("The participant has been disenrolled.", "success");

					onClose();
				},
			});
		} catch (error) {
			showSnackBar(
				(error as ErrorResponse).error?.errorMessage ||
					(error as ErrorResponse).message?.[0] ||
					"An error occurred",
			);
		}
	};

	const reasonOptions = useMemo(() => {
		return [
			{
				key: DisenrollReason.NON_COMPLIANCE_WITH_CAMPAIGN_RULES,
				value: "Non-compliance with campaign rules",
			},
			{
				key: DisenrollReason.PARTICIPANTS_CHANGES_IN_INTEREST,
				value: "Participant's changes in interest",
			},
			{
				key: DisenrollReason.PARTICIPANTS_PERSONAL_REASONS,
				value: "Participant's personal reasons",
			},
			{
				key: DisenrollReason.LACK_OF_ENGAGEMENT_OR_PARTICIPATION,
				value: "Lack of engagement or participation",
			},
			{
				key: DisenrollReason.HEALTH_COMPLICATIONS,
				value: "Health complications",
			},
		] as { key: string; value: string }[];
	}, [participant]);

	return (
		<DisenrollParticipantDialog
			onClose={() => {
				if (isPending) {
					return;
				}

				onClose();
			}}
			aria-labelledby="register-beneficiary-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
					maxWidth: 380,
					minHeight: 420,
				},
			}}
			open={open}
			disableEscapeKeyDown={isPending}
		>
			<DialogTitle
				sx={{ 
					m: 0, 
					p: 2.5,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
				id="register-beneficiary-dialog-title"
				variant="headlineSmall"
				color={(theme) => theme.palette.surface.light}
				bgcolor={(theme) => theme.palette.primary.dark}
			>
				Disenroll

				<IconButton
					aria-label="close"
					onClick={() => {
						if (isPending) {
							return;
						}
		
						onClose();
					}}
					sx={{
						color: (theme) => theme.palette.surface.light,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					gap: "1rem",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<Box sx={{ width: "100%", pt: "20px" }}>
					<Box sx={{ display: "flex", flexDirection: "column", px: 0 }}>
						<Typography
							variant="headlineSmall"
							sx={{
								color: "#7D7979",
								textTransform: "uppercase",
								fontSize: "1.25rem",
							}}
						>
							{participant.user.firstName} {participant.user.lastName}
						</Typography>
						<Typography
							variant="bodySmall"
							sx={{
								color: "#7D7979",
								textTransform: "uppercase",
								lineHeight: "1.5rem",
							}}
						>
							{participant.program.name} / {participant.organization.name} / Disenrolled by:{" "}
							{userInfo?.firstName} {userInfo?.lastName}
						</Typography>
					</Box>
					<Box sx={{ py: 3, px: 0 }} className="space-y-4">
						<Typography
							variant="bodyLarge"
							sx={{ color: (theme) => theme.palette.outline.dark2 }}
						>
							Are you sure you want to disenroll this user? Please select an
							option why:
						</Typography>
						<Stack direction="column" className="space-y-4">
							<Controller
								name="reason"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<BasicSelect
										{...field}
										handleChange={(value) => {
											field.onChange(value);
										}}
										required
										data={reasonOptions}
										value={field.value}
										label="Reason"
										id="beneficiary-status"
										error={!!errors.reason}
										errorMessage={errors.reason?.message || ""}
									/>
								)}
							/>
						</Stack>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					borderTop: "1px solid #ccc",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "100%",
						gap: "0.5rem",
					}}
				>
					<Button
						size="large"
						onClick={onClose}
						variant="outlined"
						sx={{
							borderColor: theme.palette.outline.dark,
							color: "#261F1F",
							padding: "8px 16px",
						}}
						disabled={isPending}
					>
						CANCEL
					</Button>
					<Button
						size="large"
						onClick={handleSubmit(onSubmit)}
						color="primary"
						variant="contained"
						sx={{
							textTransform: "none",
							textAlign: "center",
							padding: "8px 16px",
						}}
						disabled={isPending}
						startIcon={isPending ? <CircularProgress size={16} /> : null}
					>
						DISENROLL
					</Button>
				</Box>
			</DialogActions>
		</DisenrollParticipantDialog>
	);
}
