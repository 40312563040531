import { MenuItem } from "../domain/menu.interface";

export const menus: MenuItem[] = [
  {
    id: 'WEB_DASHBOARD_VIEW',
    title: '',
    icon: 'SpaceDashboardIcon',
    route: '',
    type: 'parent'
  },
  {
    id: 'WEB_PART_LIST_PROGRAM_VIEW',
    title: '',
    icon: 'PermIdentity',
    route: '',
    type: 'parent'
  },
  {
    id: 'WEB_USRS_MGMT_VIEW',
    title: '',
    icon: 'ManageAccountsIcon',
    route: '',
    type: 'parent'
  },
  {
    id: 'WEB_HELP_SUPPORT_VIEW',
    title: '',
    icon: 'HelpOutlineOutlined',
    route: '',
    type: 'parent'
  },
];


export const getIconOrDefault = (code: string) => {
	const icon = menus.find((item) => item.id === code);
	if (icon) return icon.icon;
	return "";
};
