import { InvitationPayload } from "@/domain/participant.interface";

export const invitationPayload: InvitationPayload = {
  data: {
    generateParticipant: true,
    data: {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        authenticationId: "",
        type: "email",
        status: "PENDING_VERIFICATION"
      },
      participant: {
        programId: null,
        status: "PENDING_VERIFICATION",
        facestatus: "pending",
        agreementStatus: false,
        dateJoined: new Date()
      },
      onboarding: {
        status: "pending",
        startDateTime: new Date(),
        endDateTime: new Date(Date.now()+1000*60*60*24*7)
      },
      enrollment: {
        programId: null,
        startDate: new Date(),
        endDate: new Date(Date.now()+1000*60*60*24*7),
        status: "ACTIVE"
      },
      enrollmentBalance: {
        amount: 0,
        currency: "USD",
        transactions: [
          {
            type: "IN",
            amount: 0,
            date: new Date(),
            mechanism: "NALOXONE_PROOF",
            operationId: ""
          }
        ]
      },
      medicationProof: {
        type: "FIRST_TIME",
        status: "PENDING",
        processDate: new Date(),
        remainingAttempts: 3
      }
    },
    feature:"invite-participant"
  },
  additionals: {
    dateOfBirth: "",
    orgUnitId: ""
  }
}