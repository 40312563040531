
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { findByAccount, getById } from "../services/roles.service";
import { IRole } from "../domain/rol.interface";

export const useGetRolesByAccount = (
	account: string,
	userId?: string,
): UseQueryResult<IRole[]> => {
	const userQuery = useQuery({
		queryKey: ["get-roles-list", account, userId],
		queryFn: () => findByAccount(account, userId as string),
		enabled: !!userId,
		staleTime: 1000 * 60,
	});

	return userQuery;
};

export const useGetRole = (
	id?: string,
): UseQueryResult<IRole> => {
	const userQuery = useQuery({
		queryKey: ["get-role", id],
		queryFn: () => getById(id as string),
		enabled: !!id,
		staleTime: 1000 * 60,
	});
	return userQuery;
};

