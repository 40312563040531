import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
	Alert,
	AlertColor,
	AlertPropsColorOverrides,
	Button,
	Card,
	CardContent,
	Chip,
	InputBase,
	Snackbar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { AppTheme, useAppTheme } from "../../utils/theme";
import { useMediaQuery } from "@mui/material";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import DataTable from "../../components/DataTable/DataTable";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	useDeleteUser,
	useGetUserList,
	useResendInviteEmail,
} from "../../hooks/useUser/useUser";
import { DataTableCell } from "@/components/DataTable/dataTable.interface";
import { useOrganizationUnits, usePrograms } from "../../hooks/useParticipant";
import { OrganizationUnit, Program } from "@/domain/program.interface";
import PersonRemove from "@mui/icons-material/PersonRemove";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import GenericAlertModal from "../../components/Modals/AlertModal";
import { OverridableStringUnion } from "@mui/types";
import ActionMenu from "../../components/ActionMenu";
import { IUserListResponse } from "@/services/users.service";
import { IUser } from "@/domain/user.interface";
import { MODULE_NAME } from "../../domain/auditlog.enum";
import { useSession } from "../../hooks/useSession";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AuditLogModal from "../../components/Modals/AuditLogModal";

const WEB_GEN_LIST_AUDITLOG_VIEW = "WEB_GEN_LIST_AUDITLOG_VIEW";

const UserList: FC = () => {
	const { data: userInfo, userHasAccessToCode } = useSession();
	const [searchText, setSearchText] = useState<string | undefined>("");
	const [isUserListLoading, setIsUserListLoading] = useState<boolean>(false);
	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [programSelected, setProgramSelected] = useState<Program | undefined>();
	const [oUSelected, setOUSelected] = useState<OrganizationUnit | undefined>();
	const [openResponseDelete, setIsDeleteDialogOpen] = useState(false);
	const [deletedUserInfo, setDeletedUserInfo] = useState({ email: "", id: "" });
	const [deleteResponseMessage, setDeleteResponseMessage] = useState("");
	const [isDeletingUser, setIsDeletingUser] = useState(false);
	const [showDeleleteUserError, setShowDeleteUserError] = useState(false);
	const [showSnackBar, setShowSnackbar] = useState<string>("");
	const [alertSeverity, setAlertSeverity] = useState<
		OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined
	>();
	const [openAuditLogModal, setOpenAuditLogModal] = useState(false);
	const [openAuditlogNoAccess, setOpenAuditlogNoAccess] = useState(false);

	const { mutateAsync: mutationDeleteUser } = useDeleteUser();
	const { mutateAsync: mutationResendInvitation } = useResendInviteEmail();

	const filtersState = useState({
		pageNumber: 0,
		pageSize: 10,
		sortField: "firstName",
		sortOrder: 1,
	});

	const canSeeAuditLog = useMemo(() => {
		return userHasAccessToCode(WEB_GEN_LIST_AUDITLOG_VIEW);
	}, [userHasAccessToCode, userInfo]);

	const [filters, setFilters] = filtersState;
	const {
		data: users,
		isLoading,
		refetch,
	} = useGetUserList({
		searchText,
		programId: programSelected?._id,
		orgUnitId: oUSelected?.id,
		pageNumber: filters.pageNumber,
		pageSize: filters.pageSize,
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const { programs, isLoadingPrograms } = usePrograms({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [""],
	});

	const { organizationUnits, isLoadingOrganizationUnits } =
		useOrganizationUnits({
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			organizationIds: [""],
			organizationLabels: "",
		});
	const { mutateAsync: createAuditLog } = useCreateAuditlog();
	const createAuditlogMutation = useCreateAuditlog();

	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const navigate = useNavigate();
	const theme = useAppTheme();
	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

	useEffect(() => {
		if (programs && !isLoadingPrograms) {
			setProgramsOptions(
				programs.map((program) => ({
					key: program._id,
					value: program.name,
				})) || [],
			);
		}
	}, [programs, isLoadingPrograms]);

	useEffect(() => {
		refetch();
	}, [filters]);

	const ouOptions = useMemo(() => {
		if (!organizationUnits) {
			return [];
		}

		return organizationUnits.map((ou) => ({
			key: ou.id,
			value: ou.name,
		}));
	}, [organizationUnits]);

	useEffect(() => {
		if (!isAuthenticated) {
			loginWithRedirect();
		}
	}, [isAuthenticated]);

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current && userInfo?._id !== undefined) {
			createAuditlogMutation.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				option: "User List",
				action: "When entering",
				module: MODULE_NAME.USERS,
				detail: "Viewed the list of users",
				actionCode: "WEB_USRS_LIST_VIEW",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			postAuditlogCalledRef.current = true;
		}
	}, [userInfo]);

	useEffect(() => {
		if (searchText) {
			const handler = setTimeout(() => {
				setIsUserListLoading(true);
			}, 300);
			return () => {
				clearTimeout(handler);
			};
		}
	}, [searchText]);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilters((prevFilter) => ({ ...prevFilter, pageNumber: 0 }));

		setSearchText(e.target.value);

		if (e.target.value.length === 1) {
			createSearchListAuditLog();
		}
	};

	const getStatusLabel = (status: string) => {
		switch (status) {
			case "PENDING":
			case "PENDING_ACTIVATION":
			case "PENDING_VERIFICATION":
				return "Pending";
			case "ACTIVE":
				return "Active";
			case "INACTIVE":
				return "Inactive";
			default:
				return status;
		}
	};

	const getStatusColor = (status: string, theme: AppTheme) => {
		switch (status) {
			case "ACTIVE":
				return theme.palette.secondary.light;
			case "PENDING":
			case "PENDING_ACTIVATION":
			case "PENDING_VERIFICATION":
				return theme.palette.warning.light;
			case "INACTIVE":
				return "#cccccc";
			default:
				return theme.palette.outline.dark;
		}
	};

	const formatData = (usersList: IUserListResponse): DataTableCell[] => {
		if (!usersList?.data?.length) return [];
		return usersList.data.map((user: IUser) => ({
			id: user._id,
			firstName: user.firstName || "",
			lastName: user.lastName || "",
			status: user.status ? (
				<Chip
					sx={{ bgcolor: getStatusColor(user.status, theme) }}
					label={<div className="text-xs">{getStatusLabel(user.status)}</div>}
				/>
			) : (
				""
			),
			role:
				user.roles && user.roles.length > 0
					? user.roles.map((role: any) => role.name).join(", ")
					: "",
			action: (
				<ActionMenu
					id={`action-menu-${user._id}`}
					options={[
						{
							value: "Edit",
							onClick: () => {
								navigate(`/users/${user._id}`);
							},
						},
						{
							value: "Resend verification link",
							onClick: () => {
								handleResendInvitation(user.email, user.firstName);
							},
						},
					]}
				/>
			),
		}));
	};


	const memoizedUserData = useMemo(
		() => formatData(users as IUserListResponse),
		[users],
	);

	const deleteUser = async (id: string, email: string) => {
		setIsDeleteDialogOpen(false);
		try {
			setIsDeletingUser(true);
			const response = await mutationDeleteUser({
				id,
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			setDeleteResponseMessage(response.message);
			createAuditLog({
				action: "When deleting a user",
				module: MODULE_NAME.USERS,
				option: "Delete",
				detail: `Deleted the user ${email}`,
				actionCode: "WEB_USRS_USR_DELETE",
				appType: "WEB_BACK_OFFICE",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			setIsUserListLoading(true);
			setIsDeletingUser(false);
		} catch (error) {
			setIsDeletingUser(false);
			setDeleteResponseMessage(
				"Failed to delete user because of network error.",
			);
			setShowDeleteUserError(true);
		}
	};

	const handleDeleteUser = async (id: string, email: string) => {
		setIsDeleteDialogOpen(true);
		setDeletedUserInfo({ email, id });
	};

	const handleResendInvitation = async (email: string, name: string) => {
		try {
			setShowSnackbar("Resending invitation...");
			setAlertSeverity("info");
			const response = await mutationResendInvitation({
				name,
				email,
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			if (response.sent) {
				setAlertSeverity("success");
				setShowSnackbar(response.message);
			} else {
				setAlertSeverity("error");
				setShowSnackbar(response.message);
			}
			createAuditLog({
				action: "When resending link",
				module: MODULE_NAME.USERS,
				option: "Resend verification link",
				detail: `Resend verification link for ${email}`,
				actionCode: "WEB_USRS_USR_RESEND_LINK",
				appType: "WEB_BACK_OFFICE",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
		} catch (error) {
			setAlertSeverity("error");
			setShowSnackbar("Failed to resend invitation due to network error.");
		}
	};

	useEffect(() => {
		if (openAuditLogModal && userInfo?._id !== undefined) {
			createAuditLog({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.USERS,
				action: "When entering",
				detail: `Viewed the audit log of the module ${MODULE_NAME.USERS}`,
				actionCode: "WEB_GEN_LIST_AUDITLOG_VIEW",
				option: "Audit log view",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
		}
	}, [openAuditLogModal, userInfo?._id]);

	const createSearchListAuditLog = () => {
		createAuditLog({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.USERS,
			action: "When searching",
			detail: "Searched within the list of users",
			actionCode: "WEB_USRS_LIST_SEARCH",
			option: "Users list",
			createdBy: userInfo?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		});
	};

	return (
		<div className="overflow-y-hidden">
			<div className="w-full overflow-y-hidden scroll-smooth">
				<div className="flex flex-col items-center justify-start min-h-screen space-y-5">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							width: "100%",
						}}
					>
						<Typography variant="h5" component="h1" fontWeight={700}>
							Users <InfoOutlinedIcon />
						</Typography>
					</Box>

					<Card sx={{ minWidth: 275, width: "100%" }}>
						<CardContent className="space-y-5">
							<Box
								display="flex"
								flexDirection={matchesMD ? "column" : "row"}
								gap="10px"
							>
								<div className="flex flex-1 bg-surfaceCardsmall rounded-lg px-4 py-3 max-w-full items-center justify-between h-14">
									<InputBase
										className="flex-1"
										placeholder="Search users"
										inputProps={{ "aria-label": "search" }}
										onChange={handleSearchChange}
									/>
									<SearchIcon className="text-icon-primary" />
								</div>

								<Tooltip title="Audit log">
									<IconButton
										size="large"
										sx={{
											borderRadius: "8px",
											border: `1px solid ${theme.palette.outline.dark}`,
											width: "3.5rem",
										}}
										onClick={() =>
											canSeeAuditLog
												? setOpenAuditLogModal(true)
												: setOpenAuditlogNoAccess(true)
										}
									>
										<HistoryOutlinedIcon className="text-icon-primary" />
									</IconButton>
								</Tooltip>

								<Button
									size={matchesMD ? "small" : "large"}
									onClick={() => navigate("/users/add")}
									color="primary"
									variant="contained"
									sx={{
										borderRadius: "8px",
										textTransform: "none",
										textAlign: "center",
										width: matchesXS ? "100%" : matchesMD ? "100%" : "auto",
									}}
								>
									ADD
								</Button>
							</Box>

							<Box
								sx={{
									display: "flex",
									gap: "10px",
									width: matchesMD ? "100%" : "50%",
									flexDirection: matchesMD ? "column" : "row",
								}}
							>
								<BasicSelect
									showClearOption
									handleChange={(value) => {
										const selectedProgram = programs?.find(
											(program) => program._id === value,
										);

										setProgramSelected(selectedProgram);

										createSearchListAuditLog();
									}}
									data={programsOptions || []}
									value={programSelected?._id || ""}
									label="Campaign"
									id="participant-program"
									isLoading={isLoadingPrograms}
								/>
								<BasicSelect
									showClearOption
									handleChange={(value) => {
										const selectedOU = organizationUnits?.find(
											(organizationUnit) => organizationUnit.id === value,
										);

										setOUSelected(selectedOU);

										createSearchListAuditLog();
									}}
									data={ouOptions || ""}
									value={oUSelected?.id || ""}
									label="Org unit (OU)"
									id="participant-org-unit"
									isLoading={isLoadingOrganizationUnits}
								/>
							</Box>
							<DataTable
								isLoading={isLoading}
								data={memoizedUserData}
								rowsPerPage={filters.pageSize}
								total={users?.total ?? 0}
								page={filters.pageNumber}
								headCells={[
									{
										id: "firstName",
										isSortable: true,
										disablePadding: true,
										label: "First name",
									},
									{
										id: "lastName",
										isSortable: true,
										disablePadding: false,
										label: "Last name",
									},
									{
										id: "status",
										isSortable: true,
										disablePadding: false,
										label: "Status",
									},
									{
										id: "role",
										isSortable: true,
										disablePadding: false,
										label: "Role",
									},
									{
										id: "action",
										isSortable: false,
										disablePadding: false,
										label: "Action",
									},
								]}
								showPagination
								onChangePage={(event, newPage) => {
									setFilters((filters) => ({
										...filters,
										pageNumber: newPage,
									}));
								}}
								onChangeSize={(event) => {
									setFilters((filters) => ({
										...filters,
										pageNumber: 0,
										pageSize: Number(event.target.value),
									}));
								}}
								order={filters.sortOrder === 1 ? "asc" : "desc"}
								orderBy={filters.sortField}
								onChangeSort={(order, orderBy) => {
									setFilters((filters) => ({
										...filters,
										pageNumber: 0,
										sortField: orderBy as string,
										sortOrder: order === "asc" ? 1 : -1,
									}));
								}}
							/>
						</CardContent>
					</Card>
				</div>
			</div>
			{openResponseDelete && (
				<GenericAlertModal
					title="Remove participant"
					description={"Are you sure you want to remove this user?"}
					cancelText="Cancel"
					submitText="Confirm"
					icon={<PersonRemove />}
					onCancel={() => setIsDeleteDialogOpen(false)}
					onClick={() => {
						deleteUser(deletedUserInfo.id, deletedUserInfo.email);
					}}
				/>
			)}
			{showDeleleteUserError && (
				<GenericAlertModal
					title="Error deleting user"
					description={deleteResponseMessage}
					submitText="Confirm"
					icon={<PriorityHigh />}
					onClick={() => {
						setShowDeleteUserError(false);
					}}
				/>
			)}
			<Snackbar
				open={Boolean(showSnackBar)}
				autoHideDuration={15000}
				onClose={() => setShowSnackbar("")}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={() => setShowSnackbar("")}
					severity={alertSeverity}
					variant="filled"
					sx={{ width: "100%" }}
				>
					{showSnackBar}
				</Alert>
			</Snackbar>

			{openAuditLogModal ? (
				<AuditLogModal
					open={openAuditLogModal}
					module={MODULE_NAME.USERS}
					onClose={() => setOpenAuditLogModal(false)}
				/>
			) : null}

			{openAuditlogNoAccess ? (
				<GenericAlertModal
					title="Access denied"
					description="Insufficient permissions to access this option."
					submitText="Accept"
					onClick={() => setOpenAuditlogNoAccess(false)}
				/>
			) : null}
		</div>
	);
};

export default UserList;
