import { Control, Controller } from 'react-hook-form';
import { IRole } from '../../domain/rol.interface';
import { Box, Grid, RadioGroup, Typography, FormControlLabel, Radio } from '@mui/material';
import { ICreateUserForm } from '../../domain/user.interface';

type Props = {
  control: Control<ICreateUserForm>;
  roles?: IRole[];
  currentRoleOnly?: IRole;
};

export const RolesForm = ({ roles, control, currentRoleOnly }: Props) => {
  const systemRoles = roles?.filter(rol => rol.type === "STANDARD_SYSTEM_ROLES")
  const customRoles = roles?.filter(rol => rol.type === "CUSTOM_ROLES")
  const accountRoles = roles?.filter(rol => rol.type === "STANDARD_ACCOUNT_ROLES")

  if (currentRoleOnly) {
    return (
      <Grid container spacing={2} width={["100%", "100%", "100%", "75%", "60%"]} sx={{ minHeight: "40vh" }}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <RadioGroup value={currentRoleOnly._id} >
              <FormControlLabel
                key={currentRoleOnly._id}
                value={currentRoleOnly._id}
                control={<Radio />}
                label={currentRoleOnly.name}
                sx={{ paddingLeft: "1rem" }}
                disabled
              />
            </RadioGroup>
            <Typography
              variant="bodyMedium"
            >
              * You cannot edit the current role
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Controller
      name="roleId"
      control={control}
      defaultValue=""
      render={({ field: { onChange, value, name } }) => (
        <RadioGroup
          onChange={onChange}
          value={value}
          name={name}
          aria-labelledby="demo-radio-buttons-group-label"
        >

          <Grid container spacing={2} width={["100%", "100%", "100%", "75%", "60%"]} sx={{ minHeight: "40vh" }}>
            {systemRoles && systemRoles?.length > 0 && (
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="titleMedium"
                    color="primary.dark"
                    fontWeight="bold"
                  >
                    Standard system roles
                  </Typography>
                  {getRows(systemRoles)}
                </Box>
              </Grid>
            )}
            {customRoles && customRoles?.length > 0 && (
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="titleMedium"
                    color="primary.dark"
                    fontWeight="bold"
                  >
                    Custom roles
                  </Typography>
                  {getRows(customRoles)}
                </Box>
              </Grid>
            )}
            {accountRoles && accountRoles?.length > 0 && (
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="titleMedium"
                    color="primary.dark"
                    fontWeight="bold"
                  >
                    Standard account roles
                  </Typography>
                  {getRows(accountRoles)}
                </Box>
              </Grid>
            )}
          </Grid>
        </RadioGroup>
      )}
    />
  );
}

function getRows(roles?: IRole[]) {
  return roles
    ?.map((rol) => (
      <FormControlLabel
        key={rol._id}
        value={rol._id}
        control={<Radio />}
        label={rol.name}
        sx={{ paddingLeft: "1rem" }}
      />
    ));
}