import { Box, Typography } from "@mui/material";
import Breadcrumbs from '../../components/Breadcrumbs';

const UserHeader = ({ header}: { header: string}) => {
    return (
        <Box sx={{ p: 0, mt: -.5 }}>
            <Breadcrumbs
                items={[
                    {
                        label: "Users list",
                        href: "/users",
                        isLink: true,
                    },
                    {
                        label: header.toUpperCase(),
                        isLink: false,
                    },
                ]}
            />
            <Typography variant="h5" sx={{ mt: 1, mb: 1, fontWeight: "700" }}>
                {header}
            </Typography>
        </Box>
    )
}
export default UserHeader;