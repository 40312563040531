import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import UserForm from "../../container/User/UserForm";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetUserById, useUpdateUser } from "../../hooks/useUser/useUser";
import { IUserPayload } from "../../domain/user.interface";
import UserHeader from "../../container/User/UserHeader";
import { UserSchema } from "@/container/User/useSchema";
import { useFeedBack } from "../../providers/FeedBackProvider/FeedBackContext";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { useSession } from "../../hooks/useSession";
import { useGetRolesByAccount, useGetRole } from "../../hooks/useRole";
import { MODULE_NAME } from "../../domain/auditlog.enum";

const User: FC = () => {
	const { t } = useTranslation(["common"]);
	const { id: userId } = useParams<{ id: string }>();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { data: currentUser } = useSession();
	const { showSnackBar } = useFeedBack();
	const createAuditlogMutation = useCreateAuditlog();
	const { data: roles, isLoading: isLoadingRoles } = useGetRolesByAccount(
		process.env.REACT_APP_ACCOUNT_ID as string,
		currentUser?._id,
	);

	queryClient.invalidateQueries({
		queryKey: ["get-user-by-id", userId],
	});

	
	const { data: user, isLoading } = useGetUserById(userId as string);

	const mutation = useUpdateUser({ id: userId as string });
	const { data: userRole, isLoading: isLoadingRole } = useGetRole(
		user?.roles[0] as string,
	);

	const currentRoleOnly =
		currentUser?._id === userId ||
		roles?.findIndex((role) => role._id === userRole?._id) === -1
			? userRole
			: undefined;

	const createAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!createAuditlogCalledRef.current && user && currentUser) {
			createAuditlogMutation.mutateAsync({
				action: "When entering to edit a user",
				module: MODULE_NAME.USERS,
				option: "User edit",
				detail: `Viewed the user ${user?.email}`,
				actionCode: "WEB_USRS_USR_VIEW",
				appType: "WEB_BACK_OFFICE",
				createdBy: currentUser?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			createAuditlogCalledRef.current = true;
		}
	}, [currentUser, user]);

	const onSubmit = async (data: UserSchema) => {
		if (!data.roleId) {
			showSnackBar("Please choose a use role first.", "warning");
			return;
		}
		try {
			const ogEmail = user?.email;
			const dataMapped: IUserPayload = {
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				status: data.status,
				phoneNumber: data.phoneNumber,
				npi: data.npi || "",
				programs: data.programs?.map((selOrg) => ({
					orgUnitId: (selOrg.orgUnitId as string[]) || [],
					programId: selOrg.programId as string,
				})),
				roles: [data.roleId],
			};
			await mutation.mutateAsync(dataMapped);

			createAuditlogMutation.mutateAsync({
				action: "When saving a user (editing)",
				module: MODULE_NAME.USERS,
				option: "User edit",
				detail: `Edited the user ${dataMapped?.email}`,
				actionCode: "WEB_USRS_USR_EDIT",
				appType: "WEB_BACK_OFFICE",
				createdBy: currentUser?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			queryClient.invalidateQueries({
				queryKey: ["get-user-list", "get-user-by-id"],
			});
			navigate("/users");
			if (ogEmail !== data.email) {
				showSnackBar(
					"Changing the email will put the user in Pending Verification status until it verifies its email",
					"success",
				);
			}
		} catch (error: any) {
			showSnackBar(error.message || error.toString());
		}
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					width: "100%",
					marginBottom: "2rem",
					// height: '100vh',
					overflow: "hidden",
				}}
			>
				<UserHeader header="Edit user" />
				{isLoading || isLoadingRole || isLoadingRoles ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						height="240px"
					>
						<CircularProgress />
					</Box>
				) : (
					<UserForm
						user={user as IUserPayload | undefined}
						isEditMode={true}
						onSubmit={onSubmit}
						saveDisabled={mutation.isPending}
						roles={roles}
						submitLabel="Save"
						currentRoleOnly={currentRoleOnly}
					/>
				)}
			</Box>
		</>
	);
};

export default User;
