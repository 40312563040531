import * as yup from "yup";

const createUserSchema = (isSysadminRole: boolean) => {
	return yup.object().shape({
		firstName: yup
			.string()
			.required("This field is required")
			.matches(/^[a-zA-Z0-9 ]+$/, "Must be alphanumeric")
			.min(2, "This field should have at least 2 characters")
			.max(50, "This field should have at most 50 characters"),
		lastName: yup
			.string()
			.required("This field is required")
			.matches(/^[a-zA-Z0-9 ]+$/, "Must be alphanumeric")
			.min(2, "This field should have at least 2 characters")
			.max(50, "This field should have at most 50 characters"),
		email: yup
			.string()
			.required("This field is required")
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				"The format is incorrect",
			)
			.max(50, "This field should have at most 100 characters"),
		phoneNumber: yup
			.string()
			.matches(/^\+?[0-9]{1,15}$/, "The format is incorrect")
			.transform((value, originalValue) => {
				// Transform empty strings to undefined to allow .optional() to work
				return originalValue.trim() === "" ? undefined : value;
			})
			.optional(),
		npi: yup.string().optional(),
		status: yup.string().required("This field is required"),
		roleId: yup.string().default(""),
		programs: yup.lazy(() => {
			if (isSysadminRole) {
				return yup.array().of(
					yup.object().shape({
						programId: yup.string().optional(),
						orgUnitId: yup.array().of(yup.string().optional()),
					}),
				);
			}

			return yup.array().of(
				yup.object().shape({
					programId: yup.string().required("This field is required"),
					orgUnitId: yup.array().of(yup.string().required("This field is required")).min(1, "This field is required"),
				}),
			).min(1, "You should select at least one organization");
		})
	});
};

export type UserSchema = yup.InferType<ReturnType<typeof createUserSchema>>;
export default createUserSchema;
