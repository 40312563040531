import { AppTheme } from "@/utils/theme";
import { SxProps } from "@mui/material/styles";

export const boxWrapper = {
	display: "flex",
	gap: "1.25rem",
	flexDirection: "column",
	justifyContent: "flex-start",
	width: "100%",
};

export const boxInner = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	width: "100%",
};

export const boxSmall = {
	display: "flex",
	gap: "1.25rem",
	width: "100%",
};

export const boxEnrollmentPeriods = {
	display: "flex",
	alignItems: "center",
	gap: "1.25rem",
	ml: "auto",
	width: "230px",
	marginTop: "0.25rem",
	height: "40px",
};

export const buttonReconfirm = (theme: AppTheme): SxProps => ({
	borderRadius: "8px",
	border: `1px solid ${theme.palette.outline.dark}`,
	color: theme.palette.primary.dark,
	fontWeight: 700,
	textTransform: "none",
	textAlign: "center",
});

export const auditLogIcon = (theme: AppTheme): SxProps => ({
	borderRadius: "8px",
	border: `1px solid ${theme.palette.outline.dark}`,
	width: "3rem",
	height: "3rem",
});

export const balanceBoxButtons = {
	display: "none",
	flexDirection: "column",
	gap: "0.5rem",
	justifyContent: "space-between",
	height: "100%",
	mt: "auto",
};
