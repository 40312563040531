import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BasicSelect from "../../BasicSelect/index";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useCreateAuditlog } from "../../../hooks/useAuditlog";
import reenrollValidationSchema from "./validation.schema";
import { useFeedBack } from "../../../providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "../../../domain/error.interface";
import { useEffect, useMemo, useState } from "react";
import { useSession } from "../../../hooks/useSession";
import { useAppTheme } from "../../../utils/theme";
import { EnrollmentReasons } from "../../../domain/participant.interface";
import useManageQueries from "../../../hooks/useManageQueries";
import {
	usePrograms,
	useOrganizationUnits,
	usePatchEnrollQueueParticipant,
} from "../../../hooks/useParticipant";
import CircularProgress from "@mui/material/CircularProgress";
import { MODULE_NAME } from "../../../domain/auditlog.enum";

const EnrollParticipantsDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: `${theme.spacing(2)} ${theme.spacing(2.5)} 0`,
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(2.5),
	},
}));

interface EnrollParticipantsModalProps {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	participantIds: string[];
}

interface FormData {
	organizationUnit: string;
	program: string;
}

export default function EnrollParticipantsModal({
	open,
	onClose,
	onSuccess,
	participantIds,
}: EnrollParticipantsModalProps) {
	const theme = useAppTheme();
	const { t } = useTranslation("common");
	const { data: userInfo } = useSession();
	const { showSnackBar } = useFeedBack();
	const { invalidateQueries } = useManageQueries();

	const createAuditlogMutation = useCreateAuditlog();
	const patchEnrollQueueParticipant = usePatchEnrollQueueParticipant({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const { isPending } = patchEnrollQueueParticipant;

	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [ouIdSelected, setOuIdSelected] = useState<string | undefined>();

	const { programs, isLoadingPrograms, refetchPrograms } = usePrograms({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [ouIdSelected || ""],
		isRegistering: true,
	});

	const { organizationUnits } = useOrganizationUnits({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [""],
		organizationLabels: "",
		isRegistering: true,
	});

	const ouOptions = useMemo(() => {
		if (!organizationUnits) {
			return [];
		}

		return organizationUnits.map((ou) => ({
			key: ou.id,
			value: ou.name,
		}));
	}, [organizationUnits]);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(reenrollValidationSchema(t)),
		mode: "all",
	});

	const onSubmit = async (data: FormData) => {
		createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.PARTICIPANTS,
			option: "Enroll queue",
			actionCode: "WEB_PART_ENROLL_FROM_QUEUE",
			action: "When enrolling participants from the queue",
			detail: "Enrolled one or more participants from the enroll queue",
			createdBy: userInfo?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			programId: data.program,
			orgUnitId: data.organizationUnit,
		});

		const enrollBatchData = {
			reason: EnrollmentReasons.ENROLLED_FROM_THE_QUEUE_LIST,
			orgUnitId: data.organizationUnit,
			programId: data.program,
			userId: userInfo?._id || "",
			participantIds,
		};

		try {
			await patchEnrollQueueParticipant.mutateAsync(enrollBatchData, {
				onSuccess: () => {
					invalidateQueries([
						{ queryKey: ["nalcam-queue-participants"] },
						{ queryKey: ["nalcam-queue-participants"] },
					]);

					showSnackBar("The participants have been enrolled", "success");
					onSuccess();
				},
			});
		} catch (error) {
			showSnackBar(
				(error as ErrorResponse).error?.errorMessage ||
					(error as ErrorResponse).message?.[0] ||
					"An error occurred",
				"error",
			);
		}
	};

	useEffect(() => {
		if (programs && !isLoadingPrograms) {
			setProgramsOptions(
				[
					...programs.map((program) => ({
						key: program._id,
						value: program.name,
					})),
				] || [],
			);
		}
	}, [programs, isLoadingPrograms]);

	useEffect(() => {
		refetchPrograms();
	}, [ouIdSelected]);

	return (
		<EnrollParticipantsDialog
			onClose={() => {
				if (isPending) {
					return;
				}

				onClose();
			}}
			aria-labelledby="register-beneficiary-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
					maxWidth: 380,
					minHeight: 420,
				},
			}}
			open={open}
			disableEscapeKeyDown={isPending}
		>
			<DialogTitle
				sx={{ 
					m: 0, 
					p: 2.5,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
				id="register-beneficiary-dialog-title"
				variant="headlineSmall"
				color={(theme) => theme.palette.surface.light}
				bgcolor={(theme) => theme.palette.primary.dark}
			>
				Enroll

				<IconButton
					aria-label="close"
					onClick={() => {
						if (isPending) {
							return;
						}
		
						onClose();
					}}
					sx={{
						color: (theme) => theme.palette.surface.light,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					gap: "1rem",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box sx={{ width: "100%", pt: "20px" }}>
					<Box sx={{ display: "flex", flexDirection: "column", px: 0 }}>
						<Typography
							variant="headlineSmall"
							sx={{
								color: "#7D7979",
								textTransform: "uppercase",
								fontSize: "1.25rem",
							}}
						>
							Enrolling {participantIds.length} participant(s)
						</Typography>
						<Typography
							variant="bodySmall"
							sx={{
								color: theme.palette.textMain.main2,
								textTransform: "uppercase",
								lineHeight: "1.5rem",
								fontWeight: 700,
							}}
						>
							Enrolled by: {userInfo?.firstName} {userInfo?.lastName}
						</Typography>
					</Box>
					<Box sx={{ py: 3, px: 0 }} className="space-y-4">
						<Stack direction="column" className="space-y-4">
							<Controller
								name="organizationUnit"
								control={control}
								defaultValue={undefined}
								render={({ field }) => (
									<BasicSelect
										{...field}
										data={ouOptions || []}
										value={field.value}
										required
										label="Org unit"
										id="participant-org-unit"
										handleChange={(value) => {
											const selectedOuId = value as string;
											field.onChange(selectedOuId);

											setOuIdSelected(selectedOuId);
										}}
										error={!!errors.organizationUnit}
										errorMessage={errors.organizationUnit?.message || ""}
										isLoading={isLoadingPrograms}
									/>
								)}
							/>
							<Controller
								name="program"
								control={control}
								defaultValue={undefined}
								render={({ field }) => (
									<BasicSelect
										{...field}
										data={programsOptions || []}
										value={field.value}
										label="Campaign"
										required
										id="participant-campaign"
										handleChange={(value) => {
											const selectedProgramId = value as string;
											field.onChange(selectedProgramId);
										}}
										error={!!errors.program}
										errorMessage={errors.program?.message || ""}
										isLoading={isLoadingPrograms || ouIdSelected === undefined}
									/>
								)}
							/>
						</Stack>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					borderTop: "1px solid #ccc",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "100%",
						gap: "0.5rem",
					}}
				>
					<Button
						size="large"
						onClick={onClose}
						variant="outlined"
						sx={{
							borderColor: theme.palette.outline.dark,
							color: "#261F1F",
							padding: "8px 16px",
						}}
						disabled={isPending}
					>
						CANCEL
					</Button>
					<Button
						size="large"
						onClick={handleSubmit(onSubmit)}
						color="primary"
						variant="contained"
						sx={{
							textTransform: "none",
							textAlign: "center",
							padding: "8px 16px",
						}}
						disabled={isPending}
						startIcon={isPending ? <CircularProgress size={16} /> : null}
					>
						ENROLL
					</Button>
				</Box>
			</DialogActions>
		</EnrollParticipantsDialog>
	);
}
