import { axios } from "../utils/axios.create";
import { MedicationProofData } from "@/domain/enrollment.interface";

const findMedicationProofList = async (
	enrollmentId: string,
	pageNumber: number,
	pageSize: number,
	sortField: string,
	sortOrder: number,
): Promise<{ total: number; data: MedicationProofData[] }> => {
	return axios
		.get(
			`enrollments/${enrollmentId}/medication-proof-results?pageNumber=${pageNumber}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

export const enrollmentService = {
	findMedicationProofList,
};
