import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "@mui/material";
import { useAppTheme } from "../../../utils/theme";
import Dialog from "@mui/material/Dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import BasicDatepicker from "../../../components/BasicDatepicker";
import dayjs from "dayjs";
import BasicSelect from "../../../components/BasicSelect";
import { usePutParticipantProfile } from "../../../hooks/useParticipant";
import { useFeedBack } from "../../../providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "../../../domain/error.interface";
import useManageQueries from "../../../hooks/useManageQueries";
import { useCreateAuditlog } from "../../../hooks/useAuditlog/index";
import { useSession } from "../../../hooks/useSession";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetAccountSettings } from "../../../hooks/useSetting/index";
import { ParticipantItem } from "@/domain/participant.interface";
import editValidationSchema from "./validation.schema";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from "../../../utils/a11yProps";
import TabPanel from "../../TabPanel";
import { statesUS } from "./statesUs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneNumberInput from "../../PhoneNumberInput";
import { MODULE_NAME } from "../../../domain/auditlog.enum";
import CloseIcon from "@mui/icons-material/Close";

interface EditParticipantModalProps {
	open: boolean;
	onClose: () => void;
	participant: ParticipantItem;
}

interface FormData {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	dateOfBirth?: string;
	clientIdNumber?: string;
	organizationUnit?: string;
	program?: string;
	status: string;
}

export default function EditParticipantModal({
	open,
	onClose,
	participant,
}: EditParticipantModalProps) {
	const { t } = useTranslation("common");
	const { data: userInfo } = useSession();
	const { invalidateQueries } = useManageQueries();
	const { showSnackBar } = useFeedBack();
	const theme = useAppTheme();
	const putParticipantProfile = usePutParticipantProfile({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const [selectedTab, setSelectedTab] = useState(0);
	const [generalTabError, setGeneralTabError] = useState(false);
	const [addressTabError, setAddressTabError] = useState(false);

	const { isPending } = putParticipantProfile;

	const { accountSettings, isLoadingAccountSettings, refetchAccountSettings } =
		useGetAccountSettings(`${process.env.REACT_APP_ACCOUNT_ID}`);
	const createAuditlog = useCreateAuditlog();

	const minAgeAllowed = useMemo(() => {
		if (!accountSettings) {
			return 18;
		}

		const minAgeSetting = accountSettings.find(
			(setting) => setting.name === "minAgeAllowed",
		);
		return parseInt((minAgeSetting?.default as string) || "18");
	}, [accountSettings, isLoadingAccountSettings]);

	const country = useMemo(() => {
		if (!accountSettings) {
			return "US";
		}

		const countrySetting = accountSettings.find(
			(setting) => setting.name === "country",
		);
		return (countrySetting?.default as string) || "US";
	}, [accountSettings, isLoadingAccountSettings]);

	const dateOfBirth = useMemo(() => {
		if (!accountSettings) {
			return "optional";
		}

		const dateOfBirthSetting = accountSettings.find(
			(setting) => setting.name === "dateOfBirth",
		);
		return (dateOfBirthSetting?.default as string) || "optional";
	}, [accountSettings, isLoadingAccountSettings]);

	const getCountryCode = useCallback(
		(code: string) => {
			switch (code) {
				case "US":
					return "+1";
				case "UK":
					return "+44";
				default:
					return "";
			}
		},
		[country],
	);

	const countryCode = getCountryCode(country);

	const {
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(
			editValidationSchema(t, minAgeAllowed, country, dateOfBirth),
		),
		mode: "all",
		defaultValues: {
			firstName: participant.user.firstName,
			lastName: participant.user.lastName,
			email: participant.user.email,
			phoneNumber: participant.user.phoneNumber.startsWith("+") ? participant.user.phoneNumber.substring(countryCode.length) : participant.user.phoneNumber,
			dateOfBirth: participant.participant.dateOfBirth || undefined,
			clientIdNumber: participant.participant.clientIdNumber,
			isArchived: participant.participant.isArchived,
			status: participant.lastEnrollment.status,
			addressLine1: participant.address?.addressLine1 || "",
			addressLine2: participant.address?.addressLine2 || "",
			locality: participant.address?.locality || "",
			city: participant.address?.city || "",
			state: participant.address?.state || "",
			zipCode: participant.address?.zipCode || "",
			postalCode: participant.address?.postalCode || "",
			orgUnitId: participant.program.orgUnitId[0],
			program: participant.program._id,
			reason: participant.participant.reasonToMove || "",
		},
	});

	const isArchivedChecked = watch("isArchived");

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openPopover = Boolean(anchorEl);
	const idPopover = openPopover ? "simple-popover" : undefined;

	const onSubmit = async (data: FormData) => {
		const phoneNoSpaces = data.phoneNumber;
		const countryWithCode = country === "US" ? "+1" : "+44";

		try {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When saving a participant (editing)",
				detail: `Edited the participant ${data.firstName} ${data.lastName}`,
				actionCode: "WEB_PART_PAR_EDIT",
				option: "Participant add / edit",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});

			await putParticipantProfile.mutateAsync(
				{
					...data,
					phoneNumber: phoneNoSpaces,
					countryPhoneCode: countryWithCode,
					dateOfBirth: data.dateOfBirth
						? dayjs(data.dateOfBirth).format("YYYY-MM-DD")
						: null,
					participantId: participant._id,
					enrollmentId: participant.lastEnrollment._id,
				},
				{
					onSuccess: () => {
						reset();
						onClose();
						invalidateQueries([
							{ queryKey: ["nalcam-enrolled-participants"] },
							{ queryKey: ["nalcam-queue-participants"] },
							{ queryKey: ["nalcam-participant-profile", participant._id] },
						]);
					},
				},
			);
		} catch (error) {
			showSnackBar((error as ErrorResponse).message?.[0] as string, "error");
		}
	};

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current) {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When entering to edit a participant",
				detail: `Viewed the participant ${participant.user.firstName} ${participant.user.lastName}`,
				actionCode: "WEB_PART_PAR_VIEW",
				option: "Participant add / edit",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			postAuditlogCalledRef.current = true;
		}
	}, []);

	const showArchived = useMemo(() => {
		const status =
			participant.lastEnrollment.status === "DISENROLLED" ||
			participant.lastEnrollment.status === "COMPLETED" ||
			participant.lastEnrollment.status === "CANCELLED";
		if (status && participant.participant.status === "ACTIVE") {
			return true;
		}

		return false;
	}, [participant]);

	const isStatusDisabled = useMemo(() => {
		const status =
			participant.lastEnrollment.status == "ACTIVE" ||
			participant.lastEnrollment.status === "PAUSED";
		if (status) {
			return false;
		}

		return true;
	}, [participant]);

	const showMoreStatusOptions = useMemo(() => {
		const status =
			participant.lastEnrollment.status == "ACTIVE" ||
			participant.lastEnrollment.status === "PAUSED";
		if (status) {
			return [];
		}

		return [
			{ key: "COMPLETED", value: "Completed" },
			{ key: "DISENROLLED", value: "Disenrolled" },
			{ key: "PENDING_ACTIVATION", value: "Pending activation" },
			{ key: "PENDING_TO_ENROLL", value: "Pending to enroll" },
		];
	}, [participant]);

	useEffect(() => {
		const hasGeneralError =
			!!errors.firstName ||
			!!errors.lastName ||
			!!errors.email ||
			!!errors.phoneNumber ||
			!!errors.dateOfBirth ||
			!!errors.clientIdNumber ||
			!!errors.status;
		setGeneralTabError(hasGeneralError);

		const hasAddressError =
			!!errors.addressLine1 ||
			!!errors.addressLine2 ||
			!!errors.locality ||
			!!errors.city ||
			!!errors.state ||
			!!errors.zipCode ||
			!!errors.postalCode;
		setAddressTabError(hasAddressError);
	}, [errors]);

	return (
		<React.Fragment>
			<Dialog
				onClose={() => {
					if (isPending) {
						return;
					}

					onClose();
				}}
				aria-labelledby="register-beneficiary-dialog-title"
				sx={{
					"& .MuiDialog-paper": {
						width: "100%",
						maxWidth: 760,
						minHeight: 640,
						maxHeight: 720,
					},
				}}
				open={open}
				disableEscapeKeyDown={isPending}
			>
				<DialogTitle
					sx={{ 
						m: 0, 
						p: 2.5,
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					id="register-beneficiary-dialog-title"
					variant="headlineSmall"
					color={(theme) => theme.palette.surface.light}
					bgcolor={(theme) => theme.palette.primary.dark}
				>
					Edit participant

					<IconButton
						aria-label="close"
						onClick={() => {
							if (isPending) {
								return;
							}
			
							onClose();
						}}
						sx={{
							color: (theme) => theme.palette.surface.light,
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{
						gap: "1.25rem",
						display: "flex",
						flexDirection: "column",
						padding: "1.25rem 1.25rem 0.75rem 1.25rem",
					}}
				>
					<Box sx={{ width: "100%" }}>
						<Box sx={{ padding: "20px 0 0" }}>
							<Tabs
								value={selectedTab}
								onChange={handleChange}
								aria-label="edit participant tabs"
								sx={{
									"& .MuiTabs-indicator": {
										backgroundColor:
											(selectedTab === 0 && generalTabError) ||
											(selectedTab === 1 && addressTabError)
												? theme.palette.error.main
												: "primary",
									},
								}}
							>
								<Tab
									label="General"
									{...a11yProps(0)}
									style={{
										color: generalTabError
											? theme.palette.error.main
											: "inherit",
									}}
								/>
								<Tab
									label="Address"
									{...a11yProps(1)}
									style={{
										color: addressTabError
											? theme.palette.error.main
											: "inherit",
									}}
								/>
							</Tabs>
						</Box>
						<TabPanel value={selectedTab} index={0}>
							<Grid container spacing={"20px"}>
								<Grid item xs={12} sm={6}>
									<Controller
										name="firstName"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="firstName"
												label="First name"
												variant="outlined"
												required
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.firstName}
												helperText={
													errors.firstName ? errors.firstName.message : null
												}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										name="lastName"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="lastName"
												label="Last name"
												variant="outlined"
												required
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.lastName}
												helperText={
													errors.lastName ? errors.lastName.message : null
												}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										name="email"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="email"
												label="Email"
												required
												variant="outlined"
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.email}
												helperText={errors.email ? errors.email.message : null}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										name="phoneNumber"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<PhoneNumberInput
												{...field}
												defaultCode={country}
												value={field.value}
												label="Phone number"
												variant="outlined"
												className="flex flex-1 w-full"
												onChange={(value) => field.onChange(value)}
												error={!!errors.phoneNumber}
												helperText={
													errors.phoneNumber ? errors.phoneNumber.message : null
												}
												key={isLoadingAccountSettings ? "loading" : "loaded"}
											/>
										)}
									/>
								</Grid>
								{dateOfBirth !== "hidden" ? (
									<Grid item xs={12} sm={6}>
										<Controller
											name="dateOfBirth"
											control={control}
											render={({ field }) => (
												<BasicDatepicker
													{...field}
													label="Date of birth (mm/dd/yyyy)"
													onChange={(value) => {
														field.onChange(
															value !== undefined ? value?.toDate() : undefined,
														);
													}}
													value={field.value ? dayjs(field.value) : undefined}
													error={!!errors.dateOfBirth}
													helperText={errors.dateOfBirth?.message || ""}
													required={dateOfBirth === "mandatory"}
												/>
											)}
										/>
									</Grid>
								) : null}
								<Grid item xs={12} sm={6}>
									<Controller
										name="clientIdNumber"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												fullWidth
												id="state"
												label="Client ID Number (CIN)"
												variant="outlined"
												className="flex flex-1"
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.clientIdNumber}
												helperText={
													errors.clientIdNumber
														? errors.clientIdNumber.message
														: null
												}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										name="status"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<BasicSelect
												handleChange={(value) => field.onChange(value)}
												data={[
													{ key: "ACTIVE", value: "Active" },
													{ key: "PAUSED", value: "Paused" },
													...showMoreStatusOptions,
												]}
												value={field.value}
												label="Status"
												id="participant-status"
												isLoading={isStatusDisabled}
											/>
										)}
									/>
								</Grid>
								{showArchived ? (
									<Grid item xs={12}>
										<Controller
											name="isArchived"
											control={control}
											defaultValue={isArchivedChecked}
											render={({ field }) => (
												<FormControlLabel
													key="isArchived"
													value={field.value}
													control={
														<Checkbox
															checked={isArchivedChecked}
															onChange={(event, checked) =>
																field.onChange(checked)
															}
														/>
													}
													label="Archived"
												/>
											)}
										/>
									</Grid>
								) : null}
							</Grid>
						</TabPanel>
						<TabPanel value={selectedTab} index={1}>
							<Grid container spacing={"20px"}>
								<Grid item xs={12}>
									<Controller
										name="addressLine1"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="addressLine1"
												label="Address line 1"
												variant="outlined"
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.addressLine1}
												helperText={
													errors.addressLine1
														? errors.addressLine1.message
														: null
												}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="addressLine2"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="addressLine2"
												label="Address line 2"
												variant="outlined"
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.addressLine2}
												helperText={
													errors.addressLine2
														? errors.addressLine2.message
														: null
												}
												fullWidth
											/>
										)}
									/>
								</Grid>
								{country === "UK" ? (
									<Grid item xs={12} sm={6}>
										<Controller
											name="locality"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="locality"
													label="Locality"
													variant="outlined"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.locality}
													helperText={
														errors.locality ? errors.locality.message : null
													}
													fullWidth
												/>
											)}
										/>
									</Grid>
								) : null}
								<Grid item xs={12} sm={6}>
									<Controller
										name="city"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<TextField
												{...field}
												id="city"
												label="City"
												variant="outlined"
												value={field.value}
												onChange={(value) => field.onChange(value)}
												error={!!errors.city}
												helperText={errors.city ? errors.city.message : null}
												fullWidth
											/>
										)}
									/>
								</Grid>
								{country === "US" ? (
									<>
										<Grid item xs={12} sm={6}>
											<Controller
												name="state"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<BasicSelect
														handleChange={(value) => field.onChange(value)}
														data={statesUS}
														value={field.value}
														label="State"
														id="state"
														error={!!errors.state}
														errorMessage={errors.state?.message || ""}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Controller
												name="zipCode"
												control={control}
												defaultValue=""
												render={({ field }) => (
													<TextField
														{...field}
														fullWidth
														id="zipCode"
														label="ZIP Code"
														variant="outlined"
														className="flex flex-1"
														value={field.value}
														onChange={(value) => field.onChange(value)}
														error={!!errors.zipCode}
														helperText={
															errors.zipCode ? errors.zipCode.message : null
														}
													/>
												)}
											/>
										</Grid>
									</>
								) : null}
								{country === "UK" ? (
									<Grid item xs={12} sm={6}>
										<Controller
											name="postalCode"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													{...field}
													id="postalCode"
													label="Postal code"
													variant="outlined"
													value={field.value}
													onChange={(value) => field.onChange(value)}
													error={!!errors.postalCode}
													helperText={
														errors.postalCode ? errors.postalCode.message : null
													}
													fullWidth
												/>
											)}
										/>
									</Grid>
								) : null}
							</Grid>
						</TabPanel>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						borderTop: "1px solid #ccc",
						p: "20px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							gap: "10px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: "0.625rem",
							}}
						>
							{selectedTab === 0 ? (
								<div className="hidden">
									<Button
										size="large"
										onClick={() => {}}
										variant="outlined"
										sx={{
											borderColor: theme.palette.outline.dark,
											color: "#261F1F",
											fontWeight: 700,
											padding: "8px 16px",
										}}
										disabled={isPending}
									>
										Move
									</Button>
									<IconButton
										aria-describedby={idPopover}
										onClick={handleClick}
										sx={{
											padding: "0px",
											width: "24px",
											height: "24px",
										}}
									>
										<InfoOutlinedIcon className="text-[#564C4C]" />
									</IconButton>
								</div>
							) : null}
							<Popover
								id={idPopover}
								open={openPopover}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								sx={{
									"& .MuiPopover-paper": {
										borderRadius: "0.5rem",
										backgroundColor: theme.palette.surface.dark,
										padding: "0.5rem 1.25rem",
										width: "250px",
									},
								}}
							>
								<Typography
									variant="bodyLarge"
									sx={{
										p: 0,
										color: theme.palette.textMain.main,
									}}
								>
									Moves participant to another [Name of type of the lowest OU
									level]
								</Typography>
							</Popover>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: "10px",
							}}
						>
							<Button
								size="large"
								onClick={onClose}
								variant="outlined"
								sx={{
									borderColor: theme.palette.outline.dark,
									color: "#261F1F",
									padding: "8px 16px",
								}}
								disabled={isPending}
							>
								CANCEL
							</Button>
							<Button
								size="large"
								onClick={handleSubmit(onSubmit)}
								color="primary"
								variant="contained"
								sx={{
									textTransform: "none",
									fontWeight: 700,
									textAlign: "center",
									padding: "8px 16px",
								}}
								disabled={isPending}
								startIcon={isPending ? <CircularProgress size={16} /> : null}
							>
								SAVE
							</Button>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
