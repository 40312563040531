import { Box, Card, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import { ChildOrganization } from "../../domain/organization.interface";
import { Program as IProgram } from "@/domain/program.interface";
import CircularProgress from "@mui/material/CircularProgress";
import OrganizationCheckboxList from "./OrganizationCheckboxList";
import {
	Controller,
	useWatch,
	useForm,
	useFieldArray,
	useFormContext,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { organizationService } from "../../services/organization.service";
import { IRole } from '../../domain/rol.interface';

const { findAll } = organizationService;

type Props = {
	roles?: IRole[];
	programsList: Array<IProgram> | undefined;
};

const OuForm: FC<Props> = (props: Props) => {
	const { control, watch } = useFormContext();
	const { programsList, roles } = props;

	const programs = watch("programs", []);
	const systemRoles = roles?.filter(rol => rol.type === "STANDARD_SYSTEM_ROLES")
	const roleId = watch("roleId", "");

	const defaultSelectedGroup = {
		programId: "",
		orgUnitId: [""],
	};

	const schema = yup.object().shape({
		selectedProgramId: yup.string().required("This field is required"),
	});

	const {
		control: filterControl,
		formState: { errors: filterErrors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			selectedProgramId: programs?.[0]?.programId || defaultSelectedGroup.programId,
		},
	});

	const selectedProgramId = useWatch({
		control: filterControl,
		name: "selectedProgramId",
	});

	const [childOrganizationsList, setChildOrganizationsList] = useState<
		Array<ChildOrganization>
	>([]);
	const [areChildOrganizationListLoading, setAreChildOrganizationListLoading] =
		useState(false);

	useEffect(() => {
		if (!selectedProgramId) {
			return;
		}
		setAreChildOrganizationListLoading(true);

		const organizationIds = programsList?.find(
			(program) => program._id === selectedProgramId,
		)?.orgUnitId;

		findAll({
			organizationIds
		})
			.then((organizations: Array<ChildOrganization>) => {
				setChildOrganizationsList(organizations);
			})
			.finally(() => {
				setAreChildOrganizationListLoading(false);
			});
	}, [selectedProgramId]);

	const { append, remove } = useFieldArray({
		control,
		name: "programs",
	});

	const [currentOrganizationIdsList, setCurrentOrganizationIdsList] = useState<
		string[]
	>([]); // group selected organizations ids

	useEffect(() => {
		setCurrentOrganizationIdsList(programs.find((program: any) => program.programId === selectedProgramId)?.orgUnitId || []);
	}, [selectedProgramId]);

	function addOrganizationId(selectedOrganizationId: string) {
		control._formState.isDirty = true;
		setCurrentOrganizationIdsList((prevOrganizationIdsList) => [
			...prevOrganizationIdsList,
			selectedOrganizationId,
		]);
	}

	function deleteOrganizationId(selectedOrganizationId: string) {
		control._formState.isDirty = true;
		const organizationIndex = currentOrganizationIdsList.findIndex(
			(organizationId) => organizationId === selectedOrganizationId,
		);
		if (organizationIndex === -1) return;

		setCurrentOrganizationIdsList((prevOrganizationIdsList) =>
			prevOrganizationIdsList.filter(
				(organizationId, index) => index !== organizationIndex,
			),
		);
	}

	useEffect(() => {
		if (control._formState?.isDirty) {
			const programOrganizationsGroupList = programs;
			if (!programOrganizationsGroupList) return;

			const currentProgramOrganizationsGroupIndex =
				programOrganizationsGroupList.findIndex(
					(programOrganizationsGroup: any) =>
						programOrganizationsGroup.programId === selectedProgramId,
				);

			if (currentProgramOrganizationsGroupIndex > -1) {
				remove(currentProgramOrganizationsGroupIndex);
			}

			if (programOrganizationsGroupList.length > 0) {
				const programOrganizationsGroupListLength =
					programOrganizationsGroupList.length;
				for (let i = 0; i < programOrganizationsGroupListLength; i++) {
					remove(i);
				}
			}

			append({
				programId: selectedProgramId,
				orgUnitId: currentOrganizationIdsList,
			});
		}
	}, [currentOrganizationIdsList]);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedOrganizationId = event.target.value;
		const isOrganizationChecked = event.target.checked;
		if (isOrganizationChecked) {
			addOrganizationId(selectedOrganizationId);
		} else {
			deleteOrganizationId(selectedOrganizationId);
		}
	};

	return (
		<>
			{systemRoles && systemRoles?.length > 0 && systemRoles.some(rol => rol._id === roleId) ? (
				<Box sx={{ width: "100%", minHeight: 400, p: 2 }}>
					<Typography
						variant="bodyLarge"
						sx={{
							color: "#7D7979",
						}}
					>
						* The users with account admin role have access to all programs and org units.
					</Typography>
				</Box>
			) : (
				<Box sx={{ width: "23rem", p: 1, m: 1 }}>
					<Box
						sx={{
							display: "flex",
							gap: "1rem",
							width: "100%",
							paddingTop: 1,
							marginTop: 1,
							paddingBottom: 1,
							marginBottom: 1,
						}}
					>
						<Controller
							name="selectedProgramId"
							control={filterControl}
							render={({ field: { onChange, value } }) => (
								<BasicSelect
									data={
										programsList?.map((program) => {
											return { key: program._id, value: program.name };
										}) || []
									}
									value={value}
									required={true}
									label="Campaign"
									id="program"
									multiple={false}
									handleChange={onChange}
									error={!!filterErrors.selectedProgramId}
									errorMessage={filterErrors.selectedProgramId?.message as string || ""}
								/>
							)}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: "1rem",
							paddingTop: 1,
							marginTop: 1,
							paddingBottom: 1,
							marginBottom: 1,
						}}
					>
						<Card
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								flexGrow: 1,
								padding: "1rem 2rem",
								alignItems: "flex-start",
							}}
						>
							{areChildOrganizationListLoading ? (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									height="240px"
									width="100%"
								>
									<CircularProgress />
								</Box>
							) : (
								<OrganizationCheckboxList
									programId={selectedProgramId}
									childOrganizationsList={childOrganizationsList} // available organizations
									currentOrganizationIdsList={currentOrganizationIdsList} // selected organizations
									handleCheckboxChange={handleCheckboxChange}
								/>
							)}
						</Card>
					</Box>
				</Box>
			)}
		</>
	);
};

export default OuForm;
