import React, { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
// import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";

const RoleList: FC = () => {
	const { t } = useTranslation(["common"]);
	const {isLoading: isAuthLoading, error, isAuthenticated, loginWithRedirect, user } = useAuth0();

	useEffect(() => {
		if (!isAuthenticated) {
			loginWithRedirect();
		}
	}, [isAuthenticated]);

	return (
		<>
			<div className="w-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-center min-h-screen">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
								marginBottom: "2rem",
							}}
						>
							<Typography variant="headlineLarge">
								Roles
							</Typography>
							<Typography variant="titleSmall">page</Typography>
						</Box>
					</div>
				</div>
			</div>
		</>
	);
};

export default RoleList;
