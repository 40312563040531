import { TFunction } from "i18next";
import * as yup from "yup";

const reenrollValidationSchema = (t: TFunction) => {
	return yup.lazy(() => {
		return yup.object().shape({
			program: yup
				.string()
				.required(t("validations.required")),
			organizationUnit: yup
				.string()
				.required(t("validations.required")),
		});
	});
};

export default reenrollValidationSchema;
