import React from "react";
import { ParticipantItem } from "../../domain/participant.interface";

type ModalContextType = {
	openModal: (participant?: ParticipantItem) => void;
	closeModal: () => void;
	showButton: () => void;
	hideButton: () => void;
};

export const InviteParticipantModalContext =
	React.createContext<ModalContextType | null>(null);

export const useInviteParticipantModal = () => {
	const context = React.useContext(InviteParticipantModalContext);
	if (!context) {
		throw new Error(
			"custom errror",
		);
	}
	return context;
};
