// CampaignFiltersContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Filters {
    campaignId: string;
    orgUnitsIds: string[];
    orgUnitLabelId: string;
    status: string[];
    search: string;
    pageNumber: number;
    pageSize: number;
    sortField: string;
    sortOrder: number;
    isArchived: boolean;
}

interface CampaignFiltersContextType {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    persistFilters: boolean;
    setPersistFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultFilters: Filters = {
    campaignId: "",
    orgUnitsIds: [""],
    orgUnitLabelId: "",
    status: ["ACTIVE", "COMPLETED"],
    search: "",
    pageNumber: 0,
    pageSize: 10,
    sortField: "user.fullName",
    sortOrder: 1,
    isArchived: false,
};

const CampaignFiltersContext = createContext<CampaignFiltersContextType | undefined>(undefined);

export const CampaignFiltersProvider = ({ children }: { children: ReactNode }) => {
    const [filters, setFilters] = useState<Filters>(defaultFilters);
    const [persistFilters, setPersistFilters] = useState<boolean>(false);

    return (
        <CampaignFiltersContext.Provider value={{ filters, setFilters, persistFilters, setPersistFilters }}>
            {children}
        </CampaignFiltersContext.Provider>
    );
};

export const useFilters = () => {
    const context = useContext(CampaignFiltersContext);
    if (!context) {
        throw new Error("useFilters must be used within a CampaignFiltersProvider");
    }
    return context;
};
