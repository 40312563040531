import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DataTableProps } from "./dataTable.interface";
import DataTableHead from "./DataTableHead";
import useTable from "./useTable";
import Skeleton from "@mui/material/Skeleton";
import { CSSProperties, useEffect } from "react";

export default function DataTable({
  data,
  rowsPerPage,
  order: initialOrder,
  orderBy: initialOrderBy,
  page: initialPage,
  headCells,
  minWidth = 750,
  isLoading,
  total,
  messageEmptyData = "No records to show",
  showPagination = true,
  onChangePage,
  onChangeSize,
  onChangeSort,
}: DataTableProps) {
  const {
    order,
    orderBy,
    selected,
    page,
    sortedData,
    handleRequestSort,
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable(data, initialOrder, initialOrderBy, initialPage);

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (onChangeSort) {
      onChangeSort(order, orderBy);
    }
  }, [order, orderBy]);  

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <TableContainer>
        <Table
          sx={{ minWidth: minWidth }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <DataTableHead
            numSelected={selected.length}
            order={order}
            orderBy={String(orderBy)}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={sortedData.length}
            headCells={headCells}
          />
          <TableBody>
            {isLoading ? (
              <TableRow hover role="checkbox" tabIndex={-1} key="skeleton-row">
                {headCells.map((key, index) => (
                  <TableCell key={`checkbox-table-cell-${index}`}>
                    <Skeleton variant="text" width={100} height={32} />
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              <>
                {sortedData.map((row: any, index: any) => {
                  const isItemSelected = isSelected(+row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (isLoading) {
                    return (
                      <TableRow
                      hover
                      onClick={(event) => handleClick(event, +row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`table-row-${row.id}-${index}`}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: (row.style as CSSProperties)
                          ?.backgroundColor,
                      }}
                    >
                      {Object.keys(row).map((key, i) => {
                        if (key !== "id" && key !== "style") {
                          const headCell = headCells.find(
                            (cell) => cell.id === key
                          );
                          let cellValue = row[key];
                          if (
                            cellValue &&
                            typeof cellValue === "object" &&
                            "node" in cellValue
                          ) {
                            cellValue = cellValue.node;
                          }
                          return (
                            <TableCell
                              key={`cell-${key}-${i}`}
                              component={i === 0 ? "th" : "td"}
                              id={i === 0 ? labelId : undefined}
                              scope={i === 0 ? "row" : undefined}
                              padding="normal"
                              align={
                                headCell?.id === "action"
                                  ? "right"
                                  : headCell?.align
                              }
                            >
                              {cellValue as React.ReactNode}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                    );
                  }

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, +row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`table-row-${row.id}-${index}`}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: (row.style as CSSProperties)?.backgroundColor,
                      }}
                    >
                      {Object.keys(row).map((key, i) => {
                        if (key !== "id" && key !== "style") {
                          const headCell = headCells.find((cell) => cell.id === key);
                          let cellValue = row[key];
                          if (cellValue && typeof cellValue === "object" && "node" in cellValue) {
                            cellValue = cellValue.node;
                          }
                          return (
                            <TableCell
                              key={`cell-${key}-${i}`}
                              component={i === 0 ? "th" : "td"}
                              id={i === 0 ? labelId : undefined}
                              scope={i === 0 ? "row" : undefined}
                              padding="normal"
                              align={
                                headCell?.id === "action"
                                  ? "right"
                                  : headCell?.align
                              }
                            >
                              {cellValue as React.ReactNode}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </>
            )}
            {!isLoading && sortedData.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={8}
                  sx={{
                    textAlign: "center",
                    padding: "1rem",
                  }}
                >
                  {messageEmptyData}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total || data.length}
          rowsPerPage={rowsPerPage}
          page={initialPage || page}
          onPageChange={onChangePage || handleChangePage}
          onRowsPerPageChange={onChangeSize || handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
