import { IAction } from "../domain/action.interface";
import { IRoleWithActions, IUserWithRoles, findByEmail } from "../services/users.service";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { MenuItem } from "./menu.store";
import { getIconOrDefault } from "../utils/menuConfig";

const actionToMenu = (action: IAction): MenuItem => {
	return {
		id: action._id,
		icon: getIconOrDefault(action.code),
		title: action.name,
		route: action.link,
		type:
			action.subActions && action.subActions?.length > 0 ? "parent" : "link",
		open: false,
		active: false,
		children: action.subActions?.map(actionToMenu),
	};
};
// Define the interface for the store's state
export interface UserStore {
	userInfo: IUserWithRoles | null;
	userHasAccess: (action: string) => boolean;
	userHasAccessToCode: (roleCode: string) => boolean;
	menus: MenuItem[];
	roles: IRoleWithActions[];
	getUserByEmail: (email: string) => Promise<IUserWithRoles>;
	clearUser: () => void;
	isLoading: boolean;
}

// Create the store
export const useUserStore = create<UserStore>()(
	persist(
		(set, get) => ({
			userInfo: null,
			menus: [],
			roles: [],
			isLoading: false,
			userHasAccess: (path: string) => {
				//everyone has access to unauthorized page or home
				if (["/unauthorized", "/"].includes(path?.toLowerCase())) return true;

				const user = get().userInfo;
				if (!user) return false;

				return user.roles.some((role) =>
					role.actions.some((a) => {
						const pathIsValid = path.split("/").length > 1;
						const pathSecondPart = path.split("/")[1].toLowerCase();

						const userHasAccess = pathIsValid
							? a.link.toLowerCase().includes(pathSecondPart)
							: false;
						const userHasChildAccess = a.subActions?.some((subAction) =>
							subAction.link.toLowerCase().includes(pathSecondPart),
						);
						return userHasAccess || userHasChildAccess;
					}),
				);
			},
			userHasAccessToCode: (roleCode: string) => {
				const user = get().userInfo;
				if (!user) return false;

				return user.roles.some((role) =>
					role.actions.some((a) => {
						const userHasAccess = a.code === roleCode;
						const userHasChildAccess = a.subActions?.some(
							(subAction) => subAction.code === roleCode,
						);
						return userHasAccess || userHasChildAccess;
					}),
				);
			},
			getUserByEmail: async (email: string) => {
				set({ isLoading: true });
				const user = await findByEmail(email);
				const menus = user.roles
					.flatMap((role) => role.actions)
					.filter((action) => action.type === "MENU_OPTION")
					.sort((a, b) => a.order - b.order)
					.map(actionToMenu);

				set({
					userInfo: user,
					menus: menus,
					roles: user.roles,
					isLoading: false,
				});
				return user;
			},
			clearUser: () => set({ userInfo: undefined, menus: [] }),
		}),
		{
			name: "UserStore",
		},
	),
);
