import React, { useState, useEffect, forwardRef } from 'react';
import { InputMask, MaskProps } from '@react-input/mask';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Flag from 'react-world-flags';
import InputAdornment from '@mui/material/InputAdornment';
import useDebounce from '../../hooks/useDebounce';

type InputProps = TextFieldProps &
  Pick<MaskProps, 'mask' | 'replacement'> & { defaultCode: string, loading?: boolean };

const PhoneNumberInput = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      loading = false,
      disabled = false,
      InputProps,
      defaultCode,
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const [formattedValue, setFormattedValue] = useState(value as string || '');
    const debouncedFormattedValue = useDebounce({
      value: formattedValue,
      delay: 300
    });

    const getMask = (code: string) => {
      switch (code) {
        case 'US':
          return '___ ___ ____';
        case 'UK':
          return '____ ______';
        default:
          return '';
      }
    };

    const getFlagCode = (code: string) => {
      switch (code) {
        case 'US':
          return 'US';
        case 'UK':
          return 'GB';
        default:
          return '';
      }
    };

    const mask = getMask(defaultCode);
    const flagCode = getFlagCode(defaultCode);

    const applyFormat = (val: string, code: string) => {
      let formatted = val.replace(/\D/g, ''); // Elimina cualquier carácter no numérico
    
      switch (code) {
        case 'US':
          if (formatted.length > 6) {
            formatted = formatted.replace(/(\d{3})(\d{3})(\d+)/, '$1 $2 $3');
          } else if (formatted.length > 3) {
            formatted = formatted.replace(/(\d{3})(\d+)/, '$1 $2');
          }
          break;
        case 'UK':
          if (formatted.length > 4) {
            formatted = formatted.replace(/(\d{4})(\d+)/, '$1 $2');
          }
          break;
        default:
          break;
      }
      
      return formatted;
    };

    useEffect(() => {
      setFormattedValue(applyFormat(value as string || '', defaultCode));
    }, [value, defaultCode]);

    useEffect(() => {
      if (debouncedFormattedValue !== value) {
        const cleanedValue = (debouncedFormattedValue as string).replace(/\s+/g, '');
        if (onChange) {
          onChange({ target: { value: cleanedValue } } as any);
        }
      }
    }, [debouncedFormattedValue, onChange, value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormattedValue(e.target.value);
    };

    useEffect(() => {
      console.log("formattedValue", formattedValue)
    }, [formattedValue])

    return (
      <TextField
        ref={ref}
        fullWidth
        autoComplete="off"
        value={formattedValue}
        onChange={handleInputChange}
        disabled={disabled || loading}
        InputProps={{
          ...InputProps,
          startAdornment: (
            <InputAdornment position="start" className="pr-3">
              <Flag code={flagCode} style={{ width: 24, height: 16 }} />
              <span className="pl-2">{defaultCode === 'US' ? '+1' : '+44'}</span>
            </InputAdornment>
          ),
          readOnly: InputProps?.readOnly || loading,
          inputComponent: InputMask,
          inputProps: { mask, replacement: { '_': /\d/ } }
        }}
        {...props}
      />
    );
  }
);

export default PhoneNumberInput;
