import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { useAppTheme } from "../../utils/theme";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface TotalNumberProps {
  label: string;
  count: ReactNode;
}

const TotalNumber: React.FC<TotalNumberProps> = ({ label, count }) => {
  const theme = useAppTheme();
  
  return (
    <Card 
      sx={{ 
       display: "flex",
       flexDirection: "row",
       alignItems: "flex-start",
       justifyContent: "space-between",
       flexGrow: 1, 
       maxWidth: "25%",
       height: "195px",
      }}
    >
      <CardContent 
        sx={{ 
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          ":last-child": {
            paddingBottom: "1rem"
          },
          gap: "1.25rem",
        }
      }>
        <Typography 
          variant="titleSmall" 
          color="#7D7979"
          fontWeight={700}
          height="3rem"
          maxWidth={200}
        >
          {label}
        </Typography>
        <Typography 
          variant="h3"
          textAlign="center"
          width="100%"
        >
          {count}
        </Typography>
      </CardContent>
    </Card>
  )
};

export default TotalNumber;