export const formatPhoneNumber = (phoneNumber: string, countryCode: string): string => {
	const cleanNumber = phoneNumber.replace(/\D/g, '');

	let formattedNumber: string;

	if (countryCode === 'US' || countryCode === 'GB' || countryCode === 'UK') {
		if (cleanNumber.startsWith('1')) {
			formattedNumber = `+${cleanNumber[0]} ${cleanNumber.slice(1, 4)} ${cleanNumber.slice(4, 7)} ${cleanNumber.slice(7)}`;
		} else if (cleanNumber.startsWith('44')) {
			formattedNumber = `+${cleanNumber.slice(0, 2)} ${cleanNumber.slice(2, 6)} ${cleanNumber.slice(6, 12)}`;
		} else if (countryCode === 'GB' || countryCode === 'UK') {
			formattedNumber = `+44 ${cleanNumber.slice(0, 4)} ${cleanNumber.slice(4, 10)}`;
		} else if (countryCode === 'US') {
			formattedNumber = `+1 ${cleanNumber.slice(0, 3)} ${cleanNumber.slice(3, 6)} ${cleanNumber.slice(6)}`;
		} else {
			throw new Error(`El número de teléfono no es válido para el país ${countryCode}`);
		}
	} else {
		if (countryCode === "+1") {
			formattedNumber = `${countryCode} ${cleanNumber.slice(0, 3)} ${cleanNumber.slice(3, 6)} ${cleanNumber.slice(6)}`;;
		} else if (countryCode === "+44") {
			formattedNumber = `${countryCode} ${cleanNumber.slice(0, 4)} ${cleanNumber.slice(4, 10)}`;
		} else {
			throw new Error(`Código de país no soportado: ${countryCode}`);
		}
	}

	return formattedNumber;
}