import * as React from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { DataTableCell, HeadCell, Order } from "./dataTable.interface";

interface DataTableHeadProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataTableCell) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: readonly HeadCell[];
}

export default function DataTableHead(props: DataTableHeadProps) {
  const { order, orderBy, onRequestSort, headCells } =
    props;
  const createSortHandler =
    (property: keyof DataTableCell) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: headCell.width,
              fontWeight: 900,
              textAlign: headCell.align
                ? headCell.align
                : headCell.isSortable
                  ? "left"
                  : "right",
            }}
          >
            {headCell.id === "action" ? (
              <Box className="font-black" component="span">
                {headCell.label}
              </Box>
            ) : (
              <TableSortLabel
                sx={{ fontWeight: 900 }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className="font-black"
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box className="font-black" component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}