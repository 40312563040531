import React from "react";
import { Box } from "@mui/material";
import { useAppTheme } from "../../utils/theme";

interface AvatarProps {
	firstName: string;
	lastName: string;
}

const Avatar: React.FC<AvatarProps> = ({ firstName, lastName }) => {
	const theme = useAppTheme();
	const initials = `${firstName[0]}${lastName[0]}`;

	return (
		<Box
			sx={{
				borderRadius: "50%",
				backgroundColor: "#D9D9D9",
				color: theme.palette.textMain.main2,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "1.5rem",
				fontWeight: "bold",
				height: "3.75rem",
				width: "3.75rem",
			}}
		>
			{initials.toUpperCase()}
		</Box>
	);
};

export default Avatar;
