import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import DataTable, { HeadCell, DataTableCell } from "../../components/DataTable";
import { headCellsConfig } from "../../utils/tableConfig";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { useAppTheme } from "../../utils/theme";
import Chip from "@mui/material/Chip";

import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { useSession } from "../../hooks/useSession";
import { useParticipant } from "../../hooks/useParticipant";
import useDebounce from "../../hooks/useDebounce";
import { useInviteParticipantModal } from "../../container/InviteParticipantModal/InviteParticipantModalContext";

import ActionMenu from "../../components/ActionMenu/ActionMenu";
import {
	ParticipantItem,
	ParticipantStatus,
} from "../../domain/participant.interface";
import dayjs from "dayjs";
import ListFilters, { Filters } from "./ListFilters"; // Asegúrate de ajustar la ruta de importación
import GenericAlertModal from "../../components/Modals/AlertModal";
import AuditLogModal from "../../components/Modals/AuditLogModal";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import DisenrollParticipantModal from "../../components/Modals/DisenrollParticipantModal";
import { getRegistrationStageText } from "../../utils/helperStatus";
import { toTitleCase } from "../../utils/toTitleCase";
import ReenrollParticipantModal from "../../components/Modals/ReenrollParticipantModal";
import EditParticipantModal from "../../components/Modals/EditParticipantModal";
import { MODULE_NAME } from "../../domain/auditlog.enum";
import { useFilters } from '../../providers/CampaignFiltersContext';

const WEB_GEN_LIST_AUDITLOG_VIEW = "WEB_GEN_LIST_AUDITLOG_VIEW";

// Delete when the call to the API is implemented
function createData(
	id: number,
	fullname: ReactNode,
	enrollmentDate: ReactNode,
	registrationStage: ReactNode,
	training: ReactNode,
	firstValidation: ReactNode,
	status: ReactNode,
	action: ReactNode,
): DataTableCell {
	return {
		id,
		fullname,
		enrollmentDate,
		registrationStage,
		training,
		firstValidation,
		status,
		action,
	};
}

const CampaignTab: FC = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { data: userInfo, userHasAccessToCode } = useSession();
	const theme = useAppTheme();
	const { openModal } = useInviteParticipantModal();
	const createAuditlog = useCreateAuditlog();

	const [showArchivedParticipants, setShowArchivedParticipants] =
		useState(false);
	const [showDisenrollModal, setShowDisenrollModal] = useState(false);

	const [selectedParticipant, setSelectedParticipant] =
		useState<ParticipantItem>();

	const [openGenericDialog, setOpenGenericDialog] = useState(false);
	const [openAuditlogNoAccess, setOpenAuditlogNoAccess] = useState(false);
	const [openAuditLogModal, setOpenAuditLogModal] = useState(false);
	const [openReenrollModal, setOpenReenrollModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);

	const { filters, setFilters, setPersistFilters } = useFilters();

	const [inputValue, setInputValue] = useState<string | undefined>(filters.search);
	const debouncedInputValue = useDebounce({ value: inputValue });

	const canSeeAuditLog = useMemo(() => {
		return userHasAccessToCode(WEB_GEN_LIST_AUDITLOG_VIEW);
	}, [userHasAccessToCode, userInfo]);

	const { data, isLoadingParticipants, refetchParticipants } = useParticipant({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		programId: filters.campaignId,
		orgUnitId: filters.orgUnitsIds,
		orgUnitLabelId: filters.orgUnitLabelId,
		status: filters.status,
		search: filters.search,
		pageNumber: filters.pageNumber,
		pageSize: filters.pageSize,
		sortField: filters.sortField,
		sortOrder: filters.sortOrder,
		isArchived: filters.isArchived,
	});

	const { participants, totalCount } = data || {};

	const actionMenu = [
		{
			value: "Invite participant",
			onClick: () => openModal(),
		},
		{
			value: "View audit log",
			onClick: () =>
				canSeeAuditLog
					? setOpenAuditLogModal(true)
					: setOpenAuditlogNoAccess(true),
		},
		{
			value: filters.isArchived ? "Hide archived" : "Show archived",
			onClick: () =>
				setFilters((filters) => ({
					...filters,
					isArchived: !filters.isArchived,
					pageNumber: 0,
				})),
		},
	];

	const handleLinkClick = () => {
    setPersistFilters(true);
  };

	const rows = useMemo(() => {
		if (participants && participants?.length === 0) {
			return [];
		}

		return (
			participants?.map((item, index) => {
				const status = item.lastEnrollment.status;
				const actionMenu =
					status === ParticipantStatus.DISENROLLED ||
					status === ParticipantStatus.COMPLETED
						? [
								{
									value: "View Profile",
									onClick: () =>
										navigate(
											`/participants/${item._id}?enrollmentId=${item.lastEnrollment._id}`,
										),
								},
								{
									value: "Edit",
									onClick: () => {
										setOpenEditModal(true);
										setSelectedParticipant(item);
									},
								},
								{
									value: "Enroll",
									onClick: () => {
										setSelectedParticipant(item);
										setOpenReenrollModal(true);
									},
								},
							]
						: [
								{
									value: "View Profile",
									onClick: () =>
										navigate(
											`/participants/${item._id}?enrollmentId=${item.lastEnrollment._id}`,
										),
								},
								{
									value: "Edit",
									onClick: () => {
										setOpenEditModal(true);
										setSelectedParticipant(item);
									},
								},
								{
									value: "Disenroll",
									onClick: () => {
										setSelectedParticipant(item);
										setShowDisenrollModal(true);
									},
								},
							];

				const row = createData(
					index,
					<Link
						to={{
							pathname: `/participants/${item._id}`,
							search: `?enrollmentId=${item.lastEnrollment._id}`,
						}}
						className="w-32 text-nowrap overflow-hidden overflow-ellipsis text-dark3 underline font-bold"
						onClick={handleLinkClick}
					>
						{`${item.user.firstName} ${item.user.lastName}`}
					</Link>,
					<div>
						{dayjs(item.lastEnrollment.startDate).format("MM/DD/YYYY hh:mm[h]")}
					</div>,
					<div>{getRegistrationStageText(item.participant.status)}</div>,
					<div>
						{item.lastEnrollment.hasCompletedTraining ? "Completed" : "None"}
					</div>,
					<div>
						{item.lastEnrollment.hasCompletedFirstTimeValidation ? "Completed" : "None"}
					</div>,
					<Chip
						sx={{
							bgcolor:
								item.lastEnrollment.status === "ACTIVE"
									? theme.palette.secondary.light
									: theme.palette.outline.dark,
						}}
						label={
							<div className="text-xs">
								{toTitleCase(item.lastEnrollment.status)}
							</div>
						}
					/>,
					<ActionMenu id={item._id} options={actionMenu} />,
				);

				return row;
			}) || []
		);
	}, [participants]);

	const headCells: HeadCell[] = headCellsConfig.participant;

	useEffect(() => {
		refetchParticipants();
	}, [filters]);

	useEffect(() => {
		if (debouncedInputValue !== undefined) {
			setFilters({
				...filters,
				search: debouncedInputValue,
			});

			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When searching",
				detail: "Searched within the list of participants in campaigns",
				actionCode: "WEB_PART_LIST_PROGRAM_SEARCH",
				option: "Participants list (campaigns tab)",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
		}
	}, [debouncedInputValue]);

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current && userInfo?._id !== undefined) {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When entering",
				detail: "Viewed the list of participants in campaigns",
				actionCode: "WEB_PART_LIST_PROGRAM_VIEW",
				option: "Participants list (campaigns tab)",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			postAuditlogCalledRef.current = true;
		}
	}, [userInfo]);

	useEffect(() => {
		if (openAuditLogModal && userInfo?._id !== undefined) {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.GENERAL,
				action: "When entering",
				detail: `Viewed the audit log of the module ${MODULE_NAME.GENERAL}`,
				actionCode: "WEB_GEN_LIST_AUDITLOG_VIEW",
				option: "Audit log view",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
		}
	}, [openAuditLogModal, userInfo?._id]);

	return (
		<>
			<Box sx={{ width: "100%" }} className="space-y-5">
				<Box sx={{ display: "flex", gap: "10px" }}>
					<div className="flex flex-1 bg-surfaceCardsmall rounded-lg px-4 py-3 max-w-full items-center justify-between h-14">
						<InputBase
							className="flex-1"
							placeholder="Search participant"
							inputProps={{ "aria-label": "search" }}
							onChange={(e) => {
								setInputValue(e.target.value);
							}}
							value={inputValue}
						/>
						<SearchIcon className="text-icon-primary" />
					</div>

					<Tooltip title="More actions">
						<ActionMenu id="more-actions" options={actionMenu} isSquare />
					</Tooltip>
				</Box>

				<ListFilters filtersState={[filters, setFilters]} />

				<DataTable
					data={rows}
					headCells={headCells}
					order={filters.sortOrder === 1 ? "asc" : "desc"}
					orderBy={filters.sortField}
					rowsPerPage={filters.pageSize}
					page={filters.pageNumber}
					total={totalCount}
					onChangePage={(event, newPage) => {
						setFilters((filters) => ({
							...filters,
							pageNumber: newPage,
						}));
					}}
					onChangeSize={(event) => {
						setFilters((filters) => ({
							...filters,
							pageNumber: 0,
							pageSize: Number(event.target.value),
						}));
					}}
					onChangeSort={(order, orderBy) => {
						setFilters((filters) => ({
							...filters,
							pageNumber: 0,
							sortField: orderBy as string,
							sortOrder: order === "asc" ? 1 : -1,
						}));
					}}
				/>
			</Box>

			{openGenericDialog ? (
				<GenericAlertModal
					title="Re-enrollment denied"
					description="Please redeem bank balance before re-enrolling user."
					submitText="Accept"
					onClick={() => setOpenGenericDialog(false)}
				/>
			) : null}

			{openAuditlogNoAccess ? (
				<GenericAlertModal
					title="Access denied"
					description="Insufficient permissions to access this option."
					submitText="Accept"
					onClick={() => setOpenAuditlogNoAccess(false)}
				/>
			) : null}

			{openAuditLogModal ? (
				<AuditLogModal
					open={openAuditLogModal}
					onClose={() => setOpenAuditLogModal(false)}
					module={MODULE_NAME.PARTICIPANTS}
				/>
			) : null}

			{openEditModal ? (
				<EditParticipantModal
					open={openEditModal}
					onClose={() => {
						setOpenEditModal(false);
						refetchParticipants();
					}}
					participant={selectedParticipant as ParticipantItem}
				/>
			) : null}

			{showDisenrollModal ? (
				<DisenrollParticipantModal
					open={showDisenrollModal}
					onClose={() => setShowDisenrollModal(false)}
					participant={selectedParticipant as ParticipantItem}
				/>
			) : null}

			{openReenrollModal ? (
				<ReenrollParticipantModal
					open={openReenrollModal}
					onClose={() => {
						setOpenReenrollModal(false);
						refetchParticipants();
					}}
					participant={selectedParticipant as ParticipantItem}
				/>
			) : null}
		</>
	);
};

export default CampaignTab;
