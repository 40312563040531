import { IRole } from "../domain/rol.interface";
import {
	IResendInvitation,
	IUser,
	IUserDelete,
	IUserPayload,
	IUserPayloadId,
	ParamsDelete,
	ParamsResendEmail,
} from "../domain/user.interface";
import { IAction } from "../domain/action.interface";
import { axios, transformAxiosError } from "../utils/axios.create";

export type IRoleWithActions = Omit<IRole, "actions"> & {
	actions: IAction[];
};

export type IUserWithRoles = Omit<IUser, "roles"> & {
	roles: IRoleWithActions[];
};

export const findByEmail = async (email: string): Promise<IUserWithRoles> => {
	return axios
		.get(`/users/email/${encodeURIComponent(email)}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};

export const findById = async (id: string): Promise<IUser> => {
	return axios
		.get(`/users/${id}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};

export const findByAccount = async (accountId: string): Promise<IUser[]> => {
	return axios
		.get(`/users/`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};

export const create = async (data: IUserPayload): Promise<IUser> => {
	return axios
		.post("/users/post", data)
		.then((res) => res.data.body)
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};

export const update = async (
	id: string,
	data: Partial<IUserPayloadId>,
): Promise<IUser> => {
	return axios
		.put(`/users/${id}`, data)
		.then((res) => res.data.body)
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};

export interface IUserListResponse {
	total: number;
	data: IUser[];
  }

export  interface GetUserListParams {
	searchText?: string;
	programId?: string;
	orgUnitId?: string;
	pageNumber?: number;
	pageSize?: number;
	accountId: string;
  }
  
  export const getUserList = async ({
	searchText,
	programId,
	orgUnitId,
	pageNumber,
	pageSize,
	accountId,
  }: GetUserListParams): Promise<IUserListResponse> => {
	const queryParams: any = {};
  
	if (searchText) queryParams.searchText = searchText;
	if (programId) queryParams.programId = programId;
	if (orgUnitId) queryParams.orgUnitId = orgUnitId;
	if (!pageNumber) queryParams.pageNumber = Number(0);
	if (pageNumber) queryParams.pageNumber = Number(pageNumber);
	if (pageSize) queryParams.pageSize = pageSize;
  
	const queryString = new URLSearchParams(queryParams).toString();
  
	return axios
	  .get(`/users/users-list?${queryString}`)
	  .then((res) => {
		return res.data.body;
	  })
	  .catch((err) => {
		return Promise.reject(err);
	  });
  };

export const deleteOne = async (params: ParamsDelete): Promise<IUserDelete> => {
	return axios
		.delete(`/users/${params.id}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};

export const resendInvitation = async (
	params: ParamsResendEmail,
): Promise<IResendInvitation> => {
	return axios
		.post(`/invitations/resend`,{
            email: params.email,
			name: params.name

        })
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};
