import axios from 'axios';

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_URL}/account/${process.env.REACT_APP_ACCOUNT_ID}`,
	headers: {
		"Content-Type": "application/json",
	}
});

const setBaseURL = (url: string): void => {
	instance.defaults.baseURL = url;
}

const setToken = (token: string): void => {
	instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const transformAxiosError = (error: Error) => {
	if (axios.isAxiosError(error)) {
		return new Error(error.response?.data?.error?.errorMessage || error.message);
	}
	return error;
};

export { instance as axios, setBaseURL, setToken, transformAxiosError };