import { ISettingResponse } from "../domain/setting.interface";
import { axios } from "../utils/axios.create";

const findAll = async (): Promise<ISettingResponse[]> => {
  return axios
    .get("/settings/account/applications/backoffice")
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

const findByAccount = async ({
  accountId,
  settingName = "",
} : {
  accountId: string;
  settingName?: string;
}): Promise<ISettingResponse[]> => {
  return axios
    .get(`/settings?settingName=${settingName}`)
    .then((res) => {
      return res.data.body.settings;      ;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
}

export const settingService = {
  findAll,
  findByAccount
};
