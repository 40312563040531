import dayjs from "dayjs";
import { TFunction } from "i18next";
import * as yup from "yup";

const inviteValidationSchema = (
	t: TFunction,
	minAgeAllowed: number,
	country: string,
	dateOfBirth: string
) => {
	return yup.lazy(() => {
		const dateOfBirthValidation = (): yup.StringSchema<string | undefined> => {
      return dateOfBirth === "mandatory"
        ? yup
					.string()
					.required(t("validations.required"))
          .test(
            "futureDate",
            "Date of birth cannot be in the future",
            (value) => {
              if (!value) return true;
              return dayjs(value).isBefore(dayjs());
            }
          )
					.test(
						"isOldEnough",
						t("validations.validator_birth_date", { minAgeAllowed }),
						(value) => {
							if (!value) return true;
							const birthdate = dayjs(value);
							const age = dayjs().diff(birthdate, "year");
							return age >= minAgeAllowed;
						}
					)
        : yup
					.string()
          .optional()
					.test(
            "futureDate",
            "Date of birth cannot be in the future",
            (value) => {
              if (!value) return true;
              return dayjs(value).isBefore(dayjs());
            }
          )
					.test(
						"isOldEnough",
						t("validations.validator_birth_date", { minAgeAllowed }),
						(value) => {
							if (!value) return true;
							const birthdate = dayjs(value);
							const age = dayjs().diff(birthdate, "year");
							return age >= minAgeAllowed;
						}
					);
    };

		const localeValidation = (): yup.StringSchema => {
			switch (country) {
				case "US":
					return yup
						.string()
						.test(
              "min",
              t("validations.validator_digits_min", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 10;
              }
            )
            .test(
              "max",
              t("validations.validator_digits_max", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 10;
              }
            )
						.test("phoneNumber", t("validations.invalid_format"), (value) => {
              if (!value || value.trim() === "") return true;
              const phoneRegex = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
              return phoneRegex.test(value);
            });
				case "UK":
					return yup
						.string()
						.test(
              "min",
              t("validations.validator_digits_min", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 10;
              }
            )
            .test(
              "max",
              t("validations.validator_digits_max", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 10;
              }
            )
						.test("phoneNumber", t("validations.invalid_format"), (value) => {
              if (!value || value.trim() === "") return true;
              const phoneRegex = /^\(?\d{4}\)?[-. ]?\d{6}$/;
              return phoneRegex.test(value);
            });
				default:
					return yup.string();
			}
		};

		return yup.object().shape({
			firstName: yup
				.string()
				.required(t("validations.required"))
				.min(2, t("validations.min_length", { count: 2 }))
				.max(50, t("validations.max_length", { count: 50 })),
			lastName: yup
				.string()
				.required(t("validations.required"))
				.min(2, t("validations.min_length", { count: 2 }))
				.max(50, t("validations.max_length", { count: 50 })),
			dateOfBirth: dateOfBirthValidation(),
			email: yup
				.string()
				.required(t("validations.required"))
				.email(t("validations.invalid_format")),
			phoneNumber: localeValidation(),
			clientIDNumber: yup
				.string()
				.optional(),
			program: yup
				.string()
				.optional(),
			organizationUnit: yup
				.string()
				.required(t("validations.required")),
		});
	});
};

export default inviteValidationSchema;
