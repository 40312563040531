import { useUserStore } from "../../stores/user.store";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { Button, Stack, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSession } from "../../hooks/useSession";
import { useAppTheme } from "../../utils/theme";
import { MODULE_NAME } from "../../domain/auditlog.enum";

const AccessDenied: FC = () => {
	const { logout } = useAuth0();
	const createAuditlogMutation = useCreateAuditlog();
	const { data } = useSession();
	const { userInfo, clearUser } = useUserStore();

	const theme = useAppTheme();

	const postAuditlog = async ({
		action,
		detail,
		actionCode,
	}: {
		action: string;
		detail: string;
		actionCode: string;
	}) => {
		await createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.GENERAL,
			action,
			detail,
			actionCode,
			option: "",
			createdBy: data?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		});
	};

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current) {
			postAuditlog({
				action: "Inactive or not existing account",
				detail:
					"An inactive account or a not existing one(only feasible with SSO) accessed NALCAM",
				actionCode: "WEB_INVALID_ACCOUNT",
			});
			postAuditlogCalledRef.current = true;
		}
	}, []);

	const logoutWithRedirect = () => {
		clearUser();
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	};

	return (
		<div className="flex flex-col items-center justify-center w-full h-screen bg-surfaceCardsmall py-6">
			<img
				className="w-[70px] h-[70px] my-6"
				alt="NALCAM Logo"
				src="/assets/LogoBackground_512x512.png"
			/>
			<div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg mt-6 w-11/12 sm:w-2/3 sm:w-2/5 border border-main">
				<img
					className="w-[269.1px] object-cover"
					alt=""
					src="/assets/access-denied.png"
				/>
				<Typography variant="h4" align="center" className="mt-4">
					Invalid Access
				</Typography>
				<Typography variant="body1" align="center" className="p-6">
					Your user does not have the correct access configuration. If you
					require assistance, please contact support.
				</Typography>
				<div className="mb-4">
					<Button variant="contained" onClick={logoutWithRedirect}>
						BACK TO SIGN IN
					</Button>
				</div>
			</div>
			<footer className=" bottom-4 text-sm text-secondary text-center my-4 bg-surfaceCardsmall">
				Powered by{" "}
				<a href="https://cmi.com" className="text-surfaceMenu underline">
					CMI
				</a>
				<br />
				NALCAM © {new Date().getFullYear()}
			</footer>
		</div>
	);
};

export default AccessDenied;
