import { FC, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import { usePrograms, useOrganizationUnits, useOrganizationLalbels } from "../../hooks/useParticipant";
import { Program } from '../../domain/program.interface';

export type Filters = {
  campaignId: string;
  orgUnitsIds: string[];
  orgUnitLabelId: string;
  status: string[];
  search: string;
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortOrder: number;
  isArchived: boolean;
};

type Props = {
  filtersState: [Filters, React.Dispatch<React.SetStateAction<Filters>>];
};

const ListFilters: FC<Props> = ({ filtersState }) => {
  const [filters, setFilters] = filtersState;
  const [programsOptions, setProgramsOptions] = useState<Array<{ key: string; value: string }>>([]);
  const [programSelected, setProgramSelected] = useState<Program | undefined>();

  const {
    programs,
    isLoadingPrograms,
    refetchPrograms,
  } = usePrograms({
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    organizationIds: [""],
  });

  const programOUIds = useMemo(() => {
    if (programSelected) {
      return programSelected.orgUnitId || [];
    }
    return [];
  }, [programSelected]);

  const {
    organizationUnits,
    isLoadingOrganizationUnits,
    refetchOrganizationUnits,
  } = useOrganizationUnits({
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    organizationIds: programOUIds,
    organizationLabels: "",
  });

  const uniqueLabels = useMemo(() => {
    if (!organizationUnits) {
      return [];
    }
    
    const labelSet = organizationUnits.reduce((acc, unit) => {
      unit.orgUnitLabelId.forEach(label => acc.add(label));
      return acc;
    }, new Set<string>());

    return Array.from(labelSet);
  }, [organizationUnits]);

  const {
    organizationLabels,
    isLoadingOrganizationLabels,
    refetchOrganizationLabels,
  } = useOrganizationLalbels({
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    organizationLabelIds: uniqueLabels,
  });

  useEffect(() => {
    if (programs && !isLoadingPrograms) {
      setProgramsOptions(
        [
          ...programs.map((program) => ({
            key: program._id,
            value: program.name,
          })),
        ] || [],
      );

      if (!filters.campaignId || filters.campaignId === "") {
        setFilters((filters) => ({ ...filters, campaignId: programs[0]._id }));
        setProgramSelected(programs[0]);
      } else {
        const selectedProgram = programs.find(
          (program) => program._id === filters.campaignId
        );
        setProgramSelected(selectedProgram);
      }
    }
  }, [programs, isLoadingPrograms]);

  const ouOptions = useMemo(() => {
    if (!organizationUnits) {
      return [];
    }

    return organizationUnits.map((ou) => ({
      key: ou.id,
      value: ou.name,
    }));
  }, [organizationUnits]);

  useEffect(() => {
    refetchOrganizationUnits();
  }, [programSelected]);

  useEffect(() => {
    refetchOrganizationLabels();
  }, [organizationUnits]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        width: "100%",
      }}
    >
      <BasicSelect
        handleChange={(value) => {
          setFilters((filters) => ({
            ...filters,
            campaignId: value as string,
            orgUnitsIds: [""],
            orgUnitLabelId: "",
            pageNumber: 0,
          }));

          const selectedProgram = programs?.find(
            (program) => program._id === value,
          );

          setProgramSelected(selectedProgram);
        }}
        data={programsOptions || []}
        value={filters.campaignId}
        label="Campaign"
        id="participant-program"
        isLoading={isLoadingPrograms}
        maxWidth={300}
      />

      <BasicSelect
        handleChange={(value) => {
          setFilters((filters) => ({
            ...filters,
            orgUnitsIds: value as string[],
            orgUnitLabelId: "",
            pageNumber: 0,
          }));
        }}
        data={ouOptions || []}
        value={filters.orgUnitsIds[0] === "" ? [] : filters.orgUnitsIds}
        label="Org unit(s)"
        multiple
        id="participant-org-units"
        isLoading={isLoadingOrganizationUnits}
        maxWidth={300}
      />

      <BasicSelect
        handleChange={(value) => {
          setFilters((filters) => ({
            ...filters,
            orgUnitLabelId: value as string,
            orgUnitsIds: [""],
            pageNumber: 0,
          }));
        }}
        showClearOption
        data={organizationLabels || []}
        value={filters.orgUnitLabelId}
        label="Org unit label"
        id="participant-org-unit-label"
        isLoading={isLoadingOrganizationLabels}
        maxWidth={300}
      />

      <BasicSelect
        handleChange={(value) => {
          setFilters((filters) => ({
            ...filters,
            status: value as string[],
            pageNumber: 0,
          }));
        }}
        data={[
          { key: 'ACTIVE', value: "Active" },
          { key: 'PAUSED', value: "Paused" },
          { key: 'COMPLETED', value: "Completed" },
          { key: 'DISENROLLED', value: "Disenrolled" },
          { key: 'CANCELED', value: "Canceled" }
        ]}
        value={filters.status[0] === "" ? [] : filters.status}
        label="Status"
        id="participant-status"
        multiple
        maxWidth={300}
        isLoading={filters.isArchived}
      />
    </Box>
  );
};

export default ListFilters;
