import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import BasicDatepicker from "../../BasicDatepicker";
import dayjs, { Dayjs } from "dayjs";
import { headCellsConfig } from "../../../utils/tableConfig";
import DataTable, { DataTableCell, HeadCell } from "../../DataTable";
import { ReactNode, useEffect, useMemo } from "react";
import { useGetAuditlog } from "../../../hooks/useAuditlog";
import { useGetSetting } from "../../../hooks/useSetting";
import GenericAlertModal from "../AlertModal";
import { MODULE_NAME } from "../../../domain/auditlog.enum";
import { useGetAccountSettings } from '../../../hooks/useSetting/index';
import useManageQueries from '../../../hooks/useManageQueries';

const AuditLogDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));
const nameSettingMinusDays = "daysAuditlog";
const createData = (
	id: number,
	timestamp: ReactNode,
	user: ReactNode,
	eventDescription: ReactNode,
): DataTableCell => {
	return {
		id,
		timestamp,
		user,
		eventDescription,
	};
};

interface AuditLogModalProps {
	open: boolean;
	module: MODULE_NAME;
	onClose: () => void;
}

export default function AuditLogModal({
	open,
	module,
	onClose,
}: AuditLogModalProps) {
	const { data: dataSetting } = useGetSetting();
	const { removeQueries } = useManageQueries();
	const invalidMessage =
		"Invalid date range selected. Please select a valid range.";
	const [openInvalidRange, setOpenInvalidRange] = React.useState(false);
	const defaultMinusDays = Number(
		dataSetting?.find((setting: any) => setting.name === nameSettingMinusDays)
			?.default ?? 1,
	);
	const [dateRange, setDateRange] = React.useState({
		from: dayjs().subtract(defaultMinusDays, "day").startOf("day"),
		to: dayjs().endOf("day"),
	});
	const [lastDocId, setLastDocId] = React.useState<string | undefined>(
		undefined,
	);
	const [pageNumber, setPageNumber] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [dateRangetoInvalid, setDateRangetoInvalid] = React.useState(false);

	const { accountSettings, isLoadingAccountSettings, refetchAccountSettings } =
		useGetAccountSettings(`${process.env.REACT_APP_ACCOUNT_ID}`, "country");

	const country = useMemo(() => {
		if (!accountSettings) {
			return "US";
		}

		const countrySetting = accountSettings.find(
			(setting) => setting.name === "country",
		);
		return (countrySetting?.default as string) || "US";
	}, [accountSettings, isLoadingAccountSettings]);

	const {
		data: dataAudit,
		isLoading,
		refetch: refecthGetAuditLogs,
	} = useGetAuditlog(
		module,
		dateRange.from.toISOString(),
		dateRange.to.toISOString(),
		pageNumber + 1,
		pageSize,
		lastDocId,
	);
	const prevLastDocIdRef = React.useRef(lastDocId);
	const prevPageNumberRef = React.useRef(pageNumber);

	useEffect(() => {
		refecthGetAuditLogs();

		prevPageNumberRef.current = pageNumber;
		prevLastDocIdRef.current = lastDocId;
	}, [pageNumber, pageSize, dateRange.from, dateRange.to]);

	React.useEffect(() => {
		if (dataAudit) {
			if (pageNumber > prevPageNumberRef.current) {
				setLastDocId(dataAudit.lastDocId);
			} else if (pageNumber < prevPageNumberRef.current) {
				setLastDocId(prevLastDocIdRef.current);
			}
		}
	}, [dataAudit]);
	const headCells: HeadCell[] = headCellsConfig.beneficiaryAuditLog;

	const rows = useMemo(() => {
		if (!dataAudit) {
			return [];
		}

		return dataAudit?.data.map((row: any, index: any) => {
			const timestampFormat =
					country === "US"
						? "MM/DD/YYYY HH:mm:ss"
						: "DD/MM/YYYY HH:mm:ss";

			return createData(
				index,
				<div style={{ minWidth: "150px" }}>
					{dayjs(row.timestamp).format(timestampFormat)}
				</div>,
				<div style={{ minWidth: "200px" }}>{row.user}</div>,
				row.eventDescription,
			);
		})
	}, [dataAudit]);

	const onRefresh = () => {
		setDateRange({
			from: dayjs().subtract(defaultMinusDays, "day").startOf("day"),
			to: dayjs().endOf("day"),
		});
	};

	return (
		<>
			<React.Fragment>
				<AuditLogDialog
					onClose={onClose}
					aria-labelledby="register-beneficiary-dialog-title"
					sx={{
						"& .MuiDialog-paper": {
							width: "100%",
							maxWidth: 920,
							borderRadius: "8px",
						},
					}}
					open={open}
				>
					<DialogTitle
						sx={{ m: 0, p: 2 }}
						id="register-beneficiary-dialog-title"
						variant="headlineSmall"
						color={(theme) => theme.palette.surface.light}
						bgcolor={(theme) => theme.palette.primary.dark}
					>
						AuditLog
					</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.surface.light,
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent
						sx={{
							gap: "1rem",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: "1rem",
							}}
						>
							<Box sx={{ display: "flex", gap: 1 }}>
								<BasicDatepicker
									label="Date from"
									onChange={(value: any) => {
										setDateRange((prev) => ({
											...prev,
											from: value?.startOf("day") as Dayjs,
										}));

										setPageNumber(0);
									}}
									value={dateRange.from}
									sx={{ maxWidth: 220 }}
									disableWrite={true}
								/>
								<BasicDatepicker
									label="Date to"
									onChange={(value: any) => {
										if (value.endOf("day").diff(dateRange.from.startOf("day")) > 0) {
											setDateRange((prev) => ({
												...prev,
												to: value?.endOf("day") as Dayjs,
											}));

											setPageNumber(0);

											setDateRangetoInvalid(false);
										} else {
											setDateRangetoInvalid(true);
											setOpenInvalidRange(true);
										}
									}}
									value={dateRange.to}
									error={dateRangetoInvalid}
									sx={{ maxWidth: 220 }}
									minDate={dateRange.from}
									disableWrite={true}
								/>
								<Button
									variant="contained"
									color="primary"
									onClick={onRefresh}
									sx={{
										borderRadius: "8px",
										width: 120,
									}}
								>
									Refresh
								</Button>
							</Box>

							<DataTable
								data={rows as DataTableCell[]}
								rowsPerPage={pageSize}
								headCells={headCells}
								order="asc"
								orderBy="none"
								page={pageNumber}
								total={dataAudit?.total}
								isLoading={isLoading}
								messageEmptyData="No events found for the selected date range."
								onChangePage={(event, newPage) => {
									setPageNumber(newPage);
								}}
								onChangeSize={(event) => {
									setPageSize(Number(event.target.value));
									setPageNumber(0);
								}}
								onChangeSort={(order, orderBy) => {}}
							/>
						</Box>
					</DialogContent>
				</AuditLogDialog>
			</React.Fragment>
			{openInvalidRange && (
				<GenericAlertModal
					title="Warning Selection"
					description={invalidMessage}
					submitText="Accept"
					onClick={() => {
						setOpenInvalidRange(false);
					}}
				/>
			)}
		</>
	);
}
