import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "../../components/Breadcrumbs";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import {
	boxWrapper,
	boxInner,
	boxSmall,
	boxEnrollmentPeriods,
} from "./profile.styles";
import { useParams, useLocation } from "react-router-dom";
import {
	useGetParticipantEnrollmentPeriods,
	useParticipantProfile,
} from "../../hooks/useParticipant";
import { capitalize } from "@mui/material/utils";
import ActionMenu from "../../components/ActionMenu/ActionMenu";
import {
	ParticipantItem,
	ParticipantStatus,
} from "../../domain/participant.interface";
import { useAppTheme } from "../../utils/theme";
import DisenrollParticipantModal from "../../components/Modals/DisenrollParticipantModal";
import Card from "@mui/material/Card";
import ProfileInformation from "./ProfileInformation";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import Skeleton from "@mui/material/Skeleton";
import ReenrollParticipantModal from "../../components/Modals/ReenrollParticipantModal";
import EditParticipantModal from "../../components/Modals/EditParticipantModal/index";
import ProfileCardBalance from "./ProfileCardBalance";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from "../../utils/a11yProps";
import BasicSelect from "../../components/BasicSelect";
import TabPanel from "../../components/TabPanel";
import ProfileResults from "./ProfileResults";
import useManageQueries from "../../hooks/useManageQueries";
import { MODULE_NAME } from "../../domain/auditlog.enum";
import { useSession } from "../../hooks/useSession";

const ParticipantProfile: FC = () => {
	const { participantId } = useParams();
	const { data: userInfo } = useSession();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const enrollmentId = queryParams.get("enrollmentId");
	const { invalidateQueries } = useManageQueries();

	const createAuditlog = useCreateAuditlog();

	const theme = useAppTheme();

	const [openEditModal, setOpenEditModal] = useState(false);
	const [openDisenrollModal, setOpenDisenrollModal] = useState(false);
	const [openReenrollModal, setOpenReenrollModal] = useState(false);
	const [openAuditLogModal, setOpenAuditLogModal] = useState(false);

	const [selectedTab, setSelectedTab] = useState(0);

	const [selectedEnrollment, setSelectedEnrollment] = useState<string>(
		enrollmentId || "",
	);

	const {
		data: participantEnrollmentPeriods,
		isLoading: isLoadingParticipantEnrollmentPeriods,
	} = useGetParticipantEnrollmentPeriods(participantId as string);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const { isAuthenticated, loginWithRedirect } = useAuth0();

	const {
		participant,
		isLoadingParticipantProfile,
		refetchParticipantProfile,
	} = useParticipantProfile({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		participantId: participantId as string,
		enrollmentId: selectedEnrollment as string,
	});

	const actionMenu = useMemo(() => {
		const status = participant?.lastEnrollment.status;

		return status === ParticipantStatus.DISENROLLED
			? {
					value: "Re-enroll",
					onClick: () => onReenrollParticipant(),
				}
			: {
					value: "Disenroll",
					onClick: () => onDisenrollParticipant(),
				};
	}, [participant]);

	const actionsMenu = useMemo(() => {
		const options = [
			{ value: "Edit", onClick: () => onEditParticipant() },
			actionMenu,
		];

		return options;
	}, [participant]);

	const onEditParticipant = () => {
		setOpenEditModal(true);
	};

	const onDisenrollParticipant = () => {
		setOpenDisenrollModal(true);
	};

	const onReenrollParticipant = () => {
		setOpenReenrollModal(true);
	};

	useEffect(() => {
		if (!isAuthenticated) {
			loginWithRedirect();
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (
			!isLoadingParticipantEnrollmentPeriods &&
			participantEnrollmentPeriods
		) {
			setSelectedEnrollment(participantEnrollmentPeriods?.[0].key);
		}
	}, [isLoadingParticipantEnrollmentPeriods, participantEnrollmentPeriods]);

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (participant?._id !== undefined && !postAuditlogCalledRef.current && userInfo?._id !== undefined) {
			createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When entering",
				detail: `Viewed participant’s profile ${participant?.user.firstName} ${participant?.user.lastName}`,
				actionCode: "WEB_PART_PROFILE_VIEW",
				option: "Participant’s profile",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			postAuditlogCalledRef.current = true;
		}
	}, [participant, userInfo]);

	const handleEnrollmentChange = (newEnrollmentId: string) => {
		invalidateQueries([
			{ queryKey: ["nalcam-participant-profile", participantId, selectedEnrollment] }, 
			{ queryKey: ["get-participant-enrollment-periods", participantId] },
			{ queryKey: ["get-medication-proof-list", participantId] },
			{ queryKey: ["get-participant-card-balances", participantId] },
		]);

		setSelectedEnrollment(newEnrollmentId);
	};

	useEffect(() => {
		refetchParticipantProfile();
	}, [participantId, selectedEnrollment]);
	
	useEffect(() => {
		if (enrollmentId) {
			handleEnrollmentChange(enrollmentId);
		}
	}, [enrollmentId]);

	return (
		<>
			<div className="w-full overflow-y-auto scroll-smooth">
				<div className="full-width-container mx-auto">
					{!isLoadingParticipantProfile && participant ? (
						<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
							<Box sx={boxWrapper}>
								<Box sx={boxInner}>
									<Breadcrumbs
										items={[
											{
												label: "Participants list",
												href: "/participants",
												isLink: true,
											},
											{
												label: `${participant.user.firstName} ${participant.user.lastName}`,
												isLink: false,
											},
										]}
									/>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<Typography variant="headlineMedium">
											{capitalize(
												`${participant.user.firstName} ${participant.user.lastName}`,
											)}
										</Typography>

										<Box display={"flex"} gap={1}>
											{/* <Tooltip title="Audit log">
												<IconButton
													size="large"
													sx={auditLogIcon(theme)}
													onClick={() => setOpenAuditLogModal(true)}
												>
													<HistoryOutlinedIcon
														sx={{
															color: (theme) => theme.palette.icon.dark,
														}}
														className="text-icon-primary"
													/>
												</IconButton>
											</Tooltip> */}

											<ActionMenu
												options={actionsMenu}
												isSquare
												squareSx={{
													width: "3rem",
													height: "3rem",
												}}
											/>
										</Box>
									</Box>
								</Box>

								<Box sx={boxSmall}>
									<Card sx={{ minWidth: 275, width: "100%" }}>
										<ProfileInformation participant={participant} />
									</Card>
									<Card sx={{ maxWidth: 235, width: "100%" }}>
										<ProfileCardBalance
											participant={participant}
											enrollmentId={selectedEnrollment}
											onHandleCardTransaction={() => {}}
										/>
									</Card>
								</Box>

								<Card>
									<CardContent>
										<Box sx={{ width: "100%" }}>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Typography variant="headlineSmall">
													Campaign progress
												</Typography>
												<Box sx={boxEnrollmentPeriods}>
													<BasicSelect
														handleChange={(value) => {
															handleEnrollmentChange(value as string);
														}}
														size="small"
														data={participantEnrollmentPeriods || []}
														value={selectedEnrollment}
														label="Enrollment period"
														id="participant-enrollment-periods"
													/>
												</Box>
											</Box>
											<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
												<Tabs
													value={selectedTab}
													onChange={handleChange}
													aria-label="basic tabs example"
													sx={{
														".MuiTabs-flexContainer": {
															padding: ".25rem 0",
														},
													}}
												>
													{/* <Tab
														label="OVERVIEW"
														sx={{ px: 3, fontWeight: 700 }}
														{...a11yProps(1)}
													/> */}
													<Tab
														label="Results"
														sx={{ px: 3, fontWeight: 700 }}
														{...a11yProps(0)}
													/>
												</Tabs>
											</Box>
											{/* <TabPanel value={selectedTab} index={1}></TabPanel> */}
											<TabPanel value={selectedTab} index={0}>
												{selectedEnrollment ? (
													<ProfileResults
														enrollmentId={selectedEnrollment}
														participantId={participantId as string}
													/>
												) : null}
											</TabPanel>
										</Box>
									</CardContent>
								</Card>
							</Box>
						</div>
					) : (
						<>
							<Skeleton variant="text" width={260} height={24} />
							<Skeleton variant="text" width={220} height={48} />
							<Box
								sx={{
									display: "flex",
									gap: "1.25rem",
									width: "100%",
								}}
							>
								<Skeleton
									variant="rectangular"
									sx={{ minWidth: 275, width: "100%" }}
									height={350}
								/>
								<Skeleton
									variant="rectangular"
									sx={{ maxWidth: 235, width: "100%" }}
									height={350}
								/>
							</Box>
						</>
					)}
				</div>
			</div>

			{openEditModal ? (
				<EditParticipantModal
					open={openEditModal}
					onClose={() => {
						setOpenEditModal(false);
						refetchParticipantProfile();
					}}
					participant={participant as ParticipantItem}
				/>
			) : null}

			{openDisenrollModal ? (
				<DisenrollParticipantModal
					open={openDisenrollModal}
					onClose={() => {
						setOpenDisenrollModal(false);
						refetchParticipantProfile();
					}}
					participant={participant as ParticipantItem}
				/>
			) : null}

			{openReenrollModal ? (
				<ReenrollParticipantModal
					open={openReenrollModal}
					onClose={() => {
						setOpenReenrollModal(false);
						refetchParticipantProfile();
					}}
					participant={participant as ParticipantItem}
				/>
			) : null}
		</>
	);
};

export default ParticipantProfile;
