"use client";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { InviteParticipantModalProvider } from "../container/InviteParticipantModal/InviteParticipantModalProvider";
import { FeedBackProvider } from "../providers/FeedBackProvider/FeedBackProvider";
import { CampaignFiltersProvider } from "../providers/CampaignFiltersContext";

function Providers({ children }: React.PropsWithChildren) {

	return (
		<ThemeProvider theme={theme}>
			<FeedBackProvider>
				<CampaignFiltersProvider>
					<InviteParticipantModalProvider>
						<Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
					</InviteParticipantModalProvider>
				</CampaignFiltersProvider>
			</FeedBackProvider>
		</ThemeProvider>
	);
}

export default Providers;
