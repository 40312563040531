import {
	UseMutationResult,
	UseQueryResult,
	skipToken,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	findByEmail,
	create,
	findByAccount,
	findById,
	update,
	getUserList,
	deleteOne,
	resendInvitation,
	GetUserListParams,
	IUserListResponse,
} from "../../services/users.service";
import {
	IUser,
	IUserPayload,
	IUserDelete,
	IResendInvitation,
	ParamsDelete,
	ParamsResendEmail,
} from "../../domain/user.interface";

const LOG_PREFIX = "useUser:";

export const useFindByEmail = (email: string | undefined, select?: any) => {
	const userQuery = useQuery({
		queryKey: ["get-user-by-email", email],
		queryFn: email ? () => findByEmail(email) : skipToken,
		staleTime: 1000 * 60 * 5,
		enabled: !!email,
		select,
	});

	return userQuery;
};

export const useGetUserById = (
	userId: string,
): UseQueryResult<IUser, Error> => {
	const userQuery = useQuery<IUser, Error>({
		queryKey: ["get-user-by-id", userId],
		queryFn: async () => await findById(userId),
		staleTime: 0,
	});

	return userQuery;
};

export const useGetUsersByAccount = (
	account: string,
	initialData = [],
): UseQueryResult<IUser[]> => {
	const userQuery = useQuery({
		queryKey: ["get-user-list"],
		queryFn: async () => await findByAccount(account),
		initialData,
	});

	return userQuery;
};

export const useCreateUser = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: IUserPayload) => create(data),
		onSuccess: () =>
			queryClient.invalidateQueries({ queryKey: ["get-user-list"] }),
	});
};

export const useUpdateUser = ({ id }: { id: string }) => {
	return useMutation({
		mutationKey: ["update-user"],
		mutationFn: (data: Partial<IUserPayload>) => update(id, data),
	});
};

export const useGetUserList = (params: GetUserListParams): UseQueryResult<IUserListResponse, Error> => {
	return useQuery<IUserListResponse, Error>({
	  queryKey: ['get-user-list', params],
	  queryFn: () => getUserList(params),
	  enabled: !!params.accountId, 
	});
  };

export const useDeleteUser = () => {
	const userQuery = useMutation({
		mutationFn: async (params: ParamsDelete) => await deleteOne( params ),
	});

	return userQuery;
};

export const useResendInviteEmail = () => {
	const userQuery = useMutation({
		mutationFn: async (params:ParamsResendEmail) => await resendInvitation(params),
	});

	return userQuery;
};
