import React, { ReactNode, useState } from "react";
import InviteParticipantModal from ".";
import { InviteParticipantModalContext } from "./InviteParticipantModalContext";
import { ParticipantItem } from "../../domain/participant.interface";

export const InviteParticipantModalProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isBeneficiaryPage, setIsBeneficiaryPage] = useState(false);
	// const [beneficiary, setBeneficiary] = useState<
	// 	BeneficiaryResponse | undefined
	// >(undefined);

	const openModal = (beneficiary?: ParticipantItem) => {
		setIsOpen(true);
		// if (beneficiary) {
		// 	setIsRegister(false);
		// }
		// setBeneficiary(beneficiary);
	};
	const closeModal = () => {
		setIsOpen(false);
		// setIsRegister(true);
	};

	const showButton = () => setIsBeneficiaryPage(true);
	const hideButton = () => setIsBeneficiaryPage(false);

	return (
		<InviteParticipantModalContext.Provider
			value={{
				openModal,
				closeModal,
				showButton,
				hideButton,
			}}
		>
			{children}
			{isOpen && (
				<InviteParticipantModal
					open={isOpen}
					onClose={closeModal}
				/>
			)}
		</InviteParticipantModalContext.Provider>
	);
};
