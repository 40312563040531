import { FC } from "react";
import Box from "@mui/material/Box";
import UserForm from "../../container/User/UserForm";
import { useNavigate } from "react-router-dom";
import { useCreateUser } from "../../hooks/useUser/useUser";
import { IUserPayload } from "../../domain/user.interface";
import UserHeader from "../../container/User/UserHeader";
import { UserSchema } from "../../container/User/useSchema";
import { useFeedBack } from "../../providers/FeedBackProvider/FeedBackContext";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { useSession } from "../../hooks/useSession";
import { useGetRolesByAccount } from "../../hooks/useRole";
import { MODULE_NAME } from "../../domain/auditlog.enum";

const User: FC = () => {
	const navigate = useNavigate();
	const mutation = useCreateUser();
	const { showSnackBar } = useFeedBack();
	const createAuditlogMutation = useCreateAuditlog();
	const { data: currentUser } = useSession();
	const { data: roles } = useGetRolesByAccount(
		process.env.REACT_APP_ACCOUNT_ID as string,
		currentUser?._id,
	);

	const onSubmit = async (data: UserSchema) => {
		if (!data.roleId) {
			showSnackBar("Please choose a use role first.", "warning");
			return;
		}

		const accountId =
			(process.env.REACT_APP_ACCOUNT_ID as string) ||
			"6682d71089b49a7e9418627c";

		try {
			const dataMapped: IUserPayload = {
				accountId,
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				status: data.status,
				phoneNumber: data.phoneNumber || "",
				npi: data.npi || "",
				roles: [data.roleId],
				programs: data.programs?.map((selOrg) => ({
					orgUnitId: (selOrg.orgUnitId as string[]) || [],
					programId: selOrg.programId as string,
				})),
			};

			await mutation.mutateAsync(dataMapped);
			createAuditlogMutation.mutateAsync({
				action: "When inviting a new user (adding)",
				module: MODULE_NAME.USERS,
				option: "User add",
				detail: `Invited new user ${data.email}`,
				actionCode: "WEB_USRS_USR_ADD_INVITE",
				appType: "WEB_BACK_OFFICE",
				createdBy: currentUser?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
			});
			navigate("/users");
		} catch (error: any) {
			showSnackBar(error.message || error.toString());
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				width: "100%",
				marginBottom: "2rem",
				overflow: "hidden",
			}}
		>
			<UserHeader header="Invite new user" />
			<UserForm
				user={undefined}
				isEditMode={false}
				onSubmit={onSubmit}
				saveDisabled={mutation.isPending}
				roles={roles}
				submitLabel="Invite"
			/>
		</Box>
	);
};

export default User;
