import { IRole } from "@/domain/rol.interface";
import { axios, transformAxiosError } from "../utils/axios.create"

export const findByAccount = async (accountId: string, userId: string): Promise<IRole[]> => {
	return axios
		.get(`/roles?userId=${userId}`)
		.then((res) => {
			return res.data.body as IRole[];
		}).then(roles => roles.filter(rol => rol.status === "ACTIVE"))
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};


export const getById = async (id: string): Promise<IRole> => {
	return axios
		.get(`/roles/${id}`)
		.then((res) => {
			return res.data.body as IRole;
		})
		.catch((err) => {
			return Promise.reject(transformAxiosError(err));
		});
};