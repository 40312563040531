import {
	IParticipant,
	ParticipantItem,
	ParticipantQueueItem,
	BeneficiaryEnrollmentPeriodsResponse,
	ParticipantCardBalanceResponse,
	DenyParticipantsResponse,
	DenyParticipantsPayload,
} from "../domain/participant.interface";
import { axios } from "../utils/axios.create";
import { Program, OrganizationUnit } from "../domain/program.interface";
import dayjs from "dayjs";

export const listByProgramStatus = async (
	programStatus: string = "ACTIVE",
): Promise<IParticipant[]> => {
	return axios
		.get(`/participants?${new URLSearchParams({ programStatus }).toString()}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};

const getEnrolledParticipants = async ({
	accountId,
	programId,
	orgUnitId,
	orgUnitLabelId,
	status,
	search,
	pageNumber,
	pageSize,
	sortField,
	sortOrder,
	isArchived,
}: {
	accountId: string;
	programId: string;
	orgUnitId: string[];
	orgUnitLabelId: string;
	status: string[];
	search: string;
	pageNumber: number;
	pageSize: number;
	sortField: string;
	sortOrder: number;
	isArchived: boolean;
}): Promise<{
	totalCount: number;
	participants: ParticipantItem[];
}> => {
	if (programId === "") {
		return Promise.resolve({ totalCount: 0, participants: []});
	}
	
	const program = new URLSearchParams({ programId }).toString();
	const orgUnit = new URLSearchParams({
		orgUnitId: orgUnitId.join(","),
	}).toString();
	const orgUnitLabel = new URLSearchParams({ orgUnitLabelId }).toString();
	const statusQuery = new URLSearchParams({
		status: status.join(","),
	}).toString();
	const searchQuery = new URLSearchParams({ search }).toString();
	const pageQuery = new URLSearchParams({
		pageNumber: String(pageNumber + 1),
		pageSize: String(pageSize),
	}).toString();
	const sortQuery = new URLSearchParams({
		sortField,
		sortOrder: String(sortOrder),
	}).toString();
	const isArchivedQuery = new URLSearchParams({
		isArchived: String(isArchived),
	}).toString();

	return axios
		.get(
			`/participants?${program}&${orgUnit}&${orgUnitLabel}&${statusQuery}&${searchQuery}&${isArchivedQuery}&${pageQuery}&${sortQuery}`,
		)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};

const getQueueParticipants = async ({
	accountId,
	orgUnitId,
	search,
	onboardingType,
	registration,
	pageNumber,
	pageSize,
	sortField,
	sortOrder,
}: {
	accountId: string;
	orgUnitId: string;
	search: string;
	onboardingType: string;
	registration: string[];
	pageNumber: number;
	pageSize: number;
	sortField: string;
	sortOrder: number;
}): Promise<{
	totalCount: number;
	participants: ParticipantQueueItem[];
}> => {
	const orgUnit = new URLSearchParams({ orgUnitId }).toString();
	const onboardingTypeQuery = new URLSearchParams({
		type: onboardingType,
	}).toString();
	const registrationQuery = new URLSearchParams({
		status: registration.join(","),
	}).toString();
	const searchQuery = new URLSearchParams({ search }).toString();
	const pageQuery = new URLSearchParams({
		pageNumber: String(pageNumber + 1),
		pageSize: String(pageSize),
	}).toString();
	const sortQuery = new URLSearchParams({
		sortField,
		sortOrder: String(sortOrder),
	}).toString();

	return axios
		.get(
			`/participants/queue?${orgUnit}&${registrationQuery}&${searchQuery}&${onboardingTypeQuery}&${pageQuery}&${sortQuery}`,
		)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getPrograms = async ({
	accountId,
	organizationIds,
}: {
	accountId: string;
	organizationIds: string[];
}): Promise<Program[]> => {
	return axios
		.get(
			`/programs?organizationIds=${organizationIds.join(",")}`,
		)
		.then((res) => {
			const { body } = res.data;
			return body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getOrganizationUnits = async ({
	accountId,
	organizationIds,
	organizationLabels,
}: {
	accountId: string;
	organizationIds: string[];
	organizationLabels: string;
}): Promise<OrganizationUnit[]> => {
	return axios
		.get(
			`/organization?organizationIds=${organizationIds.join(",")}&organizationLabels=${organizationLabels}`,
		)
		.then((res) => {
			const { body } = res.data;
			return body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getOrganizationLabels = async ({
	accountId,
	organizationLabelIds,
}: {
	accountId: string;
	organizationLabelIds: string[];
}): Promise<{ key: string; value: string }[]> => {
	return axios
		.get(
			`/organization/organizationlabel?organizationLabelIds=${organizationLabelIds.join(",")}`,
		)
		.then((res) => {
			const { body } = res.data;

			const optons = body.map((item: any) => ({
				key: item.id,
				value: item.name,
			}));

			return optons;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getParticipantProfile = async ({
	accountId,
	participantId,
	enrollmentId,
}: {
	accountId: string;
	participantId: string;
	enrollmentId?: string;
}): Promise<ParticipantItem> => {
	return axios
		.get(
			`/participant/${participantId}/enrollment/${enrollmentId}`,
		)
		.then((res) => {
			const { body } = res.data;
			return body.participants[0];
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const postInviteParticipant = async ({
	accountId,
	data,
}: {
	accountId: string;
	data: any;
}) => {
	return axios
		.post(`/participants`, data)
		.then((res) => {
			const { body } = res.data;
			return body;
		})
		.catch((err) => {
			if (err.response) {
				return Promise.reject(err.response.data);
			}

			return Promise.reject({ message: "Network error" });
		});
};

const putParticipantProfile = async ({
	accountId,
	data,
}: {
	accountId: string;
	data: any;
}) => {
	return axios
		.put(`/participant`, data)
		.then((res) => {
			const { body } = res.data;
			return body;
		})
		.catch((err) => {
			if (err.response) {
				return Promise.reject(err.response.data);
			}

			return Promise.reject({ message: "Network error" });
		});
};

const patchDisenrollParticipant = async ({
	accountId,
	data,
}: {
	accountId: string;
	data: any;
}) => {
	return axios
		.patch(`/enrollment/disenroll`, data)
		.then((res) => {
			const { body } = res.data;
			return body;
		})
		.catch((err) => {
			if (err.response) {
				return Promise.reject(err.response.data);
			}

			return Promise.reject({ message: "Network error" });
		});
};

const patchDenyParticipants = async ({
	accountId,
	data,
}: {
	accountId: string;
	data: DenyParticipantsPayload;
}): Promise<DenyParticipantsResponse> => {
	return axios
		.patch(`/enrollment/deny`, data)
		.then((res) => res.data.body)
		.catch((err) => {
			if (err.response) {
				return Promise.reject(err.response.data);
			}

			return Promise.reject({ message: "Network error" });
		});
}

const patchReenrollParticipant = async ({
	accountId,
	data,
}: {
	accountId: string;
	data: any;
}): Promise<string> => {
	return axios
		.patch(`/participant/enroll`, data)
		.then((res) => {
			const { message } = res.data;
			return message;
		})
		.catch((err) => {
			if (err.response) {
				return Promise.reject(err.response.data);
			}

			return Promise.reject({ message: "Network error" });
		});
};

const patchEnrollQueueParticipant = async ({
	accountId,
	data,
}: {
	accountId: string;
	data: any;
}) => {
	return axios
		.patch(`/participant/enrollqueue`, data)
		.then((res) => {
			const { body } = res.data;
			return body;
		})
		.catch((err) => {
			if (err.response) {
				return Promise.reject(err.response.data);
			}

			return Promise.reject({ message: "Network error" });
		});
};

const getEnrollmentPeriods = async (
	participantId: string,
): Promise<{ key: string; value: string }[]> => {
	return axios
		.get(`/participants/${participantId}/enrollment-periods`)
		.then((res) => {
			const response = res.data.body;

			const beneficiaryEnrollmentPeriods = response.map(
				(item: BeneficiaryEnrollmentPeriodsResponse) => {
					const value = `${item.program.name}: ${dayjs(item.startDate).format("MM/DD/YYYY")} - ${item.endDate ? dayjs(item.endDate).format("MM/DD/YYYY") : ""}`;

					return {
						key: item._id,
						value,
					};
				},
			);

			return beneficiaryEnrollmentPeriods;
		})
		.catch((err) => Promise.reject(err.response.data));
};

const getCardBalances = async ({
	accountId,
	participantId,
	enrollmentId,
}: {
	accountId: string;
	participantId: string;
	enrollmentId: string;
}): Promise<ParticipantCardBalanceResponse> => {
	return axios
		.get(`/participant/${participantId}/enrollment/${enrollmentId}/cardbalance`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
}

export const ParticipantServices = {
	getEnrolledParticipants,
	getQueueParticipants,
	getPrograms,
	getOrganizationUnits,
	getOrganizationLabels,
	getParticipantProfile,
	postInviteParticipant,
	putParticipantProfile,
	patchDisenrollParticipant,
	patchReenrollParticipant,
	patchEnrollQueueParticipant,
	patchDenyParticipants,
	getEnrollmentPeriods,
	getCardBalances
};
