import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const getIcon = (iconName: string) => {
  switch (iconName) {
    case "SpaceDashboardIcon":
      return <SpaceDashboardIcon />;
    case "PermIdentity":
      return <PermIdentityIcon />;
    case "ManageAccountsIcon":
      return <ManageAccountsIcon />;
    case "HelpOutlineOutlined":
      return <HelpOutlineOutlined />;
    default:
      return null;
  }
};

export default getIcon;