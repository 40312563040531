import { skipToken, useQuery, useQueryClient } from "@tanstack/react-query";
import { getIconOrDefault } from "../utils/menuConfig";
import { IAction } from "../domain/action.interface";
import { MenuItem } from "../domain/menu.interface";
import useAuth from "./useAuth";
import useLogger from "./useLogger";
import { IUserWithRoles, findByEmail } from "../services/users.service";
import { useAuth0 } from "@auth0/auth0-react";

const LOG_PREFIX = "useMenu:";

const actionToMenu = (action: IAction): MenuItem => {
    return {
        id: action._id,
        icon: getIconOrDefault(action.code),
        title: action.name,
        route: action.link,
        type:
            action.subActions && action.subActions?.length > 0 ? "parent" : "link",
        children: action.subActions?.map(actionToMenu),
    };
};

const rolesToMenu = (user: IUserWithRoles) => {
    const result = user.roles
        .flatMap((role) => role.actions)
        .filter((action) => action.type === "MENU_OPTION")
        .sort((a, b) => a.order - b.order)
        .map(actionToMenu);
    return result;
}

export const useMenu = () => {
    const { user } = useAuth0();
    const {
		data,
		error,
		isLoading,
	} = useQuery({
		queryKey: ["get-user-by-email", user?.email],
		queryFn: () => findByEmail(user?.email as string),
        enabled: !!user?.email,
        staleTime: 1000 * 60 * 5,
        select: rolesToMenu,
	});
    // useLogger(`${LOG_PREFIX} findByEmail email: ${user?.email}`, { email: user?.email });


    return { data, error, isLoading };
};
