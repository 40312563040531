import { AppTheme } from "./theme";
import { MedicationProofStatus } from "../domain/enrollment.interface";

export const getStatusColor = (result: string, theme: AppTheme) => {
	switch (result) {
		case MedicationProofStatus.FAILED:
			return theme.palette.error.light;
		case MedicationProofStatus.SUCCESS:
			return theme.palette.secondary.light;
		case MedicationProofStatus.PENDING:
			return "#FCEAD0";
		default:
			return theme.palette.secondary.light;
	}
};
