import { CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { FC, useMemo } from "react"
import { useHelpSupport } from "../../hooks/useGeneral";
import { HelpSupportResponse } from "../../domain/helpSupport.interface";
import { useAppTheme } from "../../utils/theme";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const HelpSupport: FC = () => {
  const theme = useAppTheme();

  const {
    helpSupport,
    isLoadingHelpSupport,
    refetchHelpSupport
  } = useHelpSupport();

  const {
    helpDeskPhoneNumber,
    helpCreateTicketURL,
    helpDocumentationURL,
    helpFAQURL,
    helpDeskHours
  } = useMemo<Partial<HelpSupportResponse>>(() => {
    return helpSupport || {};
  }, [helpSupport]);

  return (
    <>
      <div className="w-full overflow-y-auto scroll-smooth">
        <div className="full-width-container mx-auto">
          <div className="flex flex-col items-center justify-start min-h-screen space-y-5">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography variant="headlineSmall" component="h1">
                Help & support
              </Typography>
            </Box>

            <Box
              sx={{
                justifyContent: "justify-between",
                width: "100%",
                gap: "1.25rem"
              }}
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
            >
              {helpFAQURL ? (
                <Card className="flex flex-1">
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <figure>
                      <img
                        src={`./assets/help-support/${helpFAQURL[0].name}.png`}
                        alt="FAQ icon"
                        className="w-full object-cover object-center"
                      />
                    </figure>
                    <Typography variant="titleSmall" fontWeight={700} component="h3">{helpFAQURL[0].description}</Typography>
                    <Typography variant="bodyLarge" fontWeight={400} component="p" minHeight={100} color={theme.palette.textMain.main2}>
                      View the Frequently Asked Questions for information on managing your participants and distributing incentives.
                    </Typography>
                    <div className="mt-auto">
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => window.open(helpFAQURL[0].default)}
                      >
                        VIEW
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ) : null}
              {helpDocumentationURL ? (
                <Card className="flex flex-1">
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <figure>
                      <img
                        src={`./assets/help-support/${helpDocumentationURL[0].name}.png`}
                        alt="FAQ icon"
                        className="w-full object-cover object-center"
                      />
                    </figure>
                    <Typography variant="titleSmall" fontWeight={700} component="h3">{helpDocumentationURL[0].description}</Typography>
                    <Typography variant="bodyLarge" fontWeight={400} component="p" minHeight={100} color={theme.palette.textMain.main2}>
                      To access a complete list of training materials and documentation, please click the button below.
                    </Typography>
                    <div className="mt-auto">
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => window.open(helpDocumentationURL[0].default)}
                      >
                        VIEW
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ) : null}
              {helpCreateTicketURL ? (
                <Card className="flex flex-1">
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <figure>
                      <img
                        src={`./assets/help-support/${helpCreateTicketURL[0].name}.png`}
                        alt="FAQ icon"
                        className="w-full object-cover object-center"
                      />
                    </figure>
                    <Typography variant="titleSmall" fontWeight={700} component="h3">{helpCreateTicketURL[0].description}</Typography>
                    <Typography variant="bodyLarge" fontWeight={400} component="p" minHeight={100} color={theme.palette.textMain.main2}>
                      Generate a ticket, and our dedicated support staff will promptly assist you in resolving any issues, ensuring a smooth and hassle-free experience.
                    </Typography>
                    <div className="mt-auto">
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => window.open(helpCreateTicketURL[0].default)}
                      >
                        CREATE REQUEST
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "justify-between",
                width: "100%",
                gap: "1.25rem"
              }}
            >
              {helpDeskPhoneNumber?.[0].default ? (
                <Card className="flex w-full">
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography variant="titleSmall" fontWeight={700} component="h3">Still need help? Contact us</Typography>
                    <div className="flex flex-col gap-5">
                      <Typography variant="bodyLarge" fontWeight={400} component="p" color={theme.palette.textMain.main2}>
                        If you're in need of additional support, please call the help desk at:
                      </Typography>
                      <Link href={`tel:${helpDeskPhoneNumber[0].default}`} variant="bodyLarge" fontWeight={500} color={theme.palette.primary.main}>
                        {helpDeskPhoneNumber[0].default}
                      </Link>
                      <Typography variant="bodyMedium" fontWeight={400} component="p" color={theme.palette.textMain.main2}>
                        {helpDeskHours?.[0].default}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              ) : null}
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default HelpSupport;