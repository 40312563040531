import { useQuery } from "@tanstack/react-query";
import { GeneralServices } from '../services/general.service';

const {
	getDashboardTotals,
	getHelpSupport,
	getSearchBarResults
} = GeneralServices;

export const useDashboardTotals = ({
	accountId,
	programId,
	orgUnitId,
	orgUnitLabelId,
}: {
	accountId: string;
	programId: string;
	orgUnitId: string[];
	orgUnitLabelId: string;
}) => {
	const { data: dashboardTotals, isLoading: isLoadingTotals, refetch: refetchTotals } = useQuery({
		queryKey: ["nalcam-dashboard-totals"],
		queryFn: () => getDashboardTotals({
			accountId,
			programId,
			orgUnitId,
			orgUnitLabelId
		}),
		staleTime: 1000 * 60 * 5,
		enabled: programId !== "",
	});

	return {
		dashboardTotals,
		isLoadingTotals,
		refetchTotals
	}
};

export const useHelpSupport = () => {
	const { data: helpSupport, isLoading: isLoadingHelpSupport, refetch: refetchHelpSupport } = useQuery({
		queryKey: ["nalcam-help-support"],
		queryFn: () => getHelpSupport(),
		staleTime: 1000 * 60 * 5,
	});

	return {
		helpSupport,
		isLoadingHelpSupport,
		refetchHelpSupport
	}
}

export const useSearchBar = ({
	searchQuery
}: {
	searchQuery: string
}) => {
	const { data: searchBarResults, isLoading, isFetching, refetch: refetchSearchBarResults } = useQuery({
		queryKey: ["nalcam-search-bar-results"],
		queryFn: () => getSearchBarResults({
			searchQuery
		})
	});

	return {
		searchBarResults,
		isLoading,
		isFetching,
		refetchSearchBarResults
	}
}