import React, { FC, useMemo } from "react";
import { useAppTheme } from "../../utils/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { useCurrencySetting } from "../../hooks/useSetting";
import Button from "@mui/material/Button";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { balanceBoxButtons } from "./profile.styles";
import { ParticipantItem } from "@/domain/participant.interface";
import { useGetParticipantCardBalances } from '../../hooks/useParticipant';
import dayjs from "dayjs";

const ProfileCardBalance: FC<{
	participant: ParticipantItem;
	enrollmentId: string;
	onHandleCardTransaction: () => void;
}> = ({ participant, enrollmentId, onHandleCardTransaction }) => {
	const theme = useAppTheme();
	const currencySetting = useCurrencySetting();

	const {
		cardBalances,
		refetchCardBalances,
	} = useGetParticipantCardBalances({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		participantId: participant._id,
		enrollmentId
	});

	const hasCardBalance = useMemo(() => {
		return cardBalances && cardBalances.cardBalance?.accounts?.[0]?.cards?.length > 0;
	}, [cardBalances]);

	return (
		<CardContent className="space-y-4 h-full">
			<Box
				display={"flex"}
				flexDirection={"column"}
				gap={"1rem"}
				minHeight={"100%"}
			>
				<Typography
					variant="headlineSmall"
					sx={{ color: theme.palette.textMain.main2 }}
				>
					<Box display={"flex"} alignItems={"center"} gap={"10px"} fontSize="22px">
						<Box
							sx={{
								backgroundColor: theme.palette.surface.main,
								borderRadius: "50%",
								border: `1px solid ${theme.palette.outline.main}`,
								color: theme.palette.icon.main,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "2.5rem",
								width: "2.5rem",
							}}
						>
							<CreditCardIcon className="text-icon-primary" />
						</Box>
						Card balance
					</Box>
				</Typography>
				{hasCardBalance ? (
					<>
						<Typography variant="headlineLarge" sx={{ fontWeight: 500 }}>
							{`${currencySetting?.mask} ${cardBalances?.amount}`}
						</Typography>
						<Typography variant="labelLarge" sx={{ fontWeight: 500, color: "#7D7979" }}>
						●●●● ●●●● ●●●● {cardBalances?.cardBalance.accounts[0].cards[0].last4CardNumber}
						</Typography>
						<Typography variant="labelLarge" sx={{ fontWeight: 500, color: "#7D7979" }}>
							Exp: {dayjs(cardBalances?.cardBalance.accounts[0].cards[0].expirationDate).format("MM/YY")}
						</Typography>

						<Box sx={balanceBoxButtons}>
							<Button
								size="large"
								onClick={onHandleCardTransaction}
								color="primary"
								variant="contained"
								sx={{
									textTransform: "none",
									textAlign: "center",
									padding: "0.5rem 0.5rem",
									height: "3rem",
									fontSize: "0.9rem",
									fontWeight: 700,
									lineHeight: "1.5rem",
								}}
							>
								CARD TRANSACTIONS
							</Button>
						</Box>
					</>
				) : (
					<Typography variant="bodyMedium" sx={{ fontWeight: 400, color: "#261F1F" }}>
						Participant must be enrolled and submit their first Naloxone photo to generate their card.
					</Typography>
				)}
			</Box>
		</CardContent>
	);
};

export default ProfileCardBalance;
