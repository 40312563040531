import { useAuth0 } from "@auth0/auth0-react";
import { useFindByEmail } from "./useUser/useUser";

export const useSession = () => {
	const { user } = useAuth0();

	const {
		data,
		error,
		isLoading,
	} = useFindByEmail(user?.email);

	// useLogger(`${LOG_PREFIX} useFindByEmail email: ${user?.email}`, { email: user?.email });

	const userHasAccess = (path: string) => {
		// everyone has access to unauthorized page or home
		if (["/unauthorized", "/"].includes(path?.toLowerCase())) return true;

		if (!data) return false;

		return data.roles.some((role) =>
			role.actions.some((a) => {
				const pathIsValid = path.split("/").length > 1;
				const pathSecondPart = path.split("/")[1].toLowerCase();

				const userHasAccess = pathIsValid
					? a.link.toLowerCase().includes(pathSecondPart)
					: false;
				const userHasChildAccess = a.subActions?.some((subAction) =>
					subAction.link.toLowerCase().includes(pathSecondPart),
				);
				return userHasAccess || userHasChildAccess;
			}),
		);
	}

	const userHasAccessToCode = (roleCode: string) => {
		if (!data) return false;

		return data.roles.some((role) =>
			role.actions.some((a) => {
				const userHasAccess = a.code === roleCode;
				const userHasChildAccess = a.subActions?.some(
					(subAction) => subAction.code === roleCode,
				);
				return userHasAccess || userHasChildAccess;
			}),
		);
	};

	return { data, error, isLoading, userHasAccess, userHasAccessToCode };
}
