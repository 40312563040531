import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { useEffect } from "react";
import Spinner from "../../components/Spinner/index";
import { useFindByEmail } from "../../hooks/useUser/useUser";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { MODULE_NAME } from "../../domain/auditlog.enum";

const Callback = () => {
	const { user } = useAuth0();
	const navigate = useNavigate();

	const createAuditlog = useCreateAuditlog();
	const { data, error, isLoading } = useFindByEmail(user?.email);

	const handleAuditlog = async () => {
		createAuditlog.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: MODULE_NAME.GENERAL,
			action: "Sign in (successfully)",
			detail: "User signed in successfully",
			actionCode: "WEB_SIGN_IN_OK",
			option: "Dashboard",
			createdBy: data?._id || "",
			accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		});
	}

	useEffect(() => {
		if (!isLoading) {
			if (data && data.status === "ACTIVE") {
				handleAuditlog();
				navigate("/");
				return;
			}
			navigate("/accessDenied");
		}
	}, [data, isLoading]);

	return (
		<div className="flex w-screen h-screen items-center justify-center">
			{isLoading && <Spinner />}
		</div>
	);
};

export default Callback;