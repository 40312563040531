import React, { FC, useState, useCallback } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { ChildOrganization } from "@/domain/organization.interface";
import { useQueryClient } from "@tanstack/react-query";
import { IUser } from "@/domain/user.interface";
import GenericAlertModal from "../../components/Modals/AlertModal/index";
import { useUserStore } from "../../stores/user.store";

type Props = {
  programId: string;
  childOrganizationsList: Array<ChildOrganization>;
  currentOrganizationIdsList: string[];
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const OrganizationCheckboxList: FC<Props> = ({
  programId,
  childOrganizationsList,
  currentOrganizationIdsList,
  handleCheckboxChange,
}) => {
  const queryClient = useQueryClient();

  const { userInfo } = useUserStore();

  const currentUser = queryClient.getQueryData([
    "get-user-by-id",
    userInfo?._id,
  ]) as IUser;
  const [showModal, setShowModal] = useState(false);

  const handleCheckboxChangeInternal = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
      childOrganizationId: string
    ) => {
      if (!checked) {
        const currentProgram = currentUser?.programs?.find(
          (program) => program.programId === programId
        );

        if (currentProgram) {
          const orgUnitId = currentProgram.orgUnitId.find(
            (orgUnit) => orgUnit.orgUnitId === childOrganizationId
          );

          if ((orgUnitId?.activeEnrollmentsCount as number) > 0) {
            event.preventDefault();
            setShowModal(true);
            return;
          }
        }
      }

      handleCheckboxChange(event);
    },
    [currentUser, programId, handleCheckboxChange]
  );

  return (
    <>
      <FormGroup>
        {childOrganizationsList.length === 0 ? (
          <div>No data loaded yet</div>
        ) : (
          childOrganizationsList.map((childOrganization) => (
            <FormControlLabel
              key={childOrganization.id}
              value={childOrganization.id}
              control={
                <Checkbox
                  checked={currentOrganizationIdsList.includes(
                    childOrganization.id
                  )}
                  onChange={(event, checked) =>
                    handleCheckboxChangeInternal(
                      event,
                      checked,
                      childOrganization.id
                    )
                  }
                />
              }
              label={childOrganization.name}
            />
          ))
        )}
      </FormGroup>

      {showModal && (
        <GenericAlertModal
          title="Edit user"
          description="The OU cannot be remove from the program scope because there are beneficiaries assigned."
          submitText="Accept"
          onClick={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default OrganizationCheckboxList;
