import { enrollmentService } from "../../services/enrollment.service";
import { useQuery } from "@tanstack/react-query";

const { findMedicationProofList } = enrollmentService;

export const useMedicationProofList = (
	enrollmentId: string,
	pageNumber: number,
	pageSize: number,
	sortField: string,
	sortOrder: number,
) => {
	return useQuery({
		queryKey: ["get-medication-proof-list"],
		queryFn: () =>
			findMedicationProofList(
				enrollmentId,
				pageNumber,
				pageSize,
				sortField,
				sortOrder,
			).then((dataResponse) => {
				return {
					total: dataResponse.total,
					data: dataResponse.data,
				};
			}),
		enabled: !!enrollmentId,
	});
};
