import { useMutation, useQuery } from "@tanstack/react-query";
import { ParticipantServices } from '../services/participants.service';
import { DenyParticipantsPayload } from "@/domain/participant.interface";

const {
	getEnrolledParticipants,
	getQueueParticipants,
	getPrograms,
	getOrganizationUnits,
	getOrganizationLabels,
	getParticipantProfile,
	postInviteParticipant,
	putParticipantProfile,
	patchDisenrollParticipant,
	patchReenrollParticipant,
	patchEnrollQueueParticipant,
	patchDenyParticipants,
	getEnrollmentPeriods,
	getCardBalances
} = ParticipantServices;

export const useParticipant = ({
	accountId,
	programId,
	orgUnitId,
	orgUnitLabelId,
	status,
	search,
	pageNumber,
	pageSize,
	sortField,
	sortOrder,
	isArchived = false,
}: {
	accountId: string;
	programId: string;
	orgUnitId: string[];
	orgUnitLabelId: string;
	status: string[];
	search: string;
	pageNumber: number;
	pageSize: number;
	sortField: string;
	sortOrder: number;
	isArchived: boolean;
}) => {
	const { data, isLoading: isLoadingParticipants, refetch: refetchParticipants } = useQuery({
		queryKey: ["nalcam-enrolled-participants"],
		queryFn: () => getEnrolledParticipants({
			accountId,
			programId,
			orgUnitId,
			orgUnitLabelId,
			status,
			search,
			pageNumber,
			pageSize,
			sortField,
			sortOrder,
			isArchived
		}),
		staleTime: 1000 * 60 * 5,
		enabled: programId !== "",
	});

	return {
		data,
		isLoadingParticipants,
		refetchParticipants
	}
};

export const useQueueParticipants = ({
	accountId,
	orgUnitId,
	registration,
	search,
	onboardingType,
	pageNumber,
	pageSize,
	sortField,
	sortOrder,
}: {
	accountId: string;
	orgUnitId: string;
	registration: string[];
	search: string;
	onboardingType: string;
	pageNumber: number;
	pageSize: number;
	sortField: string;
	sortOrder: number;
}) => {
	const { data, isLoading: isLoadingQueueParticipants, refetch: refetchQueueParticipants } = useQuery({
		queryKey: ["nalcam-queue-participants"],
		queryFn: () => getQueueParticipants({
			accountId,
			orgUnitId,
			search,
			onboardingType,
			registration,
			pageNumber,
			pageSize,
			sortField,
			sortOrder
		}),
		staleTime: 1000 * 60 * 5,
	});

	return {
		data,
		isLoadingQueueParticipants,
		refetchQueueParticipants
	}
}

export const usePrograms = ({
	accountId,
	organizationIds,
	isRegistering = false,
}: {
	accountId: string;
	organizationIds: string[];
	isRegistering?: boolean;
}) => {
	const { data: programs, isLoading: isLoadingPrograms, refetch: refetchPrograms } = useQuery({
		queryKey: !isRegistering ? ["nalcam-programs"] : ["nalcam-programs-register"],
		queryFn: () => getPrograms({
			accountId,
			organizationIds
		}),
		staleTime: 1000 * 60 * 5,
	});

	return {
		programs,
		isLoadingPrograms,
		refetchPrograms
	}
}

export const useOrganizationUnits = ({
	accountId,
	organizationIds,
	organizationLabels,
	isRegistering = false,
}: {
	accountId: string;
	organizationIds: string[];
	organizationLabels: string;
	isRegistering?: boolean;
}) => {
	const { data: organizationUnits, isLoading: isLoadingOrganizationUnits, refetch: refetchOrganizationUnits } = useQuery({
		queryKey: !isRegistering ? ["nalcam-organization-units"] : ["nalcam-organization-units-register"],
		queryFn: () => getOrganizationUnits({
			accountId,
			organizationIds,
			organizationLabels
		})
	});

	return {
		organizationUnits,
		isLoadingOrganizationUnits,
		refetchOrganizationUnits
	}
};

export const useOrganizationLalbels = ({
	accountId,
	organizationLabelIds,
}: {
	accountId: string;
	organizationLabelIds: string[];
}) => {
	const { data: organizationLabels, isLoading: isLoadingOrganizationLabels, refetch: refetchOrganizationLabels } = useQuery({
		queryKey: ["nalcam-organization-labels"],
		queryFn: () => getOrganizationLabels({
			accountId,
			organizationLabelIds
		}),
		staleTime: 1000 * 60 * 5,
	});

	return {
		organizationLabels,
		isLoadingOrganizationLabels,
		refetchOrganizationLabels
	}
};

export const useSendParticipantInvitation = ({
	accountId,
}: {
	accountId: string;
}) => {
	return useMutation({
		mutationFn: (data: any) =>
			postInviteParticipant({
				data,
				accountId,
			}),
		onError: (error) => {
			console.error("Error sending participant invitation:", error);
		}
	});
}

export const usePatchParticipantDisenroll = ({
	accountId,
}: {
	accountId: string,
}) => {
	return useMutation({
		mutationFn: (data: any) =>
			patchDisenrollParticipant({
				accountId,
				data,
			}),
		onError: (error) => {
			console.error("Error disenrolling participant:", error);
		}
	});
}

export const useParticipantProfile = ({
	accountId,
	participantId,
	enrollmentId,
}: {
	accountId: string;
	participantId: string;
	enrollmentId?: string;
}) => {
	const { data: participant, isLoading: isLoadingParticipantProfile, refetch: refetchParticipantProfile } = useQuery({
		queryKey: ["nalcam-participant-profile", participantId, enrollmentId],
		queryFn: () => getParticipantProfile({
			accountId,
			participantId,
			enrollmentId
		}),
	});

	return {
		participant,
		isLoadingParticipantProfile,
		refetchParticipantProfile
	}
}

export const usePatchParticipantReenroll = ({
	accountId,
}: {
	accountId: string,
}) => {
	return useMutation({
		mutationFn: (data: any) =>
			patchReenrollParticipant({
				accountId,
				data,
			}),
		onError: (error) => {
			console.error("Error reenrolling participant:", error);
		}
	});
}

export const usePutParticipantProfile = ({
	accountId,
}: {
	accountId: string;
}) => {
	return useMutation({
		mutationFn: (data: any) =>
			putParticipantProfile({
				data,
				accountId,
			}),
		onError: (error) => {
			console.error("Error updating participant profile:", error);
		}
	});
}

export const usePatchEnrollQueueParticipant = ({
	accountId,
}: {
	accountId: string,
}) => {
	return useMutation({
		mutationFn: (data: any) =>
			patchEnrollQueueParticipant({
				accountId,
				data,
			}),
		onError: (error) => {
			console.error("Error enrolling participants from queue:", error);
		}
	});
}

export const useGetParticipantEnrollmentPeriods = (participantId: string) => {
	return useQuery({
		queryKey: ["get-participant-enrollment-periods", participantId],
		queryFn: () => getEnrollmentPeriods(participantId),
	});
};

export const useGetParticipantCardBalances = ({
	accountId,
	participantId,
	enrollmentId
}: {
	accountId: string;
	participantId: string;
	enrollmentId: string;
}) => {
	const { data: cardBalances, isLoading: isLoadingCardBalances, refetch: refetchCardBalances } = useQuery({
		queryKey: ["get-participant-card-balances", participantId],
		queryFn: () => getCardBalances({
			accountId,
			participantId,
			enrollmentId
		}),
	});

	return {
		cardBalances,
		isLoadingCardBalances,
		refetchCardBalances
	}
}

export const usePatchDenyParticipants = ({
	accountId,
}: {
	accountId: string,
}) => {
	return useMutation({
		mutationFn: (data: DenyParticipantsPayload) =>
			patchDenyParticipants({
				accountId,
				data,
			}),
		onError: (error) => {
			console.error("Error denying participants:", error);
		}
	});
}