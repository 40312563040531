import { FC, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from "../../utils/a11yProps";
import TabPanel from "../../components/TabPanel";

import CampaignTab from "./CampaignTab";
import EnrollQueueTab from "./EnrollQueueTab";

const ParticipantList: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <div className="w-full overflow-y-auto scroll-smooth">
        <div className="full-width-container mx-auto">
          <div className="flex flex-col items-center justify-start min-h-screen space-y-5">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography variant="headlineSmall" component="h1">
                Participants list <InfoOutlinedIcon />
              </Typography>
            </Box>

            <Card sx={{ width: "100%", borderRadius: "8px" }}>
              <CardContent className="space-y-5">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={selectedTab}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      sx={{
                        ".MuiTabs-flexContainer": {
                          padding: ".25rem 0 0",
                        },
                      }}
                    >
                      <Tab
                        label="Campaigns"
                        sx={{ px: "1rem", maxHeight: "42px", minHeight: "42px", fontWeight: 700 }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Enroll QUEUE"
                        sx={{ px: "1rem", maxHeight: "42px", minHeight: "42px", fontWeight: 700 }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={selectedTab} index={0}>
                    <CampaignTab />
                  </TabPanel>
                  <TabPanel value={selectedTab} index={1}>
                    <EnrollQueueTab />
                  </TabPanel>
                </Box>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantList;
