import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Providers from "./utils/provider";
import Home from "./pages";
import "./i18n/config";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "./components/Spinner";
import Layout from "./views/Layout";
import Dashboard from "./pages/Dashboard";
import ParticipantList from "./pages/Participant/List";
import ParticipantProfile from "./pages/Participant/Profile";
import AccessDenied from "./pages/AccessDenied";
import UserList from "./pages/User/UserList";
import RoleList from "./pages/Role/List";
import EducationalAssetList from "./pages/EducationalAsset/List";
import AddUser from "./pages/User/AddUser";
import EditUser from "./pages/User/EditUser";
import Callback from "./pages/Callback";
import HelpSupport from "./pages/HelpSupport";
import { setToken } from "./utils/axios.create";

function App() {
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading: isAuthLoading,
    error,
    user,
  } = useAuth0();

  const [loginError, setLoginError] = useState<string | null>(null);

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isAuthLoading, loginWithRedirect]);

  const [showComponent, setShowComponent] = useState<
    "spinner" | "accessDenied" | "continue"
  >("spinner");

  useEffect(() => {
    if (isAuthLoading || !user) {
      setShowComponent("spinner");
    } else if (user && !isAuthenticated) {
      setShowComponent("accessDenied");
    } else {
      setShowComponent("continue");

      if (user?.email) {
        const getToken = async () => {
          const token = await getAccessTokenSilently();
          console.log("token:", token);
          setToken(token);
        };
        getToken();
      }
    }
  }, [isAuthLoading, user, isAuthenticated]);

  useEffect(() => {
    if (error) {
      setLoginError(error.message);
      console.error("Login error:", error.message);
    }
  }, [error]);

  if (loginError) {
    return <div>Oops... {loginError}</div>;
  }

  return (
    <>
      {showComponent === "spinner" ? (
        <div className="flex w-screen h-screen items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <Router>
          <Providers>
            <Routes>
              <Route path="/callback" element={<Callback />} />
              <Route path="/accessDenied" element={<AccessDenied />} />
              {showComponent === "continue" && (
                <Route
                  path="*"
                  element={
                    <Layout>
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />                        
                        <Route path="/participants">
                          <Route path="/participants" element={<ParticipantList />} />
                          <Route
                            path="/participants/:participantId"
                            element={<ParticipantProfile />}
                          />
                        </Route>
                        <Route path="/users">
                          <Route path="/users" element={<UserList />} />
                          <Route path="/users/add" element={<AddUser />} />
                          <Route path="/users/:id" element={<EditUser />} />
                        </Route>
                        <Route path="/roles" element={<RoleList />} />
                        <Route path="/educational" element={<EducationalAssetList />} />
                        <Route path="/helpsupport" element={<HelpSupport />} />
                      </Routes>
                    </Layout>
                  }
                />
              )}
            </Routes>
          </Providers>
        </Router>
      )}
    </>
  );
}

export default App;
