import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "@mui/material";
import { useAppTheme } from "../../utils/theme";
import Dialog from "@mui/material/Dialog";
import inviteValidationSchema from "./validation.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import BasicDatepicker from "../../components/BasicDatepicker";
import dayjs from "dayjs";
import BasicSelect from "../../components/BasicSelect";
import {
	useOrganizationUnits,
	usePrograms,
	useSendParticipantInvitation,
} from "../../hooks/useParticipant";
import { invitationPayload } from "./invitation-payload";
import { useFeedBack } from "../../providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "../../domain/error.interface";
import useManageQueries from "../../hooks/useManageQueries";
import { useCreateAuditlog } from "../../hooks/useAuditlog/index";
import { useSession } from "../../hooks/useSession";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetAccountSettings } from "../../hooks/useSetting/index";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { MODULE_NAME } from "../../domain/auditlog.enum";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface InviteParticipantModalProps {
	open: boolean;
	onClose: () => void;
}

interface FormData {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	dateOfBirth?: string;
	clientIDNumber?: string;
	organizationUnit: string;
	program?: string;
}

export default function InviteParticipantModal({
	open,
	onClose,
}: InviteParticipantModalProps) {
	const { t } = useTranslation("common");
	const { data: userInfo } = useSession();
	const { invalidateQueries } = useManageQueries();
	const { showSnackBar } = useFeedBack();
	const theme = useAppTheme();
	const sendParticipantInvitation = useSendParticipantInvitation({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
	});

	const { isPending } = sendParticipantInvitation;

	const { accountSettings, isLoadingAccountSettings, refetchAccountSettings } =
		useGetAccountSettings(`${process.env.REACT_APP_ACCOUNT_ID}`);
	const createAuditlog = useCreateAuditlog();

	const minAgeAllowed = useMemo(() => {
		if (!accountSettings) {
			return 18;
		}

		const minAgeSetting = accountSettings.find(
			(setting) => setting.name === "minAgeAllowed",
		);
		return parseInt((minAgeSetting?.default as string) || "18");
	}, [accountSettings, isLoadingAccountSettings]);

	const country = useMemo(() => {
		if (!accountSettings) {
			return "US";
		}

		const countrySetting = accountSettings.find(
			(setting) => setting.name === "country",
		);
		return (countrySetting?.default as string) || "US";
	}, [accountSettings, isLoadingAccountSettings]);

	const dateOfBirth = useMemo(() => {
		if (!accountSettings) {
			return "optional";
		}

		const dateOfBirthSetting = accountSettings.find(
			(setting) => setting.name === "dateOfBirth",
		);
		return (dateOfBirthSetting?.default as string) || "optional";
	}, [accountSettings, isLoadingAccountSettings]);

	const {
		control,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(
			inviteValidationSchema(t, minAgeAllowed, country, dateOfBirth),
		),
		mode: "all",
	});

	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [ouIdSelected, setOuIdSelected] = useState<string | undefined>();

	const { programs, isLoadingPrograms, refetchPrograms } = usePrograms({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [ouIdSelected || ""],
		isRegistering: true,
	});

	const {
		organizationUnits,
		isLoadingOrganizationUnits,
		refetchOrganizationUnits,
	} = useOrganizationUnits({
		accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
		organizationIds: [""],
		organizationLabels: "",
		isRegistering: true,
	});

	const ouOptions = useMemo(() => {
		if (!organizationUnits) {
			return [];
		}

		return organizationUnits.map((ou) => ({
			key: ou.id,
			value: ou.name,
		}));
	}, [organizationUnits]);

	const onSubmit = async (data: FormData) => {
		const phoneNoSpaces = data.phoneNumber?.replace(/\s/g, "");
		const countryWithCode = country === "US" ? "+1" : "+44";

		try {
			await createAuditlog.mutateAsync({
				appType: "WEB_BACK_OFFICE",
				module: MODULE_NAME.PARTICIPANTS,
				action: "When inviting a new participant (adding)",
				detail: `Invited new participant ${data.firstName} ${data.lastName}`,
				actionCode: "WEB_PART_PAR_ADD_INVITE",
				option: "Participant add / edit",
				createdBy: userInfo?._id || "",
				accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
				programId: data.program || "",
				orgUnitId: data.organizationUnit,
			});

			await sendParticipantInvitation.mutateAsync(
				{
					...invitationPayload,
					data: {
						...invitationPayload.data,
						data: {
							...invitationPayload.data.data,
							user: {
								...invitationPayload.data.data.user,
								firstName: data.firstName,
								lastName: data.lastName,
								email: data.email,
								phoneNumber: phoneNoSpaces,
								countryPhoneCode: countryWithCode,
							},
							participant: {
								...invitationPayload.data.data.participant,
								programId: data.program || null,
							},
							enrollment: {
								...invitationPayload.data.data.enrollment,
								programId: data.program || null,
							},
						},
					},
					additionals: {
						...invitationPayload.additionals,
						dateOfBirth: data.dateOfBirth
							? dayjs(data.dateOfBirth).format("YYYY-MM-DD")
							: null,
						clientIdNumber: data.clientIDNumber || "",
						orgUnitId: data.organizationUnit,
					},
				},
				{
					onSuccess: () => {
						reset();
						onClose();
						invalidateQueries([
							{
								queryKey: ["nalcam-enrolled-participants"],
							},
							{
								queryKey: ["nalcam-queue-participants"],
							},
						]);

						createAuditlog.mutateAsync({
							appType: "WEB_BACK_OFFICE",
							module: MODULE_NAME.PARTICIPANTS,
							action: "When email successfully sent",
							detail: `The email for participant invitation ${data.firstName} ${data.lastName} complete was sent`,
							actionCode: "WEB_PART_INV_EMAIL_OK",
							option: "Email of invitation",
							createdBy: userInfo?._id || "",
							accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
							programId: data.program || "",
							orgUnitId: data.organizationUnit,
						});
					},
					onError: (error) => {
						showSnackBar(
							(error as unknown as ErrorResponse).message?.[0] as string,
							"error",
						);

						createAuditlog.mutateAsync({
							appType: "WEB_BACK_OFFICE",
							module: MODULE_NAME.PARTICIPANTS,
							action: "When email failed to be sent",
							detail: `The email for participant invitation ${data.firstName} ${data.lastName} failed to be sent`,
							actionCode: "WEB_PART_INV_EMAIL_FAILED",
							option: "Email of invitation",
							createdBy: userInfo?._id || "",
							accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
							programId: data.program || "",
							orgUnitId: data.organizationUnit,
						});
					},
				},
			);
		} catch (error) {
			showSnackBar((error as ErrorResponse).message?.[0] as string, "error");
		}
	};

	useEffect(() => {
		if (programs && !isLoadingPrograms) {
			setProgramsOptions(
				[
					...programs.map((program) => ({
						key: program._id,
						value: program.name,
					})),
				] || [],
			);
		}
	}, [programs, isLoadingPrograms]);

	useEffect(() => {
		refetchPrograms();
	}, [ouIdSelected]);

	return (
		<Dialog
			onClose={() => {
				if (isPending) {
					return;
				}

				onClose();
			}}
			aria-labelledby="register-beneficiary-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
					maxWidth: 760,
					minHeight: 320,
					maxHeight: 640,
				},
			}}
			open={open}
			disableEscapeKeyDown={isPending}
		>
			<DialogTitle
				sx={{
					m: 0,
					p: 2.5,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
				id="register-beneficiary-dialog-title"
				variant="headlineSmall"
				color={(theme) => theme.palette.surface.light}
				bgcolor={(theme) => theme.palette.primary.dark}
			>
				Invite participant
				<IconButton
					aria-label="close"
					onClick={() => {
						if (isPending) {
							return;
						}

						onClose();
					}}
					sx={{
						color: (theme) => theme.palette.surface.light,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					gap: "1.25rem",
					display: "flex",
					flexDirection: "column",
					padding: "1.25rem 1.25rem 1.25rem 1.25rem",
				}}
			>
				<Box sx={{ paddingTop: "1.25rem" }}>
					<Typography
						variant="bodyLarge"
						sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
						gutterBottom
					>
						General information
					</Typography>
				</Box>
				<Grid container spacing={1.25}>
					<Grid item xs={12} sm={6}>
						<Controller
							name="firstName"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									id="firstName"
									label="First name"
									variant="outlined"
									required
									value={field.value}
									onChange={(value) => field.onChange(value)}
									error={!!errors.firstName}
									helperText={
										errors.firstName ? errors.firstName.message : null
									}
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="lastName"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									id="lastName"
									label="Last name"
									variant="outlined"
									required
									value={field.value}
									onChange={(value) => field.onChange(value)}
									error={!!errors.lastName}
									helperText={errors.lastName ? errors.lastName.message : null}
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="email"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									id="email"
									label="Email"
									required
									variant="outlined"
									value={field.value}
									onChange={(value) => field.onChange(value)}
									error={!!errors.email}
									helperText={errors.email ? errors.email.message : null}
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="phoneNumber"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<PhoneNumberInput
									{...field}
									defaultCode={country}
									value={field.value}
									label="Phone number"
									variant="outlined"
									className="flex flex-1 w-full"
									onChange={(value) => field.onChange(value)}
									error={!!errors.phoneNumber}
									helperText={
										errors.phoneNumber ? errors.phoneNumber.message : null
									}
									key={isLoadingAccountSettings ? "loading" : "loaded"}
								/>
							)}
						/>
					</Grid>
					{dateOfBirth !== "hidden" ? (
						<Grid item xs={12} sm={6}>
							<Controller
								name="dateOfBirth"
								control={control}
								render={({ field }) => (
									<div>
										<BasicDatepicker
											{...field}
											label="Date of birth (mm/dd/yyyy)"
											onChange={(value) => {
												field.onChange(
													value !== undefined ? value?.toDate() : undefined,
												);
											}}
											value={field.value ? dayjs(field.value) : null}
											error={!!errors.dateOfBirth}
											helperText={errors.dateOfBirth?.message || ""}
											required={dateOfBirth === "mandatory"}
										/>
									</div>
								)}
							/>
						</Grid>
					) : null}
					<Grid item xs={12} sm={6}>
						<Controller
							name="clientIDNumber"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									id="state"
									label="Client ID Number (CIN)"
									variant="outlined"
									className="flex flex-1"
									value={field.value}
									onChange={(value) => field.onChange(value)}
									error={!!errors.clientIDNumber}
									helperText={
										errors.clientIDNumber ? errors.clientIDNumber.message : null
									}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Box sx={{ padding: "0" }}>
					<Typography
						variant="bodyLarge"
						sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
						gutterBottom
					>
						Campaign assignment
					</Typography>
				</Box>
				<Grid container spacing={1.25}>
					<Grid item xs={12} sm={6}>
						<Controller
							name="organizationUnit"
							control={control}
							defaultValue={undefined}
							render={({ field }) => (
								<BasicSelect
									{...field}
									data={ouOptions || []}
									value={field.value}
									required={true}
									label="Org unit"
									id="participant-org-unit"
									handleChange={(value) => {
										const selectedOuId = value as string;
										field.onChange(selectedOuId);

										setOuIdSelected(selectedOuId);
									}}
									error={!!errors.organizationUnit}
									errorMessage={errors.organizationUnit?.message || ""}
									isLoading={isLoadingPrograms}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="program"
							control={control}
							defaultValue={undefined}
							render={({ field }) => (
								<BasicSelect
									{...field}
									data={programsOptions || []}
									value={field.value}
									label="Campaign"
									id="participant-campaign"
									handleChange={(value) => {
										const selectedProgramId = value as string;
										field.onChange(selectedProgramId);

										// onProgramChange(selectedProgramId);
									}}
									error={!!errors.program}
									errorMessage={errors.program?.message || ""}
									isLoading={isLoadingPrograms || ouIdSelected === undefined}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions
				sx={{
					borderTop: "1px solid #ccc",
					p: "1rem 20px 20px",
				}}
			>
				<Button
					size="large"
					onClick={onClose}
					variant="outlined"
					disabled={isPending}
					sx={{
						borderColor: theme.palette.outline.dark,
						color: "#261F1F",
						padding: "8px 16px",
					}}
				>
					CANCEL
				</Button>
				<Button
					size="large"
					onClick={handleSubmit(onSubmit)}
					color="primary"
					variant="contained"
					sx={{
						textTransform: "none",
						textAlign: "center",
						fontWeight: 500,
						padding: "8px 16px",
					}}
					disabled={isPending}
					startIcon={isPending ? <CircularProgress size={16} /> : null}
				>
					INVITE
				</Button>
			</DialogActions>
		</Dialog>
	);
}
